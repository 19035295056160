// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.UsersButtons_postsBlock__uENhG{\n    padding: 10px;\n  }\n.UsersButtons_error__KhpgQ {\n  color:red;\n}\n.UsersButtons_serbut__ZYGLU{\n  margin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Users/UsersButtons/UsersButtons.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;EACf;AACF;EACE,SAAS;AACX;AACA;EACE,gBAAgB;AAClB","sourcesContent":["\n.postsBlock{\n    padding: 10px;\n  }\n.error {\n  color:red;\n}\n.serbut{\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postsBlock": "UsersButtons_postsBlock__uENhG",
	"error": "UsersButtons_error__KhpgQ",
	"serbut": "UsersButtons_serbut__ZYGLU"
};
export default ___CSS_LOADER_EXPORT___;
