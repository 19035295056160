// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n.SMS_rightcol__cVkqP {\r\n  position: relative;\r\n  display: flex;\r\n  width: 40px;\r\n  float: right;\r\n  justify-content: center;\r\n  color: #fff;\r\n  background: #343a40;\r\n  border-radius: 23px;\r\n    }\r\n\r\n.SMS_serbut__BhIJQ{\r\n      margin-left: 10px;\r\n    }\r\n/* Table\r\n**************************/\r\n\r\n.SMS_table_name__tw4Li{\r\n  width: 15%;}\r\n.SMS_table_price__qhTMs{width: 15%;}\r\n.SMS_table_status__v1wU6{width: 15%;}\r\n.SMS_table_date__nZUrU{width: 20%;}\r\n.SMS_table_actions__gxRlC{width: 35%;}\r\n", "",{"version":3,"sources":["webpack://./src/Components/SMS/SMS/SMS.module.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,WAAW;EACX,mBAAmB;EACnB,mBAAmB;IACjB;;AAEJ;MACM,iBAAiB;IACnB;AACJ;0BAC0B;;AAE1B;EACE,UAAU,CAAC;AACb,wBAAa,UAAU,CAAC;AACxB,yBAAc,UAAU,CAAC;AACzB,uBAAY,UAAU,CAAC;AACvB,0BAAe,UAAU,CAAC","sourcesContent":["\r\n.rightcol {\r\n  position: relative;\r\n  display: flex;\r\n  width: 40px;\r\n  float: right;\r\n  justify-content: center;\r\n  color: #fff;\r\n  background: #343a40;\r\n  border-radius: 23px;\r\n    }\r\n\r\n.serbut{\r\n      margin-left: 10px;\r\n    }\r\n/* Table\r\n**************************/\r\n\r\n.table_name{\r\n  width: 15%;}\r\n.table_price{width: 15%;}\r\n.table_status{width: 15%;}\r\n.table_date{width: 20%;}\r\n.table_actions{width: 35%;}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightcol": "SMS_rightcol__cVkqP",
	"serbut": "SMS_serbut__BhIJQ",
	"table_name": "SMS_table_name__tw4Li",
	"table_price": "SMS_table_price__qhTMs",
	"table_status": "SMS_table_status__v1wU6",
	"table_date": "SMS_table_date__nZUrU",
	"table_actions": "SMS_table_actions__gxRlC"
};
export default ___CSS_LOADER_EXPORT___;
