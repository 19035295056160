
import {update_data_,update_order,update_data } from '../../../redux/price-reducer.js';
import { connect } from 'react-redux';
import Head from './Head.jsx';

let mapStateToProps=(state)=>{
  return{
    data:state.price.data,
    current_type:state.price.current_type,
    order_name:state.price.order_name,
    order_by:state.price.order_by,
    order_direction:state.price.order_direction
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w_:(data)=>{
      dispatch(update_data_(data));
    },
    update_data_w:(data,type)=>{
      dispatch(update_data(data,type));
    },
    update_order_w:(order_name,order_by,order_direction)=>{
      dispatch(update_order(order_name,order_by,order_direction));
    }
  }
}
const HeadContainer = connect(mapStateToProps,mapDispatchToProps)(Head);
export default HeadContainer;
