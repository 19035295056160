
import React from 'react';
import s from './PhonesButtons.module.css';
import { dbAPI } from '../../../api/api_db';
import Add from './Add/Add.jsx'
import Search from './Search/Search.jsx'

import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledDropdown,
  Container, Row, Col
} from 'reactstrap';
import DDItem from './DDItem.jsx'
import { get_new_direction,filter_data } from '../../common/common';

const PhonesButtons = React.memo((props) => {
  let get_status = () => {
    if(props.current_type.includes("1")){
      return "Оплаченные"
    }
    if(props.current_type.includes("0")){
      return "Неоплаченные"
    }
    return"ВСЕ"
  }
  return (
    <div className={s.postsBlock}>
      <h3>Phones: {Object.values(props.data).filter(v => v.show === true).length}/{props.data.length}</h3>
      {get_status()}
      <IncreaseForm
      data={props.data} 
      update_data_w={props.update_data_w} 
      update_data_w_={props.update_data_w_}
      db_name={props.db_name}
      order_by={props.order_by}
      condition={props.condition}

      add_link={props.add_link}
      add_type={props.add_type}
      add_sub_type={props.add_sub_type}
      add_price={props.add_price}
      add_phone_number={props.add_phone_number}
      add_section={props.add_section}

      update_add_link_w={props.update_add_link_w}
      update_add_type_w={props.update_add_type_w}
      update_add_sub_type_w={props.update_add_sub_type_w}
      update_add_price_w={props.update_add_price_w}
      update_add_phone_number_w={props.update_add_phone_number_w}
      update_add_section_w={props.update_add_section_w}

      unique_sub_types={props.unique_sub_types}
      update_u_st_w={props.update_u_st_w}
      u_sections={props.u_sections}
      update_u_sections_w={props.update_u_sections_w}   
      current_type={props.current_type}   
       />
    </div>
  );
});

const IncreaseForm = (props) => {
  let update_data_w_test = (where) => {
    where=get_new_direction(where,props.current_type)
    dbAPI.get_db_data(props.db_name,where+props.condition)        
    .then(result => {
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      props.update_data_w(data,where) 
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_data_w = (where) => {
    let answer=filter_data(where,props.data,props.current_type)
    props.update_data_w(answer[0],answer[1])
  }
  let update_data_w2 = (where) => {
    dbAPI.get_db_data(props.db_name,where+props.condition)        
    .then(result => { 
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      let answer=filter_data(props.current_type,data,props.current_type)
      props.update_data_w(answer[0],answer[1])  
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let get_unique_sections = (where) => {
    dbAPI.get_unique_db_data('phone',where,'operator')        
    .then(result => { 
        var array=[]
        for (let i = 0; i < result.data.length; i++) {
          array.push(result.data[i].operator)
        }
        props.update_u_sections_w(array)
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
    return 'TEST_DATA'
  }
  let delete_phones=(where) =>{
    dbAPI.db_delete_data(props.db_name,where)        
    .then(result => { 
      if(result.data.status==200){
        update_data_w2('')
      }
      else{
        alert(`ERROR: ${result.data.status}`)
      }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
  }
  let get_status= () => {
    if(props.current_type.includes("status=1")){
      return "Активный"
    }
    if(props.current_type.includes("status=0")){
      return "Отключен"
    }
    return""
  }
  let get_theme = () => {
    for (let i = 0; i < props.u_sections.length; i++) {
      if(props.current_type.includes(props.u_sections[i].replaceAll('@', ' '))){
        return props.u_sections[i].replaceAll('@', ' ')
      }
    }
    return""
  }
  let update_db_data = (where,new_data) => {
    dbAPI.update_db_data(props.db_name,where,new_data)        
    .then(result => { 
       if(result.data.status==200){
          update_data_w2('')
        }
        else{
          alert(`ERROR: ${result.data.status}`)
        }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let unique_sections= props.u_sections.map( (p => 
    <DDItem 
    option={p}
    update_data_w={props.update_data_w} 
    db_name={props.db_name}
    order_by={props.order_by}
    update_u_sections_w={props.update_u_sections_w}
    current_type={props.current_type}
    get_new_direction={get_new_direction}
    data={props.data} 
    />) );
  return (
    <div className={s.parent}>
<Container>
<Row>
<Col xs="auto">
  {/*
<Add
      condition={props.condition}
      add_link={props.add_link}
      add_type={props.add_type}
      add_sub_type={props.add_sub_type}
      add_price={props.add_price}
      add_phone_number={props.add_phone_number}
      add_section={props.add_section}

      update_add_link_w={props.update_add_link_w}
      update_add_type_w={props.update_add_type_w}
      update_add_sub_type_w={props.update_add_sub_type_w}
      update_add_price_w={props.update_add_price_w}
      update_add_phone_number_w={props.update_add_phone_number_w}
      update_add_section_w={props.update_add_section_w}

      update_data_w={props.update_data_w}
      update_data_w_={props.update_data_w_}
      db_name={props.db_name}

      unique_sub_types={props.unique_sub_types}
      update_u_st_w={props.update_u_st_w}
      u_sections={props.u_sections}
      update_u_sections_w={props.update_u_sections_w} 
  />
  */}
      <Search
      add_link={props.add_link}
      add_type={props.add_type}
      add_sub_type={props.add_sub_type}
      add_price={props.add_price}
      add_phone_number={props.add_phone_number}
      add_section={props.add_section}
 
      update_add_link_w={props.update_add_link_w}
      update_add_type_w={props.update_add_type_w}
      update_add_sub_type_w={props.update_add_sub_type_w}
      update_add_price_w={props.update_add_price_w}
      update_add_phone_number_w={props.update_add_phone_number_w}
      update_add_section_w={props.update_add_section_w}

      update_data_w={props.update_data_w}
      update_data_w_={props.update_data_w_}
      db_name={props.db_name}

      unique_sub_types={props.unique_sub_types}
      update_u_st_w={props.update_u_st_w}
      u_sections={props.u_sections}
      update_u_sections_w={props.update_u_sections_w} 

      data={props.data}
  />
  <Button color="primary" className={s.serbut} onClick={() => update_data_w_test('')}>🔄</Button>
</Col>
<Col xs="auto">
  <UncontrolledDropdown >
  <DropdownToggle className={s.serbut} caret>Статус {get_status()}</DropdownToggle>
  <DropdownMenu >
  <DropdownItem onClick={() => update_data_w('status=1')}>Активный</DropdownItem>
  <DropdownItem onClick={() => update_data_w('status=0')}>Отключен</DropdownItem>
  <DropdownItem onClick={() => update_data_w('status=2')}>Продление</DropdownItem>
  </DropdownMenu>
  </UncontrolledDropdown>
</Col> 
<Col xs="auto">
  <UncontrolledDropdown >
  <DropdownToggle className={s.serbut} onClick={() => get_unique_sections('')} caret>Операторы {get_theme()}</DropdownToggle>
  <DropdownMenu >
  {unique_sections}
  </DropdownMenu>
  </UncontrolledDropdown>
</Col>
<Col xs="auto">
  <Button color="danger" className={s.serbut} onClick={() => delete_phones("status=2")}>🧹🗑️Удалить всё</Button>
</Col>
</Row>
</Container>
    </div>
  )
}


export default PhonesButtons;
