import axios from 'axios';

const instance = axios.create({
    baseURL: "https://api.receptor.cfd"
    //baseURL: "http://localhost:3001"
}
)
export const dbAPI = {
    send_sms(phone) {
        return instance.get(`new_sms_2?api_key=acaAdm&&phone_to=${phone}&&phone_from=ACA_TEST&&text=TEST_MSG&&date=2000-01-01 00:00:01`)
    },    
    get_db_data(db_name,where) {
        return instance.get(`get_db_data?db_name=${db_name}&&where=${where}`)
    },
    db_delete_data(db_name,where) {
        return instance.get(`db_delete_data?db_name=${db_name}&&where=${where}`)
    },
    update_db_data(db_name,where,new_data) {
        return instance.get(`update_db_data?db_name=${db_name}&&where=${where}&&new_data=${new_data}`)
    },
    insert_item_db(db_name,data) {
        return instance.get(`insert_item_db?db_name=${db_name}&&data=${data}`)
    },
    get_unique_db_data(db_name,where,target) {
        return instance.get(`get_unique_db_data?db_name=${db_name}&&where=${where}&&target=${target}`)
    },
    check_access(email,password) {
        return instance.get(`check_access?email=${email}&&password=${password}`)
    },
    insert_phone_db(db_name,data) {
        return instance.get(`insert_phone_db?db_name=${db_name}&&data=${data}`)
    },
    insert_price_db(db_name,data) {
        return instance.get(`insert_price_db?db_name=${db_name}&&data=${data}`)
    },
    insert_section_db(db_name,data) {
        return instance.get(`insert_section_db?data=${data}`)
    },
    update_image(db_name,where,image) {
        return instance.post(
            `update_image?db_name=${db_name}&&where=${where}`,
            image, 
            {
                headers: {
                  'Content-Type': 'multipart/form-data',
                }
              }
            )
    },
    add_phones(json) {
        return instance.post(
            `add_phones`,
            json, 
            {
                headers: {
                  'Content-Type': 'application/json',
                }
              }
            )
    },
    delete_phones(json) {
        return instance.post(
            `delete_phones`,
            json, 
            {
                headers: {
                  'Content-Type': 'application/json',
                }
              }
            )
    },
    delete_all_sms() {
        return instance.post(
            `delete_all_sms`,
            )
    },
    delete_phones_by_id(json) {
        return instance.post(
            `delete_phones_by_id`,
            json, 
            {
                headers: {
                  'Content-Type': 'application/json',
                }
              }
            )
    },
    update_data(json) {
        return instance.post(
            `update_data`,
            json, 
            {
                headers: {
                  'Content-Type': 'application/json',
                }
              }
            )
    },
    save_sms(phones) {
        return instance.post(
            `save_sms`,
            {
                'phones':phones,
                'phone_from':'ACA_TEST',
                'text':'TEST_MSG',
                'date':'2000-01-01 00:00:01'

            }, 
            {
                headers: {
                  'Content-Type': 'application/json',
                }
              }
            )
    }, 
    initialize_phones(json) {
        return instance.post(
            `initialize_phones`,
            json, 
            {
                headers: {
                  'Content-Type': 'application/json',
                }
              }
            )
    },

}
const instance2 = axios.create({
    baseURL: "https://min-api.cryptocompare.com/data/price?fsym=RUB&tsyms=USD"
})
export const exchange_rate = {
    
    get_rub_to_usd() {
        return instance2.get(``)
    },
}
const instance3 = axios.create({
    baseURL: "https://brighton.fdfdfdfdfdfdf.online/smswebhok1gqwqw"
})
export const bw_api = {
    
    send_new_phone(phone_number,unix_date) {
        return instance3.post('',
            {
                'recipient':phone_number,
                'date_time':unix_date
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  }
            }
            )
    },
}