// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.OrdersButtons_postsBlock__bKDbK{\n    padding: 10px;\n  }\n.OrdersButtons_error__ul0sA {\n  color:red;\n}\n.OrdersButtons_serbut__o8oSu{\n  margin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Orders/OrdersButtons/OrdersButtons.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;EACf;AACF;EACE,SAAS;AACX;AACA;EACE,gBAAgB;AAClB","sourcesContent":["\n.postsBlock{\n    padding: 10px;\n  }\n.error {\n  color:red;\n}\n.serbut{\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postsBlock": "OrdersButtons_postsBlock__bKDbK",
	"error": "OrdersButtons_error__ul0sA",
	"serbut": "OrdersButtons_serbut__o8oSu"
};
export default ___CSS_LOADER_EXPORT___;
