import React , { useState } from 'react';
import b from './Show.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { get_info_user } from '../../../common/common';
const Show = (props) =>{
  let get_check_type=(type) =>{
    if(type=='ADD_FUNDS'){
       return "Пополнение баланса"
     }
     if(type=='item'){
       return "Оплата товара"
     }
     if(type=='service'){
       return "Оплата услуги"
     }
    return 'ERROR'
  }
  let get_type=(status) =>{
    if(status=='done'){
       return "Оплачен"
     }
    if(status=='in_progress'){
    return "Не оплачен"
    }
    if(status=='active'){
       return "Ожидает пополнение"
     }
   if(status=='canceled'){
   return "Удален"
   }
    return "ERROR"
  }
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return(
    <b><Button className={b.serbut}   outline  onClick={toggle} >Show</Button>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Check: {props.check_id}</ModalHeader>
      <ModalBody>
      Type: {get_check_type(props.type)}<br/>
      Status: {get_type(props.status)}<br/>
      Date: {props.date}<br/>
      Price: {props.price}$<br/>
      Payment ID: {props.payment_id}<br/>  
      Address: {props.address}<br/>
      Currency: {props.amount} {props.currency}<br/>
      Order ID: {props.order_id}<br/>
      Author ID: {props.author_id}{get_info_user(props.author_id,props.users_data)}<br/>    
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)
}


export default Show
