import SectionsButtonsContainer from './SectionsButtons/SectionsButtonsContainer';
import SectionContainer from './Section/SectionContainer.jsx';
import HeadContainer from './Head/HeadContainer.jsx';
const Sections = (props) => {
  let items = props.data.map(
    p => 
    <SectionContainer 
    id={p.id}
    name={p.name}
    type={p.type}
    description={p.description}
    db_name={props.db_name}
    show={p.show}
    
    owner={p.owner}
    phone_number={p.phone}
    order_id={p.order_id}
    status={p.status}
    key_word={p.key_word}
    expiry_date={p.expiry_date}
    price={p.price}
    black_price={p.black_price}
    operator={p.operator}
    received_id={p.received_id}
    rent_type={p.rent_type}
    active_period={p.active_period}
    image={p.image}
    />
    );
  return (
    <div>
      <SectionsButtonsContainer 
      db_name={props.db_name}
      />
      <HeadContainer
      db_name={props.db_name}
      />
      {items}
    </div>
  );
}

export default Sections;
