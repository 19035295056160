
import {update_data,update_unpaid,update_credits,update_balance } from '../../../redux/users-reducer.js';
import { connect } from 'react-redux';
import User from './User';

let mapStateToProps=(state)=>{
  return{
    data:state.users.data,
    current_type:state.users.current_type,
    data_balance:state.users.data_balance,
    data_credits:state.users.data_credits,
    data_unpaid:state.users.data_unpaid,
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    },
    update_unpaid_w:(data)=>{
      dispatch(update_unpaid(data));
    },
    update_credits_w:(data)=>{
      dispatch(update_credits(data));
    },
    update_balance_w:(data)=>{
      dispatch(update_balance(data));
    }
  }
}
const UserContainer = connect(mapStateToProps,mapDispatchToProps)(User);
export default UserContainer;
