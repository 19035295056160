import OrdersButtonsContainer from './OrdersButtons/OrdersButtonsContainer';
import OrderContainer from './Order/OrderContainer.jsx';
import HeadContainer from './Head/HeadContainer.jsx';
const Orders = (props) => {
  let items = props.data.map(
    p => 
    <OrderContainer 
    author_id={p.author_id}
    freelancer_id={p.freelancer_id}
    name={p.name}
    text={p.text}
    theme={p.theme}
    date={p.date}
    order_id={p.order_id}
    price={p.price}
    status={p.status}
    type={p.type}
    db_name={props.db_name}
    message_history={p.message_history}
    executor_id={p.executor_id}
    show={p.show}
    />
    );
  return (
    <div>
      <OrdersButtonsContainer 
      db_name={props.db_name}
      />
      <HeadContainer
      db_name={props.db_name}
      />
      {items}
    </div>
  );
}

export default Orders;
