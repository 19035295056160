import React  from 'react';
import b from './Price.module.css';
import Edit from './edit/edit.jsx'
import Delete from './delete/delete.jsx'
import  {Table} from 'reactstrap';
import { dbAPI,exchange_rate } from '../../../api/api_db.js';
const Price = (props) =>{
if(props.show===true) { 
   let update_data_w = (where) => {
      dbAPI.get_db_data(props.db_name,where)        
      .then(result => { 
        let data=[]
        for (let i = 0; i < result.data.length; i++) {
          let copy_data=result.data[i]
          copy_data.show=true
          data.push(copy_data)
        }
          props.update_data_w(data,where) 
      })
      .catch(error => { console.error(error); return Promise.reject(error); })  
    }
   let update_db_data = (where,new_data) => {
      dbAPI.update_db_data(props.db_name,where,new_data)        
      .then(result => { 
        update_data_w('')
      })
      .catch(error => { console.error(error); return Promise.reject(error); })  
    }
    let get_rate = () => {
      if(props.exchange_rate_RUB==0){
            exchange_rate.get_rub_to_usd()        
            .then(result => { 
               props.update_update_exchange_rate_RUB_w(result.data.USD)
            })
            .catch(error => { console.error(error); return Promise.reject(error); })  
      }
    }
  return(
<Table bordered hover size="sm" cellSpacing="1" >
   <thead id={b.remover}>
      <tr>
      </tr>
   </thead>
   <tbody > <tr>
      <td  className={b.table_order_id}>
         {props.operator}<br/>
         {props.name}<br/>
         🗓️Минимальная аренда: {props.min} 🌅
      </td>
      <td  className={b.table_price}>
      {props.day} $<br/>
      {props.day3} $<br/>
      {props.day7} $<br/>
      {props.day15} $<br/>
      {props.day30} $<br/>
      </td>
      <td  className={b.table_actions}>
         <Edit
            operator={props.operator}
            min={props.min}
            owner={props.owner}
            received_id={props.received_id}
            phone_number={props.phone_number}
            order_id={props.order_id}
            key_word={props.key_word}
            expiry_date={props.expiry_date}
            db_name={props.db_name}
            update_data_w={props.update_data_w}
            current_type={props.current_type}
            data_link={props.data_link}
            data_status={props.data_status}
            data_owner={props.data_owner}
            data_price={props.data_price}
            update_received_id_w={props.update_received_id_w}
            data_received_id={props.data_received_id}
            data_description={props.data_description}
            update_link_w={props.update_link_w}
            update_status_w={props.update_status_w}
            update_owner_w={props.update_owner_w}
            update_price_w={props.update_price_w}
            update_description_w={props.update_description_w}
            users_data={props.users_data}
            price={props.price}
            black_price={props.black_price}
            data_active_period={props.data_active_period}
            update_active_period_w={props.update_active_period_w}

            data_white_price={props.data_white_price}
            update_white_price_w={props.update_white_price_w}
            data_black_price={props.data_black_price}
            update_black_price_w={props.update_black_price_w}
            />
         <Delete
            operator={props.operator}
            db_name={props.db_name}
            update_data_w={props.update_data_w}
            current_type={props.current_type}
            />
      </td>
      </tr>
   </tbody>
</Table>
)}
else{
   return(<></>)
}
}


export default Price
