import PricesButtonsContainer from './PricesButtons/PricesButtonsContainer.jsx';
import PriceContainer from './Price/PriceContainer.jsx';
import HeadContainer from './Head/HeadContainer.jsx';
const Prices = (props) => {
  let items = props.data.map(
    p => 
    <PriceContainer 
    operator={p.operator}
    name={p.name}
    day={p.day}
    day3={p.day3}
    day7={p.day7}
    day15={p.day15}
    day30={p.day30}
    min={p.min+1}
    show={p.show}
    db_name={props.db_name}
    />
    );
  return (
    <div>
      <PricesButtonsContainer 
      db_name={props.db_name}
      />
      <HeadContainer
      db_name={props.db_name}
      />
      {items}
    </div>
  );
}

export default Prices;
