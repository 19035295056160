// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n/* Table\r\n**************************/\r\n\r\n.Head_table_order_id__Vv1Eb{\r\n  width: 12.5%;}\r\n.Head_table_name__UsV0v{\r\n  width: 15%;}\r\n.Head_table_price__s4gDL{width: 15%;}\r\n.Head_table_status__kl75H{width: 15%;}\r\n.Head_table_date__\\+vo6N{width: 17.5%;}\r\n.Head_table_actions__fMFzB{width: 25%;}\r\n.Head_input__\\+6xs4{\r\n  width: 80%;\r\n  margin-top: 10px;\r\n}", "",{"version":3,"sources":["webpack://./src/Components/PhonesBASKET/Head/Head.module.css"],"names":[],"mappings":";AACA;0BAC0B;;AAE1B;EACE,YAAY,CAAC;AACf;EACE,UAAU,CAAC;AACb,yBAAa,UAAU,CAAC;AACxB,0BAAc,UAAU,CAAC;AACzB,yBAAY,YAAY,CAAC;AACzB,2BAAe,UAAU,CAAC;AAC1B;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["\r\n/* Table\r\n**************************/\r\n\r\n.table_order_id{\r\n  width: 12.5%;}\r\n.table_name{\r\n  width: 15%;}\r\n.table_price{width: 15%;}\r\n.table_status{width: 15%;}\r\n.table_date{width: 17.5%;}\r\n.table_actions{width: 25%;}\r\n.input{\r\n  width: 80%;\r\n  margin-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_order_id": "Head_table_order_id__Vv1Eb",
	"table_name": "Head_table_name__UsV0v",
	"table_price": "Head_table_price__s4gDL",
	"table_status": "Head_table_status__kl75H",
	"table_date": "Head_table_date__+vo6N",
	"table_actions": "Head_table_actions__fMFzB",
	"input": "Head_input__+6xs4"
};
export default ___CSS_LOADER_EXPORT___;
