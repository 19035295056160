
import {update_data } from '../../../redux/users-reducer.js';
import { connect } from 'react-redux';
import UsersButtons from './UsersButtons.jsx';

let mapStateToProps=(state)=>{
  return{
    data:state.users.data,
    current_type:state.users.current_type,
    order_by:state.users.order_by
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    }
  }
}
const UsersButtonsContainer = connect(mapStateToProps,mapDispatchToProps)(UsersButtons);
export default UsersButtonsContainer;
