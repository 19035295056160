import SMSButtonsContainer from './SMSButtons/SMSButtonsContainer';
import SMSContainer from './SMS/SMSContainer.jsx';
import HeadContainer from './Head/HeadContainer.jsx';
const SMS = (props) => {
  let items = props.data.map(
    p => 
    <SMSContainer 
    sms_id={p.id}
    phone_number={p.phone_number}
    sender={p.sender}
    msg_text={p.msg_text}
    date={p.date}
    owner={p.user_id}
    status={p.status}
    db_name={props.db_name}
    show={p.show}
    />
    );
  return (
    <div>
      <SMSButtonsContainer 
      db_name={props.db_name}
      />
      <HeadContainer
      db_name={props.db_name}
      />
      {items}
    </div>
  );
}

export default SMS;
