import React , { useState } from 'react';
import b from './post.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import { dbAPI } from '../../../../api/api_db';
import { get_info_user } from '../../../common/common';
import { filter_data } from '../../../common/common';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';
const Edit = (props) =>{
  let newDescription= React.createRef();
  let update_data_w = (where) => {
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => { 
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      let answer=filter_data(props.current_type,data,props.current_type)
      props.update_data_w(answer[0],answer[1])  
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_db_data = (where,new_data) => {
    dbAPI.update_db_data(props.db_name,where,new_data)        
    .then(result => { 
      if(result.data.status==200){
        update_data_w('')
      }
      else{
        alert(`ERROR: ${result.data.status}`)
      }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }

  let get_type=() =>{
    if(props.type=='phone'){
       return `☎️Аренда номеров`
       }
    if(props.type=='item'){
       return `📦Маркет`
    }
    return "ERROR "
  }

  let onDescriptionChange=()=>{
    let data=newDescription.current.value;
    data=data.replace(/^\s+/,'');
    props.update_description_w(data)
  }
  let update_status=(new_status) =>{
    var where=`id=${props.id}`
    var new_data=`type='${new_status}'`
    alert(new_data)
    update_db_data(where,new_data)
  }
  const {
    className
  } = props;
  const [modal, setModal] = useState(false);
  const toggle = () =>{
    setModal(!modal);
  }
  let edit_description=() =>{
    var where=`type='${props.type}' AND id=${props.id}`
    var new_data=`description='${props.data_description.replaceAll('\n', '%0A')}'`
    update_db_data(where,new_data)
    props.update_description_w('')
  }
  let set_default_description=() =>{
    props.update_description_w(props.description)
  }
  return(
    <b>
    <Button className={b.serbut} outline color="primary" onClick={toggle}>Edit</Button>
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>ID: {props.id}</ModalHeader>
      <ModalBody>
      Описание:
      <br/>
      <textarea class={b.textarea} role="textbox" contenteditable onChange={onDescriptionChange} 
              ref={newDescription}  value={props.data_description} placeholder={"Enter "}></textarea>
      <br/>
      {(props.description && props.description.toString().length>1)?<Button className={b.serbut} color="primary" onClick={set_default_description}>Запросить текущее описание</Button>:''}
      <Button className={b.serbut} disabled={ !props.data_description} color="primary" onClick={edit_description}>Update Description</Button>
      <br/>
      <br/>
      <UncontrolledDropdown >
        <DropdownToggle className={b.serbut} caret>Тип {get_type()}</DropdownToggle>
        <DropdownMenu >
        <DropdownItem onClick={() => update_status('phone')}>☎️Аренда номеров</DropdownItem>
        <DropdownItem onClick={() => update_status('item')}>📦Маркет</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <br/>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)





}


export default Edit
