const UPDATE_DATA = 'UPDATE_DATA_ITEM';
const UPDATE_DATA_ = 'UPDATE_DATA_ITEM_';
const UPDATE_ORDER = 'UPDATE_ORDER_ITEM';

const UPDATE_LINK = 'UPDATE_LINK_ITEM';
const UPDATE_STATUS = 'UPDATE_STATUS_ITEM';
const UPDATE_OWNER = 'UPDATE_OWNER_ITEM';
const UPDATE_PRICE = 'UPDATE_PRICE_ITEM';
const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION_ITEM';
const UPDATE_ADD_LINK = 'UPDATE_ADD_LINK_ITEM';
const UPDATE_ADD_TYPE='UPDATE_ADD_TYPE_ITEM';
const UPDATE_ADD_SUB_TYPE='UPDATE_ADD_SUB_TYPE_ITEM';
const UPDATE_ADD_PRICE='UPDATE_ADD_PRICE_ITEM';
const UPDATE_ADD_Phone_Number='UPDATE_ADD_Phone_Number_ITEM';
const UPDATE_ADD_SECTION='UPDATE_ADD_SECTION_ITEM';

const UPDATE_U_ST='UPDATE_U_ST_ITEM';
const UPDATE_U_SECTION='UPDATE_U_SECTION_ITEM'; 
let initialState = {
    counts:2,
    data:[], 
    current_type:'',
    current_status:'',
    order_name:'id',
    order_by:' ORDER BY id DESC',
    order_direction:'DESC',
    data_link:'',
    data_price:'',
    data_status:'',
    data_owner:'',
    data_description:'',

    add_link:'',
    add_type:'',
    add_sub_type:'',
    add_price:'',
    add_phone_number:'',
    add_section:'',
    unique_sub_types:[],
    u_sections:[]
};
const itemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ADD_SECTION:
            return {
                ...state,
                add_section:action.add_section
            }
        case UPDATE_ADD_Phone_Number:
            return {
                ...state,
                add_phone_number:action.add_phone_number
            }
        case UPDATE_ADD_PRICE:
            return {
                ...state,
                add_price:action.add_price
            }
        case UPDATE_ADD_SUB_TYPE:
            return {
                ...state,
                add_sub_type:action.add_sub_type
            }
        case UPDATE_ADD_TYPE:
            return {
                ...state,
                add_type:action.add_type
            }
        case UPDATE_ADD_LINK:
            return {
                ...state,
                add_link:action.add_link
            }
        case UPDATE_DATA_:
            return {
                ...state,
                data:action.data
            }
        case UPDATE_DATA:
            return {
                ...state,
                data:action.data,
                current_type:action.current_type
            }
        case UPDATE_ORDER:
            return {
                ...state,
                order_name:action.order_name,
                order_by:action.order_by,
                order_direction:action.order_direction
            }
        case UPDATE_LINK:
            return {
                ...state,
                data_link:action.data_link
        }
        case UPDATE_STATUS:
            return {
                ...state,
                data_status:action.data_status
            }
        case UPDATE_OWNER:
            return {
                ...state,
                data_owner:action.data_owner
            }
        case UPDATE_PRICE:
            return {
                ...state,
                data_price:action.data_price
            }
        case UPDATE_DESCRIPTION:
            return {
                ...state,
                data_description:action.data_description
            }
        case UPDATE_U_ST:
            return {
                ...state,
                unique_sub_types:action.unique_sub_types
            }
        case UPDATE_U_SECTION:
            return {
                ...state,
                u_sections:action.u_sections
            }
        default:
            return state;
    }
}
export const update_u_sections = (data) => (
    { 
        type: UPDATE_U_SECTION, 
        u_sections:data
    }
    )
export const update_u_st = (data) => (
    { 
        type: UPDATE_U_ST, 
        unique_sub_types:data
    }
    )
export const update_add_type = (data) => (
    { 
        type: UPDATE_ADD_TYPE, 
        add_type:data
    }
    )
export const update_add_sub_type = (data) => (
    { 
        type: UPDATE_ADD_SUB_TYPE, 
        add_sub_type:data
    }
    )
export const update_add_phone_number = (data) => (
    { 
        type: UPDATE_ADD_Phone_Number, 
        add_phone_number:data
    }
    )
export const update_add_section = (data) => (
    { 
        type: UPDATE_ADD_SECTION, 
        add_section:data
    }
    )
export const update_add_link = (data) => (
    { 
        type: UPDATE_ADD_LINK, 
        add_link:data
    }
    )
export const update_add_price = (data) => (
    { 
        type: UPDATE_ADD_PRICE, 
        add_price:data
    }
    )
export const update_price = (data) => (
    { 
        type: UPDATE_PRICE, 
        data_price:data
    }
    )
export const update_description = (data) => (
    { 
        type: UPDATE_DESCRIPTION, 
        data_description:data
    }
    )
export const update_owner = (data) => (
    { 
        type: UPDATE_OWNER, 
        data_owner:data
    }
    )
export const update_status = (data) => (
    { 
        type: UPDATE_STATUS, 
        data_status:data
    }
    )
export const update_link = (data) => (
    { 
        type: UPDATE_LINK, 
        data_link:data
    }
    )
export const update_data_ = (data) => (
    { 
        type: UPDATE_DATA_, 
        data:data
    }
    )
export const update_data = (data,current_type) => (
    { 
        type: UPDATE_DATA, 
        data:data,
        current_type:current_type
    }
    )
export const update_order = (order_name,order_by,order_direction) => (
    { 
        type: UPDATE_ORDER,order_name,order_by,order_direction
    }
    )
export default itemsReducer;