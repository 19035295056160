import React , { useState } from 'react';
import b from './post.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';
import { dbAPI } from '../../../../api/api_db';
import { get_info_user,filter_data } from '../../../common/common';
const Edit = (props) =>{
  let newStatus= React.createRef();
  let newFID= React.createRef();
  let newEXID= React.createRef();
  let update_data_w = (where) => {
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => { 
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
        let answer=filter_data(props.current_type,data,props.current_type)
        props.update_data_w(answer[0],answer[1]) 
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_db_data = (where,new_data) => {
    dbAPI.update_db_data(props.db_name,where,new_data)        
    .then(result => { 
      if(result.data.status==200){
        update_data_w('')
      }
      else{
        alert(`ERROR: ${result.data.status}`)
      }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let onFIDChange=()=>{
    let data=newFID.current.value;
    data=data.replace(/^\s+/,'');
    props.update_f_id_w(data)
    console.log(`onFIDChange ${data}`)
  }
  let onEXIDChange=()=>{
    let data=newEXID.current.value;
    data=data.replace(/^\s+/,'');
    props.update_ex_id_w(data)
    console.log(`onEXIDChange ${data}`)
  }
  let edit=() =>{
    var where=`order_id='${props.order_id}' AND author_id='${props.author_id}'`
    var new_data=`freelancer_id=${props.f_id_data},executor_id=${props.ex_id_data}`
    update_db_data(where,new_data)
    props.update_f_id_w('')
    props.update_ex_id_w('')
  }
  let edit_f_id=() =>{
    var where=`order_id='${props.order_id}' AND author_id='${props.author_id}'`
    var new_data=`freelancer_id=${props.f_id_data}`
    update_db_data(where,new_data)
    props.update_f_id_w('')
  }
  let edit_ex_id=() =>{
    var where=`order_id='${props.order_id}' AND author_id='${props.author_id}'`
    var new_data=`executor_id=${props.ex_id_data}`
    update_db_data(where,new_data)
    props.update_ex_id_w('')
  }
  let update_status=(new_status) =>{
    var where=`order_id='${props.order_id}' AND author_id='${props.author_id}'`
    var new_data=`status='${new_status}'`
    update_db_data(where,new_data)
  }

  const {
    className
  } = props;
  const [modal, setModal] = useState(false);
  const toggle = () =>{
    setModal(!modal);
  }
  let get_type=(status) =>{
    if(status=='done'){
       return "Закрыт"
     }
    if(status=='in_progress'){
    return "Без исполнителя"
    }
    if(status=='active'){
      return "Активный"
    }
   if(status=='deleted'){
   return "Удален"
   }
    return ""
  }
  return(
    <b>
    <Button className={b.serbut} outline color="primary" onClick={toggle}>Edit</Button>
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>Order ID: {props.order_id}</ModalHeader>
      <ModalBody>
      Status {get_type(props.status)}
      <br/>
      Manager ID {props.freelancer_id} :
      {get_info_user(props.freelancer_id,props.users_data)}
      <br/>
      <input type="number" min="0" onChange={onFIDChange} pattern={"^[0-9]*$"}
              ref={newFID}  value={props.f_id_data} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.f_id_data} color="primary" onClick={edit_f_id}>Update Manager ID</Button>
      <br/>
      Исполнитель ID {props.executor_id} :
      {get_info_user(props.executor_id,props.users_data)}
      <br/>
      <input type="number" min="0" onChange={onEXIDChange} pattern={"^[0-9]*$"}
              ref={newEXID}  value={props.ex_id_data} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.ex_id_data} color="primary" onClick={edit_ex_id}>Update Исполнитель ID</Button>
      <br/>
      Статус заказа (нажатие сразу меняет статус заказа)<br/>Текущий статус {get_type(props.status)}
      <br/>
      <UncontrolledDropdown >
        <DropdownToggle className={b.serbut} caret>Статус {get_type(props.status_data)}</DropdownToggle>
        <DropdownMenu >
        <DropdownItem onClick={() => update_status('done')}>Закрыт</DropdownItem>
        <DropdownItem onClick={() => update_status('in_progress')}>Без исполнителя</DropdownItem>
        <DropdownItem onClick={() => update_status('active')}>Активный</DropdownItem>
        <DropdownItem onClick={() => update_status('deleted')}>Удален</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      </ModalBody>
      <ModalFooter>
        <Button className={b.serbut} disabled={ !props.f_id_data || !props.ex_id_data} color="primary" onClick={edit}>Update All</Button>
        <Button className={b.serbut} color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)





}


export default Edit
