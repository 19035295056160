// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n.Check_rightcol__QNMSD {\r\n  position: relative;\r\n  display: flex;\r\n  width: 40px;\r\n  float: right;\r\n  justify-content: center;\r\n  color: #fff;\r\n  background: #343a40;\r\n  border-radius: 23px;\r\n    }\r\n\r\n\r\n/* Table\r\n**************************/\r\n\r\n.Check_table_name__SlobR{\r\n  width: 15%;}\r\n.Check_table_price__bvE5B{width: 15%;}\r\n.Check_table_status__jk0Ou{width: 15%;}\r\n.Check_table_date__m1JI9{width: 20%;}\r\n.Check_table_actions__GukE4{width: 35%;}\r\n", "",{"version":3,"sources":["webpack://./src/Components/Checks/Check/Check.module.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,WAAW;EACX,mBAAmB;EACnB,mBAAmB;IACjB;;;AAGJ;0BAC0B;;AAE1B;EACE,UAAU,CAAC;AACb,0BAAa,UAAU,CAAC;AACxB,2BAAc,UAAU,CAAC;AACzB,yBAAY,UAAU,CAAC;AACvB,4BAAe,UAAU,CAAC","sourcesContent":["\r\n.rightcol {\r\n  position: relative;\r\n  display: flex;\r\n  width: 40px;\r\n  float: right;\r\n  justify-content: center;\r\n  color: #fff;\r\n  background: #343a40;\r\n  border-radius: 23px;\r\n    }\r\n\r\n\r\n/* Table\r\n**************************/\r\n\r\n.table_name{\r\n  width: 15%;}\r\n.table_price{width: 15%;}\r\n.table_status{width: 15%;}\r\n.table_date{width: 20%;}\r\n.table_actions{width: 35%;}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightcol": "Check_rightcol__QNMSD",
	"table_name": "Check_table_name__SlobR",
	"table_price": "Check_table_price__bvE5B",
	"table_status": "Check_table_status__jk0Ou",
	"table_date": "Check_table_date__m1JI9",
	"table_actions": "Check_table_actions__GukE4"
};
export default ___CSS_LOADER_EXPORT___;
