
import sidebarReducer from "./sidebar-reducer";
import usersReducer from "./users-reducer";
import authReducer from "./auth-reducer";
import formReducer from "./form-reducer";
import appReducer from "./app-reducer";
import checksReducer from "./checks-reducer";
import ordersReducer from "./orders-reducer";
import itemsReducer from "./items-reducer";
import thunkMiddleware from 'redux-thunk';
import smsReducer from "./sms-reducer";
import phoneReducer from "./phone-reducer";
import priceReducer from "./price-reducer";
import sectionReducer from "./section-reducer";
const { createStore, combineReducers, applyMiddleware } = require("redux");

let reducers=combineReducers({
    sidebar: sidebarReducer,
    users: usersReducer,
    auth: authReducer,
    form: formReducer,
    app:appReducer,
    checks:checksReducer,
    orders:ordersReducer,
    items:itemsReducer,
    sms:smsReducer,
    phone:phoneReducer,
    price:priceReducer,
    section:sectionReducer
});

let store= createStore(reducers,applyMiddleware(thunkMiddleware));
window.store=store;
export default store;