import React , { useState } from 'react';
import b from './post.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';

const Search = (props) =>{
  let newPhoneNumber= React.createRef();
  let onPhoneNumberChange=()=>{
    let data=newPhoneNumber.current.value;
    data=data;
    props.update_add_phone_number_w(data)
  }
  const {
    className
  } = props;
  const [modal, setModal] = useState(false);
  const toggle = () =>{
    setModal(!modal);
  }
  let search_phone=(cleaned_phones) =>{
    var new_data=[]
    var choosen_phones=[]
    for (let i = 0; i < props.data.length; i++) {
      var data_el=props.data[i]
      for (let y = 0; y < cleaned_phones.length; y++) {
        if(data_el.phone.includes(cleaned_phones[y])&&!choosen_phones.includes(cleaned_phones[y])){
          data_el.show=true
          data_el.select=true
          choosen_phones.push(cleaned_phones[y])
          break
        }
        else{
          data_el.show=false
          data_el.select=false
        }
      }
      new_data.push(data_el)
    }
    props.update_data_w(new_data,'')
    var answer=`Нашёл: ${choosen_phones.length}\n${choosen_phones}`
    props.update_add_phone_number_w(answer)
  }
  let search=() =>{
    var phones=props.add_phone_number.split('\n')
    var cleaned_phones=[]
    for (let i = 0; i < phones.length; i++) {
      if(phones[i].length>9){
            var phone_number=phones[i]
            cleaned_phones.push(phone_number)
      }
    }
    search_phone(cleaned_phones)
  }
  return(
    <b>
    <Button className={b.serbut} outline color="primary" onClick={() =>
    {
      toggle()
    }
    }>🔎Поиск</Button>
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>Номера:</ModalHeader>
      <ModalBody>  
      <br/>
      Номера:
      <br/>
      <textarea class={b.textarea} role="textbox" onChange={onPhoneNumberChange}
              ref={newPhoneNumber}  value={props.add_phone_number} placeholder={"Enter "}/>      
      <br/>
      </ModalBody>
      <ModalFooter>
        <Button className={b.serbut} disabled={!props.add_phone_number} color="primary" onClick={search}>🔎Поиск</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)





}


export default Search
