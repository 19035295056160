import React  from 'react';
import b from './User.module.css';
import Edit from './edit/edit.jsx'
import Delete from './delete/delete.jsx'
import Show from './Show/Show.jsx'
import  {Table} from 'reactstrap';

const User = (props) =>{
   let get_money=(status) =>{
      if(status=='admin'){
         return <p>Баланс: {props.credits}$</p>
       }
      if(status=='customer'){
      return <p>Потратил: {props.balance}$<br/>Баланс: {props.credits}$</p>
      }
      if(status=='freelancer'){
        return (
         <p>Заработок: {props.balance}$<br/>Не выплачено: {props.unpaid}$</p>
        )
      }
      return ""
    }
   let get_type=(status) =>{
      if(status=='admin'){
         return "Админ"
       }
      if(status=='customer'){
      return "Клиент"
      }
      if(status=='freelancer'){
        return <p>Исполнитель {props.work_theme}</p>
      }
      return ""
    }
   if(props.show===true) {  
   return(
   <Table bordered hover size="sm" cellSpacing="1" >
      <thead id={b.remover}>
         <tr>
         </tr>
      </thead>
      <tbody > <tr>
         <td  className={b.table_order_id}>
            {props.user_id}
         </td>
         <td  className={b.table_name}>
            {props.name}<br/>
            <a href={`https://t.me/${props.username}`} target="_blank">@{props.username}</a>
         </td>
         <td  className={b.table_price}>
         {get_money(props.status)}
         </td>
         <td  className={b.table_status}>
            {get_type(props.status)}
         </td>
         <td  className={b.table_date}>
            {props.id}
         </td>
         <td  className={b.table_actions}>
            <Edit
               name={props.name}
               username={props.username}
               user_id={props.user_id}
               id={props.id}
               work_theme={props.work_theme}
               date={props.date}
               balance={props.balance}
               unpaid={props.unpaid}
               credits={props.credits}
               online={props.online}
               description={props.description}
               status={props.status}

               update_data_w={props.update_data_w}
               update_unpaid_w={props.update_unpaid_w}
               update_credits_w={props.update_credits_w}
               update_balance_w={props.update_balance_w}
               current_type={props.current_type}
               db_name={props.db_name}
               data_balance={props.data_balance}
               data_credits={props.data_credits}
               data_unpaid={props.data_unpaid}
               />
            <Delete
               name={props.name}
               username={props.username}
               user_id={props.user_id}
               id={props.id}
               work_theme={props.work_theme}
               date={props.date}
               balance={props.balance}
               unpaid={props.unpaid}
               credits={props.credits}
               online={props.online}
               description={props.description}
               status={props.status}
               update_data_w={props.update_data_w}
               current_type={props.current_type}
               db_name={props.db_name}
               />
            <Show
               name={props.name}
               username={props.username}
               user_id={props.user_id}
               id={props.id}
               work_theme={props.work_theme}
               date={props.date}
               balance={props.balance}
               unpaid={props.unpaid}
               credits={props.credits}
               online={props.online}
               description={props.description}
               status={props.status}
               db_name={props.db_name}
               />
         </td>
         </tr>
      </tbody>
   </Table>
   )}
   else{
      return(<></>)
   }
}


export default User
