
import './App.css';
import NavbarContainer from './Components/Navbar/NavbarContainer';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginContainer from './Components/Login/LoginContainer';
import ChecksContainer from './Components/Checks/ChecksContainer';
import OrdersContainer from './Components/Orders/OrdersContainer';
import UsersContainer from './Components/Users/UsersContainer';
import StatisticsContainer from './Components/Statistics/StatisticsContainer';
import SMSContainer from './Components/SMS/SMSContainer';
import PhonesContainer from './Components/Phones/PhonesContainer';
import PricesContainer from './Components/Prices/PricesContainer';
import SectionsContainer from './Components/Sections/SectionsContainer';
import PhonesContainerBASKET from './Components/PhonesBASKET/PhonesContainer';
const App = (props) => {
    return (

      <div className="app-wrapper">
        <NavbarContainer />
        <div className='app-wrapper-content'>
          <Routes>
            <Route exact path="/"
              element={
                <LoginContainer />} />
            <Route exact path="/checks"
              element={
                <ChecksContainer />} />
            <Route exact path="/orders"
              element={
                <OrdersContainer />} />
            <Route exact path="/users"
              element={
                <UsersContainer />} />
            <Route exact path="/statistics"
              element={
                <StatisticsContainer />} />
            <Route exact path="/sms"
              element={
                <SMSContainer />} />
            <Route exact path="/phone"
              element={
                <PhonesContainer />} />
            <Route exact path="/prices"
              element={
                <PricesContainer />} />
            <Route exact path="/sections"
              element={
                <SectionsContainer />} />      
            <Route exact path="/basket"
              element={
                <PhonesContainerBASKET />} />            
          </Routes>
        </div>

      </div>
    );
  }


export default App;