// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Show_serbut__j6m\\+s{\r\n  margin-left: 10px;\r\n}\r\n.Show_space__LMxxE{\r\nwhite-space: pre-line;\r\n}", "",{"version":3,"sources":["webpack://./src/Components/Orders/Order/Show/Show.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;AACA,qBAAqB;AACrB","sourcesContent":[".serbut{\r\n  margin-left: 10px;\r\n}\r\n.space{\r\nwhite-space: pre-line;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serbut": "Show_serbut__j6m+s",
	"space": "Show_space__LMxxE"
};
export default ___CSS_LOADER_EXPORT___;
