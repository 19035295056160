import React  from 'react';
import b from './Paginator.module.css';

const Paginator=(props) =>{
  function* chunks(arr, n) {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  }
  let change_page=(value,pages_amount) =>{
    if(!(props.current_page+value>=pages_amount) && !(props.current_page+value<0)){
      props.update_current_page_w(props.current_page+value)
    }
  }
  let set_page=(value) =>{
      props.update_current_page_w(value)
  }
  var pages_amount=[...chunks(props.data, 400)].length
  var pages_data=Array.from(Array(pages_amount).keys())
  let pages = pages_data.map(
    page => 
    <li  onClick={() => {
      set_page(page);
      }}> <span className={props.current_page==page?b.pagination_active:''}>{page+1}</span></li>
    );
  if(props.data.length<=400){return ''}
  return(
    <div className={b.table_container}>
        <div className={b.pagination_container}>
            <span className={b.pagination_wrapper}>
              <span className={b.pagination_extreme} 
                onClick={() => {
                  change_page(-1,pages_amount);
                  }}
              >⬅️</span>
              <ul className={b.pagination}>
              {pages}
              </ul>
              <span className={b.pagination_extreme} 
                onClick={() => {
                        change_page(1,pages_amount);
                        }}>➡️</span>
            </span>          
        </div>
    </div>
            )}



export default Paginator
