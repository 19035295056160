
import {update_data,
  update_data_,
  update_add_link,
  update_add_type,
  update_add_sub_type,
  update_add_price,
  update_add_phone_number,
  update_add_section,
  update_u_st,
  update_u_sections,
  update_u_senders
}  
  from '../../../redux/phone-reducer.js';
import { connect } from 'react-redux';
import OrdersButtons from './PhonesButtons.jsx';

let mapStateToProps=(state)=>{
  return{
    data:state.phone.data,
    current_type:state.phone.current_type,
    order_by:state.phone.order_by,

    add_link:state.phone.add_link,
    add_type:state.phone.add_type,
    add_sub_type:state.phone.add_sub_type,
    add_price:state.phone.add_price,
    add_phone_number:state.phone.add_phone_number,
    add_section:state.phone.add_section,

    unique_senders:state.phone.unique_senders,
    unique_sub_types:state.phone.unique_sub_types,
    u_sections:state.phone.u_sections,
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    },
    update_data_w_:(data)=>{
      dispatch(update_data_(data));
    },
    update_add_link_w:(data)=>{
      dispatch(update_add_link(data));
    },
    update_add_type_w:(data)=>{
      dispatch(update_add_type(data));
    },
    update_add_sub_type_w:(data)=>{
      dispatch(update_add_sub_type(data));
    },
    update_add_price_w:(data)=>{
      dispatch(update_add_price(data));
    },
    update_add_phone_number_w:(data)=>{
      dispatch(update_add_phone_number(data));
    },
    update_add_section_w:(data)=>{
      dispatch(update_add_section(data));
    },
    update_u_st_w:(data)=>{
      dispatch(update_u_st(data));
    },
    update_u_sections_w:(data)=>{
      dispatch(update_u_sections(data));
    },
    update_u_senders_w:(data)=>{
      dispatch(update_u_senders(data));
    }
  }
}
const PhonesButtonsContainer = connect(mapStateToProps,mapDispatchToProps)(OrdersButtons);
export default PhonesButtonsContainer;
