
import {update_data_,update_order,update_data } from '../../../redux/orders-reducer.js';
import { connect } from 'react-redux';
import Head from './Head.jsx';
 
let mapStateToProps=(state)=>{
  return{
    data:state.orders.data,
    current_type:state.orders.current_type,
    order_name:state.orders.order_name,
    order_by:state.orders.order_by,
    order_direction:state.orders.order_direction
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    },
    update_data_w_:(data)=>{
      dispatch(update_data_(data));
    },
    update_order_w:(order_name,order_by,order_direction)=>{
      dispatch(update_order(order_name,order_by,order_direction));
    }
  }
}
const HeadContainer = connect(mapStateToProps,mapDispatchToProps)(Head);
export default HeadContainer;
