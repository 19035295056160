
import {update_data } from '../../../redux/checks-reducer.js';
import { connect } from 'react-redux';
import Check from './Check.jsx';

let mapStateToProps=(state)=>{
  return{
    data:state.checks.data,
    current_type:state.checks.current_type,

    users_data:state.users.data
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    }
  }
}
const CheckContainer = connect(mapStateToProps,mapDispatchToProps)(Check);
export default CheckContainer;
