// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n.Phone_rightcol__7HnoC {\r\n  position: relative;\r\n  display: flex;\r\n  width: 40px;\r\n  float: right;\r\n  justify-content: center;\r\n  color: #fff;\r\n  background: #343a40;\r\n  border-radius: 23px;\r\n    }\r\n\r\n    .Phone_serbut__eaSNl{\r\n      margin-left: 10px;\r\n    }\r\n    .Phone_serbut2__mI58T{\r\n      margin-left: 20px;\r\n    }\r\n/* Table\r\n**************************/\r\n.Phone_table_order_id__eLbLh{\r\n  width: 12.5%;}\r\n.Phone_table_name__Ahubj{\r\n  width: 15%;}\r\n.Phone_table_price__c1ieb{width: 15%;}\r\n.Phone_table_status__QMcHz{width: 15%;}\r\n.Phone_table_date__x91JP{width: 17.5%;}\r\n.Phone_table_actions__IGz0Z{width: 25%;}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/Components/PhonesBASKET/Phone/Phone.module.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,WAAW;EACX,mBAAmB;EACnB,mBAAmB;IACjB;;IAEA;MACE,iBAAiB;IACnB;IACA;MACE,iBAAiB;IACnB;AACJ;0BAC0B;AAC1B;EACE,YAAY,CAAC;AACf;EACE,UAAU,CAAC;AACb,0BAAa,UAAU,CAAC;AACxB,2BAAc,UAAU,CAAC;AACzB,yBAAY,YAAY,CAAC;AACzB,4BAAe,UAAU,CAAC","sourcesContent":["\r\n.rightcol {\r\n  position: relative;\r\n  display: flex;\r\n  width: 40px;\r\n  float: right;\r\n  justify-content: center;\r\n  color: #fff;\r\n  background: #343a40;\r\n  border-radius: 23px;\r\n    }\r\n\r\n    .serbut{\r\n      margin-left: 10px;\r\n    }\r\n    .serbut2{\r\n      margin-left: 20px;\r\n    }\r\n/* Table\r\n**************************/\r\n.table_order_id{\r\n  width: 12.5%;}\r\n.table_name{\r\n  width: 15%;}\r\n.table_price{width: 15%;}\r\n.table_status{width: 15%;}\r\n.table_date{width: 17.5%;}\r\n.table_actions{width: 25%;}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightcol": "Phone_rightcol__7HnoC",
	"serbut": "Phone_serbut__eaSNl",
	"serbut2": "Phone_serbut2__mI58T",
	"table_order_id": "Phone_table_order_id__eLbLh",
	"table_name": "Phone_table_name__Ahubj",
	"table_price": "Phone_table_price__c1ieb",
	"table_status": "Phone_table_status__QMcHz",
	"table_date": "Phone_table_date__x91JP",
	"table_actions": "Phone_table_actions__IGz0Z"
};
export default ___CSS_LOADER_EXPORT___;
