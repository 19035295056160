
import {update_current_page} from '../../../redux/phone-reducer.js';
import { connect } from 'react-redux';
import Paginator from './Paginator.jsx';

let mapStateToProps=(state)=>{
  return{
    data:state.phone.data,
    current_page:state.phone.current_page
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_current_page_w:(data)=>{
      dispatch(update_current_page(data));
    }
  }
}
const PaginatorContainer = connect(mapStateToProps,mapDispatchToProps)(Paginator);
export default PaginatorContainer;
