import React  from 'react';
import b from './Check.module.css';
import Delete from './delete/delete.jsx'
import Show from './Show/Show.jsx'
import  {Table} from 'reactstrap';

const Check = (props) =>{
   let get_type=(status) =>{
      if(status=='done'){
         return "Оплачен"
       }
      if(status=='in_progress'){
      return "Не оплачен"
      }
      if(status=='active'){
         return "Ожидает пополнение"
       }
     if(status=='canceled'){
     return "Удален"
     }
      return "ERROR"
    }
    let get_order_id=(order_id) =>{
      if(order_id=='ADD_FUNDS'){
         return ""
       }
      return order_id
    }
    let get_check_type=(type) =>{
      if(type=='ADD_FUNDS'){
         return "Пополнение баланса"
       }
       if(type=='item'){
         return "Оплата товара"
       }
       if(type=='service'){
         return "Оплата услуги"
       }
      return 'ERROR'
    }
    if(props.show===true) { 
  return(
<Table bordered hover size="sm" cellSpacing="1" >
   <thead id={b.remover}>
      <tr>
      </tr>
   </thead>
   <tbody >
   <tr>
      <td  className={b.table_name}>
         {get_check_type(props.type)}<br/>
         {get_order_id(props.order_id)}
         <b className={b.rightcol} >{props.count}</b>
      </td>
      <td  className={b.table_price}>
         {props.price}$
      </td>
      <td  className={b.table_status}>
         {get_type(props.status)}
      </td>
      <td  className={b.table_date}>
         {props.date}
      </td>
      <td  className={b.table_actions}>
         <Delete
            author_id={props.author_id}
            address={props.address}
            amount={props.amount}
            check_id={props.check_id}
            currency={props.currency}
            date={props.date}
            order_id={props.order_id}
            payment_id={props.payment_id}
            price={props.price}
            status={props.status}
            type={props.type}
            update_data_w={props.update_data_w}
            current_type={props.current_type}
            db_name={props.db_name}
            />
         <Show
            users_data={props.users_data}
            author_id={props.author_id}
            address={props.address}
            amount={props.amount}
            check_id={props.check_id}
            currency={props.currency}
            date={props.date}
            order_id={props.order_id}
            payment_id={props.payment_id}
            price={props.price}
            status={props.status}
            type={props.type}
            />
      </td>
      </tr>
   </tbody>
</Table>
)}
else{
   return(<></>)
}
}


export default Check
