import React  from 'react';
import b from './Phone.module.css';
import Edit from './edit/edit.jsx'
import Delete from './delete/delete.jsx'
import Show from './Show/Show.jsx'
import  {Table,Button} from 'reactstrap';
import { get_info_user,set_new_data } from '../../common/common';
import { dbAPI } from '../../../api/api_db';
import { filter_data,get_show_items_amount } from '../../common/common';
const Phone = (props) =>{
   let ex_date=''
   let formattedToday=''
   if(props.expiry_date){
      try {
         let split_date=props.expiry_date.split(' ');
         let s_date=split_date[0].split('-')
         let s_time=split_date[1].split('.')[0]
         let parse_date = new Date(props.expiry_date);
         parse_date.setDate(parse_date.getDate() + props.active_period);
         const yyyy = parse_date.getFullYear();
         let mm = parse_date.getMonth() + 1; // Months start at 0!
         let dd = parse_date.getDate();
         let hh = parse_date.getHours()+1;
         let minu = parse_date.getMinutes();
         let ss = parse_date.getSeconds();
         if (dd < 10) dd = '0' + dd;
         if (mm < 10) mm = '0' + mm;
         
         formattedToday = dd + '-' + mm + '-' + yyyy +' '+hh+':'+minu+':'+ss;
         ex_date=`${s_date[2]}-${s_date[1]}-${s_date[0]} ${s_time}`;
      } catch (error) {
         ex_date=props.expiry_date;
         formattedToday='-';
      }

   }
   const phone_obj=
      {
         id: props.id,
         phone: props.phone_number,
         owner: props.owner,
         key_word: props.key_word,
         expiry_date: props.expiry_date,
         order_id: props.order_id,
         status: props.status,
         type: props.type,
         info: props.info,
         price: props.price,
         operator: props.operator,
         received_id: props.received_id,
         alert_3_days: props.alert_3_days,
         alert_1_hour: props.alert_1_hour,
         active_period: props.active_period,
         rent_type: props.rent_type,
         image: props.image,
         section: props.section,
         initialisation: props.initialisation,
         port: props.port,
         server: props.server,
         select:props.select,
         show:true,
         page:props.page,
     }      
   
   let get_rent_type=() =>{
      if(props.rent_type=='white'){
         return `⬜️`
         }
      if(props.rent_type=='black'){
         return `⬛️`
      }
      return "ERROR "
    }
   let get_status=() =>{
      if(props.status==2){
         return <span style={{color:'blue'}}>⏱Ожидает продления</span>
         }
      if(props.status==1){
         return <span style={{color:'orange'}}>⏳В аренде</span>
      }
      if(props.status==0){
      return <span style={{color:'grey'}}>🆓Не использован</span>
      }
      if(props.status==3){
         return <span style={{color:'red'}}>Удалить</span>
         }
      return props.status
    }
if(props.show===true && (props.current_page==props.page || get_show_items_amount(props.data)<401)) { 
   let get_sernders=(phone_number,data) =>{
         try{
            let u_senders=[...new Set()]
            for (let i = 0; i < data.length; i++) {
               if((data[i].phone_number.includes(phone_number))&&(!u_senders.includes(data[i].sender))){
                  let new_el=data[i].sender
                  u_senders.push(new_el)
               }
            }
            let u_senders2=[...new Set()]
            for (let i = 0; i < u_senders.length; i++) {
                  let new_el=u_senders[i]+' '
                  if(u_senders2.length%4==0){
                     new_el+='\n'
                  }
                  u_senders2.push(new_el)
            }
            let return_data='Нет SMS'
            if(u_senders.length>0){
               return_data=u_senders2
            }
            return return_data
         }
         catch (err) {
            return '-'
          }
   }
   let update_data_w = (new_data) => {
        let data=[]
        for (let i = 0; i < new_data.length; i++) {
          let copy_data=new_data[i]
          copy_data.show=true
          data.push(copy_data)
        }
        let answer=filter_data(props.current_type,data,props.current_type)
        props.update_data_w(answer[0],answer[1])  
    }
   let update_db_data = (where,new_data) => {
      dbAPI.update_db_data(props.db_name,where,new_data)        
      .then(result => { 
         if(result.data.status==200){
            //update_data_w('')
          }
          else{
            alert(`ERROR: ${result.data.status}`)
          }
      })
      .catch(error => { console.error(error); return Promise.reject(error); })  
    }
   let reset_phone=() =>{
      var where=`id='${props.id}'`
      var new_data=`status=0,active_period=0,owner=0,expiry_date='',order_id='',received_id='',alert_3_days='${false}',alert_1_hour='${false}'`
      update_db_data(where,new_data)
      var new_phone_obj=phone_obj
      new_phone_obj.status=0
      new_phone_obj.active_period=0
      new_phone_obj.expiry_date=''
      new_phone_obj.order_id=''
      new_phone_obj.received_id=''
      new_phone_obj.alert_3_days=false
      new_phone_obj.alert_1_hour=false
      var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj) 
      update_data_w(edited_data)
    }
   let add_rent=(days) =>{
      var where=`id='${props.id}'`
      var new_data=`active_period=${props.active_period+days}`
      update_db_data(where,new_data)
      var new_phone_obj=phone_obj
      new_phone_obj.active_period=props.active_period+days
      var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj)
      update_data_w(edited_data)
    }
    let set_rent_1_hour=() =>{
      var where=`id='${props.id}'`
      var choosen_date=new Date(new Date().toLocaleString("en-US", {timeZone: 'Europe/Moscow'}))
      var day=choosen_date.getDate().toString().padStart(2, "0")
      var month=(choosen_date.getMonth() + 1).toString().padStart(2, "0")
      var year=choosen_date.getFullYear()
      choosen_date.setHours(choosen_date.getHours()+1);
      var hours=(choosen_date.getHours()).toString().padStart(2, "0")
      var minutes=(choosen_date.getMinutes()).toString().padStart(2, "0")
      var expiry_date=`${year}-${month}-${day} ${hours}:${minutes}:06.768591+03:00`
      var new_data=`status=1,active_period=0,expiry_date='${expiry_date.replaceAll('+', '@')}',alert_3_days='${true}',alert_1_hour='${true}'`
      
      update_db_data(where,new_data)
      var new_phone_obj=phone_obj
      new_phone_obj.status=1
      new_phone_obj.active_period=0
      new_phone_obj.expiry_date=expiry_date
      new_phone_obj.alert_3_days=true
      new_phone_obj.alert_1_hour=true
      var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj)
      update_data_w(edited_data)

    }
    let set_BW_owner=() =>{
      var where=`id=${props.id}`
      var choosen_date=new Date(new Date().toLocaleString("en-US", {timeZone: 'Europe/Moscow'}))
      var day=choosen_date.getDate().toString().padStart(2, "0")
      var month=(choosen_date.getMonth() + 1).toString().padStart(2, "0")
      var year=choosen_date.getFullYear()
      var hours=(choosen_date.getHours()).toString().padStart(2, "0")
      var minutes=(choosen_date.getMinutes()).toString().padStart(2, "0")
      var expiry_date=`${year}-${month}-${day} ${hours}:${minutes}:06.768591+03:00`
      var new_data=`status=1,active_period=1,owner=5916131241,expiry_date='${expiry_date.replaceAll('+', '@')}',order_id='BW0SET',received_id='5916131241',alert_3_days='${true}',alert_1_hour='${true}'`
      update_db_data(where,new_data)

      var new_phone_obj=phone_obj
      new_phone_obj.status=1
      new_phone_obj.active_period=1
      new_phone_obj.owner=5916131241
      new_phone_obj.expiry_date=expiry_date
      new_phone_obj.order_id='BW0SET'
      new_phone_obj.received_id='5916131241'
      var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj)
      update_data_w(edited_data)
    }
    let set_GR_owner=() =>{
      var where=`id=${props.id}`
      var choosen_date=new Date(new Date().toLocaleString("en-US", {timeZone: 'Europe/Moscow'}))
      var day=choosen_date.getDate().toString().padStart(2, "0")
      var month=(choosen_date.getMonth() + 1).toString().padStart(2, "0")
      var year=choosen_date.getFullYear()
      var hours=(choosen_date.getHours()).toString().padStart(2, "0")
      var minutes=(choosen_date.getMinutes()).toString().padStart(2, "0")
      var expiry_date=`${year}-${month}-${day} ${hours}:${minutes}:06.768591+03:00`
      var new_data=`status=1,active_period=1,owner=1667259725,expiry_date='${expiry_date.replaceAll('+', '@')}',order_id='GR0SET',received_id='-1001924027224',alert_3_days='${true}',alert_1_hour='${true}'`
      update_db_data(where,new_data)

      var new_phone_obj=phone_obj
      new_phone_obj.status=1
      new_phone_obj.active_period=1
      new_phone_obj.owner=1667259725
      new_phone_obj.expiry_date=expiry_date
      new_phone_obj.order_id='GR0SET'
      new_phone_obj.received_id='-1001924027224'
      var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj)
      update_data_w(edited_data)
    }
    let set_rent_day=(days) =>{
      var where=`id=${props.id}`
      var choosen_date=new Date(new Date().toLocaleString("en-US", {timeZone: 'Europe/Moscow'}))
      var day=choosen_date.getDate().toString().padStart(2, "0")
      var month=(choosen_date.getMonth() + 1).toString().padStart(2, "0")
      var year=choosen_date.getFullYear()
      var hours=(choosen_date.getHours()).toString().padStart(2, "0")
      var minutes=(choosen_date.getMinutes()).toString().padStart(2, "0")
      var expiry_date=`${year}-${month}-${day} ${hours}:${minutes}:06.768591+03:00`
      var new_data=`status=1,active_period=${days},owner=${props.owner},expiry_date='${expiry_date.replaceAll('+', '@')}',order_id='',received_id='${props.received_id}',alert_3_days='${true}',alert_1_hour='${true}'`
      update_db_data(where,new_data)

      var new_phone_obj=phone_obj
      new_phone_obj.status=1
      new_phone_obj.active_period=days
      new_phone_obj.owner=props.owner
      new_phone_obj.expiry_date=expiry_date
      new_phone_obj.order_id=''
      new_phone_obj.received_id=props.received_id
      var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj)
      update_data_w(edited_data)
     
    }
    let send_test_msg = () => {
      dbAPI.send_sms(props.phone_number)        
      .then(result => { 
         if(result.data.status==200){
            //pass
          }
          else{
            alert(`ERROR: ${result.data.status}`)
          }
      })
      .catch(error => { console.error(error); return Promise.reject(error); })  
    }
    let checkbox_onClick = () => {
      var new_select_status=false
      if(props.select==true){
         new_select_status=false
      }
      if(props.select==false){
         new_select_status=true
      }
      var new_phone_obj=phone_obj
      new_phone_obj.select=new_select_status
      var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj)
      update_data_w(edited_data)
    }
  return(
   <tr className={props.select?b.selected:''} >
      <td  className={b.table_order_id} onClick={checkbox_onClick}>
         {props.phone_number}<br/>
         {(props.image&&props.image.length)>4?'📥':''}{props.key_word=='fullRent'?'Полная аренда':props.key_word} {props.section}
         <div className={b.checkbox_wrapper}>
            <input type="checkbox" checked={props.select} onClick={()=>{
               checkbox_onClick()
            }}/>
         </div>
      </td>
      <td  className={b.table_price} onClick={checkbox_onClick}>
         <b>{(props.owner && props.owner.toString().length>1)?props.owner:``}</b>
         {(props.owner && props.owner.toString().length>1)?get_info_user(props.owner,props.users_data):`Нет владельца`}
         <br/>
         {(props.owner &&props.received_id &&props.owner.toString()==props.received_id )?'SMS идут владельцу':props.received_id?`SMS иду на ${props.received_id}`:''}
      </td>
      <td  className={b.table_status} onClick={checkbox_onClick}>
         {get_status()}<br/>
         <small>[{get_sernders(props.phone_number,props.sms_data)}]</small>
      </td>
      <td  className={b.table_date}>
      {(props.expiry_date)?<div>
         🚀{ex_date}<br/>⏱{formattedToday}<br/>
         {props.active_period?<span>
         🗓{props.active_period}<br/></span>:''}
         <Button  color="dark" size='sm' onClick={() => set_rent_1_hour()}>⌛1 ч.</Button>
         <Button  className={b.serbut} color="dark" size='sm' onClick={() => add_rent(1)}>+1</Button>
         <Button className={b.serbut} color="dark" size='sm' onClick={() => add_rent(7)}>+7</Button>
         <Button className={b.serbut} color="dark" size='sm' onClick={() => add_rent(15)}>+15</Button>
         <Button className={b.serbut} color="dark" size='sm' onClick={() => add_rent(30)}>+30</Button>
         </div>:(props.owner&&props.received_id)?
         <div>
            <Button  color="dark" size='sm' onClick={() => set_rent_1_hour()}>⌛1 час</Button>
            <Button  className={b.serbut} color="dark" size='sm' onClick={() => set_rent_day(1)}>🌅1 день</Button>
         </div>
         :'Нет владельца'}
      </td>
      <td  className={b.table_actions}>
         <Edit
            id={props.id}
            operator={props.operator}
            owner={props.owner}
            section={props.section}
            received_id={props.received_id}
            phone_number={props.phone_number}
            order_id={props.order_id}
            status={get_status()}
            key_word={props.key_word}
            expiry_date={props.expiry_date}
            db_name={props.db_name}
            update_data_w={props.update_data_w}
            current_type={props.current_type}
            data_service={props.data_service}
            data_link={props.data_link}
            data_status={props.data_status}
            data_owner={props.data_owner}
            data_price={props.data_price}
            data_image={props.data_image}
            loading_status={props.loading_status}
            update_received_id_w={props.update_received_id_w}
            data_received_id={props.data_received_id}
            data_description={props.data_description}
            update_service_w={props.update_service_w}
            update_link_w={props.update_link_w}
            update_status_w={props.update_status_w}
            update_owner_w={props.update_owner_w}
            update_price_w={props.update_price_w}
            update_description_w={props.update_description_w}
            users_data={props.users_data}
            price={props.price}
            black_price={props.black_price}
            data_active_period={props.data_active_period}
            update_active_period_w={props.update_active_period_w}

            data_white_price={props.data_white_price}
            update_white_price_w={props.update_white_price_w}
            data_black_price={props.data_black_price}
            update_black_price_w={props.update_black_price_w}
            update_image_w={props.update_image_w}
            update_loading_status_w={props.update_loading_status_w}
            u_sections={props.u_sections}
            update_u_sections_w={props.update_u_sections_w}

            phone_obj={phone_obj}
            data={props.data}
            />
         <Delete
            id={props.id}
            db_name={props.db_name}
            update_data_w={props.update_data_w}
            current_type={props.current_type}
            />
         <Show
            id={props.id}
            owner={props.owner}
            phone_number={props.phone_number}
            order_id={props.order_id}
            status={get_status()}
            key_word={props.key_word}
            expiry_date={props.expiry_date}
            db_name={props.db_name}
            users_data={props.users_data}
            />
         <Button className={b.serbut} outline color="success" onClick={reset_phone}>RESET</Button>
         <Button className={b.serbut} color="info" onClick={send_test_msg}>📩</Button>
      </td>
      </tr>
)}
else{
   return(<></>)
}
}


export default Phone
