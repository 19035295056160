const UPDATE_DATA = 'UPDATE_DATA';
const UPDATE_DATA_ = 'UPDATE_DATA_';
const UPDATE_ORDER = 'UPDATE_ORDER';
let initialState = {
    counts:2,
    data:[],
    current_type:'',
    order_name:'date',
    order_by:' ORDER BY date DESC',
    order_direction:'DESC'
};
 
const checksReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DATA_:
            return {
                ...state,
                data:action.data
            }
        case UPDATE_DATA:
            return {
                ...state,
                data:action.data,
                current_type:action.current_type
            }
        case UPDATE_ORDER:
            return {
                ...state,
                order_name:action.order_name,
                order_by:action.order_by,
                order_direction:action.order_direction
            }
        default:
            return state;
    }
}
export const update_data_ = (data) => (
    { 
        type: UPDATE_DATA_, 
        data:data
    }
    )
export const update_data = (data,current_type) => (
    { 
        type: UPDATE_DATA, 
        data:data,
        current_type:current_type
    }
    )
export const update_order = (order_name,order_by,order_direction) => (
    { 
        type: UPDATE_ORDER,order_name,order_by,order_direction
    }
    )
export default checksReducer;