
import React from 'react';
import Statistics from './Statistics';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {logout } from '../../redux/auth-reducer';
import { withAuthRedirect } from '../../hoc/withAuthRedirect';
class StatisticsContainer extends React.Component {

  render() {
    return (<Statistics {...this.props} />);
  }
}
let mapStateToProps = (state) => {

  return {
    users_data:state.users.data,
    orders_data:state.orders.data,
    sms_data:state.sms.data,
    phone_data:state.phone.data,
  }

}
export default compose(connect(mapStateToProps),withAuthRedirect)(StatisticsContainer);
