import UsersButtonsContainer from './UsersButtons/UsersButtonsContainer';
import UserContainer from './User/UserContainer.jsx';
import HeadContainer from './Head/HeadContainer.jsx';
const Users = (props) => {
  let items = props.data.map(
    p => 
    <UserContainer 
    name={p.name}
    username={p.username}
    user_id={p.user_id}
    id={p.id}
    work_theme={p.work_theme}
    date={p.date}
    balance={p.balance}
    unpaid={p.unpaid}
    credits={p.credits}
    online={p.online}
    description={p.description}
    status={p.status}
    db_name={props.db_name}
    show={p.show}
    />
    );
  return (
    <div>
      <UsersButtonsContainer 
      db_name={props.db_name}
      />
      <HeadContainer
      db_name={props.db_name}
      />
      {items}
    </div>
  );
}

export default Users;
