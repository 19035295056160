// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n.Price_rightcol__CMB69 {\r\n  position: relative;\r\n  display: flex;\r\n  width: 40px;\r\n  float: right;\r\n  justify-content: center;\r\n  color: #fff;\r\n  background: #343a40;\r\n  border-radius: 23px;\r\n    }\r\n\r\n    .Price_serbut__7NQB\\+{\r\n      margin-left: 10px;\r\n    }\r\n/* Table\r\n**************************/\r\n.Price_table_order_id__oa28n{\r\n  width: 12.5%;}\r\n.Price_table_name__eTl0d{\r\n  width: 15%;}\r\n.Price_table_price__Xyj6r{width: 15%;}\r\n.Price_table_status__bxvWi{width: 15%;}\r\n.Price_table_date__uxSWN{width: 17.5%;}\r\n.Price_table_actions__7GyKG{width: 25%;}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/Components/Prices/Price/Price.module.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,WAAW;EACX,mBAAmB;EACnB,mBAAmB;IACjB;;IAEA;MACE,iBAAiB;IACnB;AACJ;0BAC0B;AAC1B;EACE,YAAY,CAAC;AACf;EACE,UAAU,CAAC;AACb,0BAAa,UAAU,CAAC;AACxB,2BAAc,UAAU,CAAC;AACzB,yBAAY,YAAY,CAAC;AACzB,4BAAe,UAAU,CAAC","sourcesContent":["\r\n.rightcol {\r\n  position: relative;\r\n  display: flex;\r\n  width: 40px;\r\n  float: right;\r\n  justify-content: center;\r\n  color: #fff;\r\n  background: #343a40;\r\n  border-radius: 23px;\r\n    }\r\n\r\n    .serbut{\r\n      margin-left: 10px;\r\n    }\r\n/* Table\r\n**************************/\r\n.table_order_id{\r\n  width: 12.5%;}\r\n.table_name{\r\n  width: 15%;}\r\n.table_price{width: 15%;}\r\n.table_status{width: 15%;}\r\n.table_date{width: 17.5%;}\r\n.table_actions{width: 25%;}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightcol": "Price_rightcol__CMB69",
	"serbut": "Price_serbut__7NQB+",
	"table_order_id": "Price_table_order_id__oa28n",
	"table_name": "Price_table_name__eTl0d",
	"table_price": "Price_table_price__Xyj6r",
	"table_status": "Price_table_status__bxvWi",
	"table_date": "Price_table_date__uxSWN",
	"table_actions": "Price_table_actions__7GyKG"
};
export default ___CSS_LOADER_EXPORT___;
