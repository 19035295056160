
import {update_data } from '../../../redux/sms-reducer.js';
import { connect } from 'react-redux';
import SMS from './SMS.jsx';

let mapStateToProps=(state)=>{
  return{
    data:state.sms.data,
    current_type:state.sms.current_type,

    users_data:state.users.data
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    }
  }
}
const SMSContainer = connect(mapStateToProps,mapDispatchToProps)(SMS);
export default SMSContainer;
