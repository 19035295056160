
import React from 'react';
import s from './ChecksButtons.module.css';
import { dbAPI } from '../../../api/api_db';
import  {Button} from 'reactstrap';
import { get_new_direction,filter_data } from '../../common/common';
const ChecksButtons = React.memo((props) => {
  let get_status = () => {
    if(props.current_type.includes("done")){
      return "Оплаченные ЧЕКИ"
    }
    if(props.current_type.includes("active")){
      return "Неоплаченные ЧЕКИ"
    }
    if(props.current_type.includes("canceled")){
      return "Просроченные ЧЕКИ"
    }
    return"ВСЕ ЧЕКИ"
  }
  return (
    <div className={s.postsBlock}>
      <h3>Checks: {Object.values(props.data).filter(v => v.show === true).length}/{props.data.length}</h3>
      {get_status()}
      <IncreaseForm 
      update_data_w={props.update_data_w} 
      db_name={props.db_name}
      order_by={props.order_by}
      data={props.data}
      current_type={props.current_type}
       />
    </div>
  );
});

const IncreaseForm = (props) => {
  let update_data_w_test = (where) => {
    where=get_new_direction(where,props.current_type)
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => {
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      props.update_data_w(data,where) 
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_data_w = (where) => {
    let answer=filter_data(where,props.data,props.current_type)
    props.update_data_w(answer[0],answer[1])
  }
  let newSearch= React.createRef();
  let onSearchChange=()=>{
    let data=newSearch.current.value;
    data=data.replace(/^\s+/,'');
    var where=`order_id=${data}`
    update_data_w(where)
  }
  let s_address= React.createRef();
  let onaddressChange=()=>{
    let data=s_address.current.value;
    data=data.replace(/^\s+/,'');
    var where=`address=${data}`
    update_data_w(where)
  } 
  return (
    <div>
      <input type="name" onChange={onSearchChange}
          ref={newSearch}
          placeholder={"Enter Order ID"} list="datalist-name"/>{' '}
      <Button color="primary" onClick={() => update_data_w_test('')}>🔄</Button>{' '}
      <Button color="primary" onClick={() => update_data_w('status=done')}>Оплаченные</Button>{' '}
      <Button color="primary" onClick={() => update_data_w('status=active')}>Неоплаченные</Button>{' '}
      <Button color="primary" onClick={() => update_data_w('status=canceled')}>Просроченные</Button>
      <br/>
      <input type="name" onChange={onaddressChange}
          ref={s_address}
          placeholder={"Enter Address"} list="datalist-name"/>
    </div>
  )
}


export default ChecksButtons;
