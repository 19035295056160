
import {update_data,update_u_sections } from '../../../redux/sms-reducer.js';
import { connect } from 'react-redux';
import SMSButtons from './SMSButtons.jsx';

let mapStateToProps=(state)=>{
  return{
    data:state.sms.data,
    current_type:state.sms.current_type,
    order_by:state.sms.order_by,

    u_sections:state.sms.u_sections,
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    }, 
    
    update_u_sections_w:(data)=>{
      dispatch(update_u_sections(data));
    }
  }
}
const SMSButtonsContainer = connect(mapStateToProps,mapDispatchToProps)(SMSButtons);
export default SMSButtonsContainer;
