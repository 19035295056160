import React , { useState } from 'react';
import b from './Show.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { get_info_user } from '../../../common/common';

const Show = (props) =>{
  let get_type=() =>{
    if(props.type=='phone'){
       return `☎️Аренда номеров`
       }
    if(props.type=='item'){
       return `📦Маркет`
    }
    return "ERROR "
  }
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  return(
    <b > 
    <Button className={b.serbut}   outline  onClick={toggle} >S</Button>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>ID: {props.id}</ModalHeader>
      <ModalBody >
      <b>Section ID:</b> {props.id}<br/>
      <b>Раздел:</b> {props.name}<br/>
      <b>Тип:</b> {get_type()}<br/>
      <b>Описание:</b> <br/>{(props.description && props.description.toString().length>1)?props.description:`Нет описания`}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)
}


export default Show
