
import {update_data, update_u_sections,update_data_ } from '../../../redux/orders-reducer.js';
import { connect } from 'react-redux';
import OrdersButtons from './OrdersButtons.jsx';

let mapStateToProps=(state)=>{
  return{
    data:state.orders.data,
    current_type:state.orders.current_type,
    order_by:state.orders.order_by,

    u_sections:state.orders.u_sections
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    },
    update_u_sections_w:(data)=>{
      dispatch(update_u_sections(data));
    },
    update_data_w_:(data)=>{
      dispatch(update_data_(data));
    }
  }
}
const OrdersButtonsContainer = connect(mapStateToProps,mapDispatchToProps)(OrdersButtons);
export default OrdersButtonsContainer;
