const UPDATE_DATA = 'UPDATE_DATA_PRICE';
const UPDATE_DATA_ = 'UPDATE_DATA_PRICE_';
const UPDATE_ORDER = 'UPDATE_ORDER_PRICE';
 
const UPDATE_LINK = 'UPDATE_LINK_PRICE';
const UPDATE_STATUS = 'UPDATE_STATUS_PRICE';
const UPDATE_OWNER = 'UPDATE_OWNER_PRICE';
const UPDATE_PRICE = 'UPDATE_PRICE_PRICE';
const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION_PRICE';
const UPDATE_received_id = 'UPDATE_received_id_PRICE';
const UPDATE_ADD_LINK = 'UPDATE_ADD_LINK_PRICE';
const UPDATE_ADD_TYPE='UPDATE_ADD_TYPE_PRICE';
const UPDATE_ADD_SUB_TYPE='UPDATE_ADD_SUB_TYPE_PRICE';
const UPDATE_ADD_PRICE='UPDATE_ADD_PRICE_PRICE';
const UPDATE_ADD_Price_Number='UPDATE_ADD_Price_Number_PRICE';
const UPDATE_ADD_SECTION='UPDATE_ADD_SECTION_PRICE';

const UPDATE_U_ST='UPDATE_U_ST_PRICE';
const UPDATE_U_SECTION='UPDATE_U_SECTION_PRICE'; 
const UPDATE_U_SENDERS='UPDATE_U_SENDERS_PRICE';
const UPDATE_active_period = 'UPDATE_active_period_PRICE';
const UPDATE_white_PRICE = 'UPDATE_white_PRICE_PRICE';
const UPDATE_black_PRICE = 'UPDATE_black_PRICE_PRICE';
const UPDATE_exchange_rate_RUB = 'UPDATE_exchange_rate_RUB';
let initialState = {
    counts:2,
    data:[],
    current_type:'', 
    current_status:'',
    order_name:'id',
    order_by:' ORDER BY id DESC',
    order_direction:'DESC',
    data_link:'',
    data_price:'',
    data_status:'',
    data_owner:'',
    data_description:'',
    data_received_id:'',
    data_active_period:'',
    data_white_price:'',
    data_black_price:'',

    add_link:'',
    add_type:'',
    add_sub_type:'',
    add_price:'',
    add_phone_number:'',
    add_section:'',
    unique_sub_types:[],
    u_sections:[],
    unique_senders:[],

    exchange_rate_RUB:0,
};
const priceReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ADD_SECTION:
            return {
                ...state,
                add_section:action.add_section
            }
        case UPDATE_ADD_Price_Number:
            return {
                ...state,
                add_phone_number:action.add_phone_number
            }
        case UPDATE_exchange_rate_RUB:
            return {
                ...state,
                exchange_rate_RUB:action.exchange_rate_RUB
            }
        case UPDATE_black_PRICE:
            return {
                ...state,
                data_black_price:action.data_black_price
            }
        case UPDATE_white_PRICE:
            return {
                ...state,
                data_white_price:action.data_white_price
            }
        case UPDATE_ADD_PRICE:
            return {
                ...state,
                add_price:action.add_price
            }
        case UPDATE_ADD_SUB_TYPE:
            return {
                ...state,
                add_sub_type:action.add_sub_type
            }
        case UPDATE_ADD_TYPE:
            return {
                ...state,
                add_type:action.add_type
            }
        case UPDATE_ADD_LINK:
            return {
                ...state,
                add_link:action.add_link
            }
        case UPDATE_DATA_:
            return {
                ...state,
                data:action.data
            }
        case UPDATE_DATA:
            return {
                ...state,
                data:action.data,
                current_type:action.current_type
            }
        case UPDATE_ORDER:
            return {
                ...state,
                order_name:action.order_name,
                order_by:action.order_by,
                order_direction:action.order_direction
            }
        case UPDATE_LINK:
            return {
                ...state,
                data_link:action.data_link
        }
        case UPDATE_STATUS:
            return {
                ...state,
                data_status:action.data_status
            }
        case UPDATE_OWNER:
            return {
                ...state,
                data_owner:action.data_owner
            }
        case UPDATE_PRICE:
            return {
                ...state,
                data_price:action.data_price
            }
        case UPDATE_received_id:
            return {
                ...state,
                data_received_id:action.data_received_id
            }
        case UPDATE_active_period:
            return {
                ...state,
                data_active_period:action.data_active_period
            }
        case UPDATE_DESCRIPTION:
            return {
                ...state,
                data_description:action.data_description
            }
        case UPDATE_U_ST:
            return {
                ...state,
                unique_sub_types:action.unique_sub_types
            }
        case UPDATE_U_SENDERS:
            return {
                ...state,
                unique_senders:action.unique_senders
            }
        case UPDATE_U_SECTION:
            return {
                ...state,
                u_sections:action.u_sections
            }
        default:
            return state;
    }
}
export const update_exchange_rate_RUB = (data) => (
    { 
        type: UPDATE_exchange_rate_RUB, 
        exchange_rate_RUB:data
    }
    )
export const update_black_price = (data) => (
    { 
        type: UPDATE_black_PRICE, 
        data_black_price:data
    }
    )
export const update_white_price = (data) => (
    { 
        type: UPDATE_white_PRICE, 
        data_white_price:data
    }
    )
export const update_u_sections = (data) => (
    { 
        type: UPDATE_U_SECTION, 
        u_sections:data
    }
    )
export const update_u_st = (data) => (
    { 
        type: UPDATE_U_ST, 
        unique_sub_types:data
    }
    )
export const update_u_senders = (data) => (
    { 
        type: UPDATE_U_SENDERS, 
        unique_senders:data
    }
    )
export const update_add_type = (data) => (
    { 
        type: UPDATE_ADD_TYPE, 
        add_type:data
    }
    )

export const update_add_sub_type = (data) => (
    { 
        type: UPDATE_ADD_SUB_TYPE, 
        add_sub_type:data
    }
    )
export const update_add_phone_number = (data) => (
    { 
        type: UPDATE_ADD_Price_Number, 
        add_phone_number:data
    }
    )
export const update_add_section = (data) => (
    { 
        type: UPDATE_ADD_SECTION, 
        add_section:data
    }
    )
export const update_add_link = (data) => (
    { 
        type: UPDATE_ADD_LINK, 
        add_link:data
    }
    )
export const update_add_price = (data) => (
    { 
        type: UPDATE_ADD_PRICE, 
        add_price:data
    }
    )
export const update_price = (data) => (
    { 
        type: UPDATE_PRICE, 
        data_price:data
    }
    )
export const update_received_id = (data) => (
    { 
        type: UPDATE_received_id, 
        data_received_id:data
    }
    )
export const update_active_period = (data) => (
    { 
        type: UPDATE_active_period, 
        data_active_period:data
    }
    )
export const update_description = (data) => (
    { 
        type: UPDATE_DESCRIPTION, 
        data_description:data
    }
    )
export const update_owner = (data) => (
    { 
        type: UPDATE_OWNER, 
        data_owner:data
    }
    )
export const update_status = (data) => (
    { 
        type: UPDATE_STATUS, 
        data_status:data
    }
    )
export const update_link = (data) => (
    { 
        type: UPDATE_LINK, 
        data_link:data
    }
    )
export const update_data_ = (data) => (
    { 
        type: UPDATE_DATA_, 
        data:data
    }
    )
export const update_data = (data,current_type) => (
    { 
        type: UPDATE_DATA, 
        data:data,
        current_type:current_type
    }
    )
export const update_order = (order_name,order_by,order_direction) => (
    { 
        type: UPDATE_ORDER,order_name,order_by,order_direction
    }
    )
export default priceReducer;