import React , { useState } from 'react';
import b from './post.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import { dbAPI } from '../../../../api/api_db';
import Option from './Option/Option.jsx'
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';
const Add = (props) =>{
  let newLink= React.createRef();
  let newType= React.createRef();
  let newSubType= React.createRef();
  let newPrice= React.createRef();
  let newPhoneNumber= React.createRef();
  let newSection= React.createRef();
  let unique_sub_types= props.unique_sub_types.map( (p => <Option option={p}/>) );
  let unique_sections= props.u_sections.map( (p => <Option option={p}/>) );
  let get_type=(type) =>{
    if(type=='phone'){
       return `☎️Аренда номеров`
       }
    if(type=='item'){
       return `📦Маркет`
    }
    return "Тип не выбран"
  }
  let update_data_w = (where) => {
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => { 
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
        props.update_data_w_(data)
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let insert_data = (new_data) => {
    dbAPI.insert_section_db(props.db_name,new_data)        
    .then(result => { 
      if(result.data.status===200){
      update_data_w('')
      //props.update_add_sub_type_w('')
      props.update_add_phone_number_w('')
    }
    if(result.data.status===400){
      alert(`ERROR TRY AGAIN ${result.data.error}`)
      console.log(result.data)
    }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let onSubTypeChange=()=>{
    let data=newSubType.current.value;
    data=data.replace(/^\s+/,'');
    props.update_add_sub_type_w(data)
  }
  let onPhoneNumberChange=()=>{
    let data=newPhoneNumber.current.value;
    data=data.replace(/^\s+/,'');
    props.update_add_phone_number_w(data)
  }
  let get_unique_item_names = (where) => {
    dbAPI.get_unique_db_data('phone',where,'operator')        
    .then(result => { 
        var array=[]
        for (let i = 0; i < result.data.length; i++) {
          array.push(result.data[i].operator)
        }
        props.update_u_st_w(array)
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
    return 'TEST_DATA'
  }
  const {
    className
  } = props;
  const [modal, setModal] = useState(false);
  const toggle = () =>{
    setModal(!modal);
  }
  let add_new_item=() =>{
    var new_data=`${props.add_sub_type}|${props.add_phone_number}`
    insert_data(new_data)

  }
  return(
    <b>
    <Button className={b.serbut} outline color="primary" onClick={() =>
    {
      get_unique_item_names('');
      toggle()
    }
    }>➕ Добавить раздел</Button>
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>Новый раздел</ModalHeader>
      <ModalBody>
      Тип:
      <UncontrolledDropdown >
        <DropdownToggle className={b.serbut} caret>- {get_type(props.add_sub_type)}</DropdownToggle>
        <DropdownMenu >
        <DropdownItem onClick={() => props.update_add_sub_type_w('phone')}>☎️Аренда номеров</DropdownItem>
        <DropdownItem onClick={() => props.update_add_sub_type_w('item')}>📦Маркет</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>   
      <br/>
      Название:
      <br/>
      <input onChange={onPhoneNumberChange}
              ref={newPhoneNumber}  value={props.add_phone_number} placeholder={"Enter "}/>
      <br/>
      </ModalBody>
      <ModalFooter>
        <Button className={b.serbut} disabled={ !props.add_sub_type || !props.add_phone_number} color="primary" onClick={add_new_item}>Добавить новый раздел</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)





}


export default Add
