import React  from 'react';
import b from './Head.module.css';
import SortButton from './SortButton/SortButton.jsx';
import  {Table } from 'reactstrap';
import {filter_data } from '../../common/common';
 
const Head=(props) =>{
    let s_user_id= React.createRef();
    let s_name= React.createRef();
    let s_balance= React.createRef();
    let s_id= React.createRef();
    let update_data_w = (where) => {
        let answer=filter_data(where,props.data,props.current_type)
        props.update_data_w(answer[0],answer[1])
      }
    let onChange=(value,target)=>{
        let data=value.current.value;
        data=data.replace(/^\s+/,'');
        var where=`${target}=${data}`
        update_data_w(where)
    } 
  return(
    <Table striped bordered hover cellSpacing="1" >
        <thead>
            <tr>
            <th scope="col" className={b.table_order_id}>
                    <SortButton
                        name="user_id"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w= {props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    User ID
                    <input type="name" className={b.input} onChange={() => onChange(s_user_id,'user_id')}
                        ref={s_user_id}
                        placeholder={"User ID"}  />
                </th>
                <th scope="col" className={b.table_name}>
                    <SortButton
                        name="name"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w= {props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Name
                    <input type="name" className={b.input} onChange={() => onChange(s_name,'name')}
                        ref={s_name}
                        placeholder={"Enter Name"}  />
                </th>
                <th scope="col" className={b.table_price}>
                    <SortButton
                        name="balance"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w= {props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Balance
                    <input type="name" className={b.input} onChange={() => onChange(s_balance,'balance')}
                        ref={s_balance}
                        placeholder={"Balance"}  />
                </th>
                <th scope="col" className={b.table_status}>
                    <SortButton
                        name="status"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w= {props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Status
                    <input type="name" className={b.input}
                        placeholder={"disabled"} disabled="disabled"/>
                </th>
                <th scope="col" className={b.table_date}>
                    <SortButton
                        name="id"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w= {props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    ID
                    <input type="name" className={b.input} onChange={() => onChange(s_id,'id')}
                        ref={s_id}
                        placeholder={"ID"}  />
                </th>
                <th scope="col" className={b.table_actions}>Actions</th>
            </tr>
        </thead>
    <tbody id={b.remover}></tbody>
    </Table>)}



export default Head
