import React  from 'react';
import b from './SMS.module.css';
import Delete from './delete/delete.jsx'
import Show from './Show/Show.jsx'
import  {Table,Button} from 'reactstrap';
import { get_info_user } from '../../common/common';
import { dbAPI } from '../../../api/api_db';
import { filter_data } from '../../common/common';
const SMS = (props) =>{
   let get_type=(status) =>{
      if(status=='new'){
         return "🆕Новое"
       }
      if(status=='old'){
      return "📬Выдано"
      }
      if(status=='use'){
         return "📨В процессе отправки"
         }
      if(status=='ban'){
         return "🚫Запрещенный Отправитель"
         }
      if(status=='skip'){
         return "🤷‍♂️Ничейная"
         }
      return status
    }
    let update_data_w = (where) => {
      dbAPI.get_db_data(props.db_name,where)        
      .then(result => { 
        let data=[]
        for (let i = 0; i < result.data.length; i++) {
          let copy_data=result.data[i]
          copy_data.show=true
          data.push(copy_data)
        }
        let answer=filter_data(props.current_type,data,props.current_type)
        props.update_data_w(answer[0],answer[1])  
      })
      .catch(error => { console.error(error); return Promise.reject(error); })  
    }
    let update_db_data = (where,new_data) => {
      dbAPI.update_db_data(props.db_name,where,new_data)        
      .then(result => { 
         if(result.data.status==200){
            update_data_w('')
          }
          else{
            alert(`ERROR: ${result.data.status}`)
          }
      })
      .catch(error => { console.error(error); return Promise.reject(error); })  
    }
    let resend_sms=() =>{
      var where=`id=${props.sms_id}`
      var new_data=`status='new'`
      update_db_data(where,new_data)
    }
    if(props.show===true) { 
  return(
<Table bordered hover size="sm" cellSpacing="1" >
   <thead id={b.remover}>
      <tr>
      </tr>
   </thead>
   <tbody > <tr>
      <td  className={b.table_name}>
         {props.phone_number}
         <b className={b.rightcol} >{props.count}</b>
      </td>
      <td  className={b.table_price}>
         {props.sender}
      </td>
      <td  className={b.table_status}>
         {get_type(props.status)}
      </td>
      <td  className={b.table_price}>
      <b>{(props.owner && props.owner.toString().length>1)?props.owner:``}</b>
         {(props.owner && props.owner.toString().length>1)?get_info_user(props.owner,props.users_data):`Нет владельца`}
      </td>
      <td  className={b.table_date}>
         {props.date}
      </td>
      <td  className={b.table_actions}>
         <Delete
            sms_id={props.sms_id}
            update_data_w={props.update_data_w}
            current_type={props.current_type}
            db_name={props.db_name}
            />
         <Show
            sms_id={props.sms_id}
            users_data={props.users_data}
            phone_number={props.phone_number}
            sender={props.sender}
            msg_text={props.msg_text}
            date={props.date}
            status={get_type(props.status)}
            />
         {props.status!='new'?<Button className={b.serbut} color="info" onClick={resend_sms}>📬</Button>:''}
      </td>
      </tr>
   </tbody>
</Table>
)}
else{
   return(<></>)
}
}


export default SMS
