
import React from 'react';
import s from './SMSButtons.module.css';
import { dbAPI } from '../../../api/api_db';
import { get_new_direction,filter_data } from '../../common/common';
import DDItem from './DDItem.jsx'
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledDropdown,
  Container, Row, Col
} from 'reactstrap';
const SMSButtons = React.memo((props) => {
  let get_status = () => {
    if(props.current_type.includes("new")){
      return "Новые SMS"
    }
    if(props.current_type.includes("old")){
      return "Выданные SMS"
    }
    return"ВСЕ SMS"
  }
  return (
    <div className={s.postsBlock}>
      <h3>SMS: {Object.values(props.data).filter(v => v.show === true).length}/{props.data.length}</h3>
      {get_status()}
      <IncreaseForm 
      update_data_w={props.update_data_w} 
      db_name={props.db_name}
      order_by={props.order_by}
      data={props.data}
      current_type={props.current_type}
      u_sections={props.u_sections}
      update_u_sections_w={props.update_u_sections_w} 
       />
    </div>
  );
});

const IncreaseForm = (props) => {
  let update_data_w_test = (where) => {
    where=get_new_direction(where,props.current_type)
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => {
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      props.update_data_w(data,where) 
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_data_w_test2 = (where) => {
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => {
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      props.update_data_w(data,where) 
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_data_w = (where) => {
    let answer=filter_data(where,props.data,props.current_type)
    props.update_data_w(answer[0],answer[1])
  }
  let get_unique_sections = (where) => {
    dbAPI.get_unique_db_data('sms',where,'sender')        
    .then(result => { 
        var array=[]
        for (let i = 0; i < result.data.length; i++) {
          if(!result.data[i].sender.includes('Megafon') && !result.data[i].sender.includes('79') && isNaN(result.data[i].sender)){
          array.push(result.data[i].sender)
          }
        }
        props.update_u_sections_w(array)
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
    return 'TEST_DATA'
  }
  let delete_all_sms=() =>{
    dbAPI.delete_all_sms()        
    .then(result => { 
      if(result.data.status==200){
        update_data_w_test('')
      }
      else{
        alert(`ERROR: ${result.data.status}`)
      }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
  }
  let get_theme = () => {
    for (let i = 0; i < props.u_sections.length; i++) {
      if(props.current_type.includes(props.u_sections[i].replaceAll('@', ' '))){
        return props.u_sections[i].replaceAll('@', ' ')
      }
    }
    return""
  }
  let unique_sections= props.u_sections.map( (p => 
    <DDItem 
    option={p}
    update_data_w={props.update_data_w} 
    db_name={props.db_name}
    order_by={props.order_by}
    update_u_sections_w={props.update_u_sections_w}
    current_type={props.current_type}
    get_new_direction={get_new_direction}
    data={props.data} 
    />) );
  return (
    <div> 
      <Container>
<Row>
<Col xs="auto">
      <Button color="primary" onClick={() => update_data_w_test('')}>🔄Все SMS</Button>{' '}
      <Button color="primary" onClick={() => update_data_w_test2('order by id desc limit 100')}>✉️100</Button>{' '}
      <Button color="primary" onClick={() => update_data_w_test2('order by id desc limit 300')}>✉️300</Button>{' '}
      <Button color="primary" onClick={() => update_data_w_test2('order by id desc limit 500')}>✉️500</Button>{' '}
      <Button color="primary" onClick={() => update_data_w_test2('order by id desc limit 1000')}>✉️1000</Button>{' '}      
      <Button color="primary" onClick={() => update_data_w('status=new')}>🆕Новые</Button>{' '}
      <Button color="primary" onClick={() => update_data_w('status=old')}>Выданные</Button>{' '}
      </Col>
<Col xs="auto">
      <UncontrolledDropdown >
        <DropdownToggle className={s.serbut} onClick={() => get_unique_sections('')} caret>Отправители {get_theme()}</DropdownToggle>
        <DropdownMenu >
        {unique_sections}
        </DropdownMenu>
      </UncontrolledDropdown>
</Col>
<Col xs="auto">
  <Button color="danger" className={s.serbut} onClick={() => {
    delete_all_sms()
    }
    }>🧹🗑️Удалить всё</Button>
</Col>
</Row>
</Container>
    </div>
  )
}


export default SMSButtons;
