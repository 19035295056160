// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.SMSButtons_postsBlock__LvHtt{\n    padding: 10px;\n  }\n.SMSButtons_error__N17jI {\n  color:red;\n}", "",{"version":3,"sources":["webpack://./src/Components/SMS/SMSButtons/SMSButtons.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;EACf;AACF;EACE,SAAS;AACX","sourcesContent":["\n.postsBlock{\n    padding: 10px;\n  }\n.error {\n  color:red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postsBlock": "SMSButtons_postsBlock__LvHtt",
	"error": "SMSButtons_error__N17jI"
};
export default ___CSS_LOADER_EXPORT___;
