import { NavLink } from 'react-router-dom';
import s from './Statistics.module.css';
import  {Button} from 'reactstrap';
const Statistics = (props) =>{
  let get_money_customer=(data) =>{
    let money=0
    for (let i = 0; i < data.length; i++) {
       if(data[i].status=='customer'){
        money+=data[i].balance
       }
    }
    return parseInt(money)
 }
 let get_credits_customer=(data) =>{
  let money=0
  for (let i = 0; i < data.length; i++) {
     if(data[i].status=='customer'){
      money+=data[i].credits
     }
  }
  return parseInt(money)
}
let get_orders_num=(data,order_status) =>{
  let orders=0
  for (let i = 0; i < data.length; i++) {
     if(data[i].status==order_status){
      orders+=1
     }
  }
  return orders
}
let get_items_num=(data) =>{
  let orders=0
  for (let i = 0; i < data.length; i++) {
     if(data[i].status=='done'&&data[i].type=='item'){
      orders+=1
     }
  }
  return orders
}
let get_sms_num=(data) =>{
  let orders=0
  console.log(data)
  for (let i = 0; i < data.length; i++) {
     if(data[i].status=='old'){
      orders+=1
     }
  }
  return orders
}
let get_phone_num=(data,check_status) =>{
  let orders=0
  console.log(data)
  for (let i = 0; i < data.length; i++) {
     if(data[i].status==check_status){
      orders+=1
     }
  }
  return orders
}
  return (
        <>
          Доход: {get_money_customer(props.users_data)}$<br/>
          Балансы: {get_credits_customer(props.users_data)}$<br/>
          Сдано в аренду: {get_items_num(props.orders_data)}<br/>
          Принято 📧SMS: {get_sms_num(props.sms_data)}<br/>
          Свободные номера: {get_phone_num(props.phone_data,0)}<br/>
          Арендованные номера: {get_phone_num(props.phone_data,1)}<br/>
        </>
  );
}

export default Statistics;
