
import {
  update_data,
  update_link,
  update_status,
  update_owner,
  update_price,
  update_description,
  update_received_id,
  update_u_senders,
  update_active_period,
  update_white_price,
  update_black_price,
  update_exchange_rate_RUB
} from '../../../redux/price-reducer.js';
import { connect } from 'react-redux';
import Order from './Price.jsx';

let mapStateToProps=(state)=>{
  return{
    data:state.price.data,
    current_type:state.price.current_type,

    exchange_rate_RUB:state.price.exchange_rate_RUB,

    data_received_id:state.price.data_received_id,
    data_link:state.price.data_link,
    data_status:state.price.data_status,
    data_owner:state.price.data_owner,
    data_price:state.price.data_price,
    data_description:state.price.data_description,
    data_active_period:state.price.data_active_period,
    data_white_price:state.price.data_white_price,
    data_black_price:state.price.data_black_price,
    users_data:state.users.data,
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    },
    update_link_w:(data)=>{
      dispatch(update_link(data));
    },
    update_status_w:(data)=>{
      dispatch(update_status(data));
    },
    update_owner_w:(data)=>{
      dispatch(update_owner(data));
    },
    update_price_w:(data)=>{
      dispatch(update_price(data));
    },
    update_received_id_w:(data)=>{
      dispatch(update_received_id(data));
    },
    update_description_w:(data)=>{
      dispatch(update_description(data));
    },
    update_u_senders_w:(data)=>{
      dispatch(update_u_senders(data));
    },
    update_active_period_w:(data)=>{
      dispatch(update_active_period(data));
    },
    update_white_price_w:(data)=>{
      dispatch(update_white_price(data));
    },
    update_black_price_w:(data)=>{
      dispatch(update_black_price(data));
    },
    update_update_exchange_rate_RUB_w:(data)=>{
      dispatch(update_exchange_rate_RUB(data));
    }
  }
}
const ItemContainer = connect(mapStateToProps,mapDispatchToProps)(Order);
export default ItemContainer;
