// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n/* Table\r\n**************************/\r\n\r\n.Head_table_order_id__iAVmY{\r\n  width: 12.5%;}\r\n.Head_table_name__mOERw{\r\n  width: 15%;}\r\n.Head_table_price__FRfHU{width: 15%;}\r\n.Head_table_status__4baw5{width: 15%;}\r\n.Head_table_date__BUciB{width: 17.5%;}\r\n.Head_table_actions__V2O87{width: 25%;}\r\n.Head_input__hGzTA{\r\n  width: 80%;\r\n  margin-top: 10px;\r\n}", "",{"version":3,"sources":["webpack://./src/Components/Sections/Head/Head.module.css"],"names":[],"mappings":";AACA;0BAC0B;;AAE1B;EACE,YAAY,CAAC;AACf;EACE,UAAU,CAAC;AACb,yBAAa,UAAU,CAAC;AACxB,0BAAc,UAAU,CAAC;AACzB,wBAAY,YAAY,CAAC;AACzB,2BAAe,UAAU,CAAC;AAC1B;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["\r\n/* Table\r\n**************************/\r\n\r\n.table_order_id{\r\n  width: 12.5%;}\r\n.table_name{\r\n  width: 15%;}\r\n.table_price{width: 15%;}\r\n.table_status{width: 15%;}\r\n.table_date{width: 17.5%;}\r\n.table_actions{width: 25%;}\r\n.input{\r\n  width: 80%;\r\n  margin-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_order_id": "Head_table_order_id__iAVmY",
	"table_name": "Head_table_name__mOERw",
	"table_price": "Head_table_price__FRfHU",
	"table_status": "Head_table_status__4baw5",
	"table_date": "Head_table_date__BUciB",
	"table_actions": "Head_table_actions__V2O87",
	"input": "Head_input__hGzTA"
};
export default ___CSS_LOADER_EXPORT___;
