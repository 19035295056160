// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".post_serbut__keOYZ{\r\n  margin-left: 10px;\r\n}\r\n.post_upspace__YTn6y{\r\n  margin-top: 10px;\r\n}", "",{"version":3,"sources":["webpack://./src/Components/Sections/SectionsButtons/Add/post.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".serbut{\r\n  margin-left: 10px;\r\n}\r\n.upspace{\r\n  margin-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serbut": "post_serbut__keOYZ",
	"upspace": "post_upspace__YTn6y"
};
export default ___CSS_LOADER_EXPORT___;
