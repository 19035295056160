// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n.Section_rightcol__7t9fD {\r\n  position: relative;\r\n  display: flex;\r\n  width: 40px;\r\n  float: right;\r\n  justify-content: center;\r\n  color: #fff;\r\n  background: #343a40;\r\n  border-radius: 23px;\r\n    }\r\n\r\n    .Section_serbut__\\+9nZn{\r\n      margin-left: 10px;\r\n    }\r\n/* Table\r\n**************************/\r\n.Section_table_order_id__-yedo{\r\n  width: 12.5%;}\r\n.Section_table_name__0vV-J{\r\n  width: 15%;}\r\n.Section_table_price__GqIcB{width: 15%;}\r\n.Section_table_status__H7Ei4{width: 15%;}\r\n.Section_table_date__wK31G{width: 17.5%;}\r\n.Section_table_actions__GLHWR{width: 25%;}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/Components/Sections/Section/Section.module.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,WAAW;EACX,mBAAmB;EACnB,mBAAmB;IACjB;;IAEA;MACE,iBAAiB;IACnB;AACJ;0BAC0B;AAC1B;EACE,YAAY,CAAC;AACf;EACE,UAAU,CAAC;AACb,4BAAa,UAAU,CAAC;AACxB,6BAAc,UAAU,CAAC;AACzB,2BAAY,YAAY,CAAC;AACzB,8BAAe,UAAU,CAAC","sourcesContent":["\r\n.rightcol {\r\n  position: relative;\r\n  display: flex;\r\n  width: 40px;\r\n  float: right;\r\n  justify-content: center;\r\n  color: #fff;\r\n  background: #343a40;\r\n  border-radius: 23px;\r\n    }\r\n\r\n    .serbut{\r\n      margin-left: 10px;\r\n    }\r\n/* Table\r\n**************************/\r\n.table_order_id{\r\n  width: 12.5%;}\r\n.table_name{\r\n  width: 15%;}\r\n.table_price{width: 15%;}\r\n.table_status{width: 15%;}\r\n.table_date{width: 17.5%;}\r\n.table_actions{width: 25%;}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightcol": "Section_rightcol__7t9fD",
	"serbut": "Section_serbut__+9nZn",
	"table_order_id": "Section_table_order_id__-yedo",
	"table_name": "Section_table_name__0vV-J",
	"table_price": "Section_table_price__GqIcB",
	"table_status": "Section_table_status__H7Ei4",
	"table_date": "Section_table_date__wK31G",
	"table_actions": "Section_table_actions__GLHWR"
};
export default ___CSS_LOADER_EXPORT___;
