import React  from 'react';
import b from './profile.module.css';
import  {Button } from 'reactstrap';
 
const SortButton=(props) =>{
  let update_data_w = (where) => {
    let key=where.split('=')
    let data= props.data
    if(key[1]=='DESC'){
      if(typeof data[0][key[0]]==='number'){
        data.sort((a, b) => a[key[0]] - b[key[0]]);
      }
      if(typeof data[0][key[0]]==='string'){
        data.sort(function(a, b){
          let x = String(a[key[0]]).toLowerCase();
          let y = String(b[key[0]]).toLowerCase();
          if (x < y) {return -1;}
          if (x > y) {return 1;}
          return 0;
        });
    }
    }
    if(key[1]=='ASC'){
      if(typeof data[0][key[0]]==='number'){
        data.sort((a, b) => b[key[0]] - a[key[0]]);
      }
      if(typeof data[0][key[0]]==='string'){
        data.sort(function(a, b){
          let x = String(a[key[0]]).toLowerCase();
          let y = String(b[key[0]]).toLowerCase();
          if (x > y) {return -1;}
          if (x < y) {return 1;}
          return 0;
        });
      }
    }
    let data2=[]
    for(let x in data){
      data2.push(data[x])
    }
    props.update_data_w(data2)
    props.update_order_w(key[0],where,key[1]) 
  }
  let update_order_w = (order_direction) => {
    var order_by=`${props.name}=${order_direction}`
    update_data_w(order_by)
  }
  if(props.name==props.order_name){
    if(props.order_direction=='DESC'){
    return(
      <b>
      <Button className={b.serbut} onClick={() =>  update_order_w('ASC')} type="button" id="sort-name" outline color="secondary">&#9660;</Button>
      </b>)}
    if(props.order_direction=='ASC'){
      return(
        <b>
        <Button className={b.serbut} onClick={() => update_order_w('DESC')} type="button" id="sort-name" outline color="secondary">&#9650;</Button>
        </b>)}
  }
  else{
    return(
      <b>
      <Button className={b.serbut} onClick={() => update_order_w('ASC')} type="button" id="sort-name" outline color="secondary">&#9660;</Button>
      </b>)
  }
}
export default SortButton
