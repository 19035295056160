import React  from 'react';
import b from './Section.module.css';
import Edit from './edit/edit.jsx'
import Delete from './delete/delete.jsx'
import Show from './Show/Show.jsx'
import  {Table,Button} from 'reactstrap';
import { get_info_user } from '../../common/common';
import { dbAPI } from '../../../api/api_db';
import { filter_data } from '../../common/common';
const Section = (props) =>{
   let get_type=() =>{
      if(props.type=='phone'){
         return `☎️Аренда номеров`
         }
      if(props.type=='item'){
         return `📦Маркет`
      }
      return "ERROR "
    }
    let get_status=() =>{
      if(props.status==2){
         return `Ожидает продления\n`
         }
      if(props.status==1){
         return `В аренде`+`\n${props.order_id}\n`
      }
      if(props.status==0){
      return `Не использован\n`
      }
      return "ERROR"
    }
if(props.show===true) { 
   let get_sernders=(phone_number,data) =>{
      let u_senders=[...new Set()]
      for (let i = 0; i < data.length; i++) {
         if((data[i].phone_number==phone_number)&&(!u_senders.includes(data[i].sender))){
            let new_el=data[i].sender
            u_senders.push(new_el)
         }
      }
      let u_senders2=[...new Set()]
      for (let i = 0; i < u_senders.length; i++) {
            let new_el=u_senders[i]+' '
            if(u_senders2.length%4==0){
               new_el+='\n'
            }
            u_senders2.push(new_el)
      }
      let return_data='Нет SMS'
      if(u_senders.length>0){
         return_data=u_senders2
      }
      return return_data
   }
   let update_data_w = (where) => {
      dbAPI.get_db_data(props.db_name,where)        
      .then(result => { 
        let data=[]
        for (let i = 0; i < result.data.length; i++) {
          let copy_data=result.data[i]
          copy_data.show=true
          data.push(copy_data)
        }
        let answer=filter_data(props.current_type,data,props.current_type)
        props.update_data_w(answer[0],answer[1])  
      })
      .catch(error => { console.error(error); return Promise.reject(error); })  
    }
   let update_db_data = (where,new_data) => {
      dbAPI.update_db_data(props.db_name,where,new_data)        
      .then(result => { 
         if(result.data.status==200){
            update_data_w('')
          }
          else{
            alert(`ERROR: ${result.data.status}`)
          }
      })
      .catch(error => { console.error(error); return Promise.reject(error); })  
    }
   let reset_phone=() =>{
      var where=`id='${props.id}'`
      var new_data=`status=0,active_period=0,owner=0,expiry_date='',order_id='',received_id='',alert_3_days='${false}',alert_1_hour='${false}'`
      update_db_data(where,new_data)
    }
  return(
<Table bordered hover size="sm" cellSpacing="1" >
   <thead id={b.remover}>
      <tr>
      </tr>
   </thead>
   <tbody > <tr>
      <td  className={b.table_order_id}>
         {props.id}<br/>
         {props.name}
      </td>
      <td  className={b.table_price}>
         <b>{get_type()}<br/>
            {(props.description && props.description.toString().length>1)?'Есть описание':`Нет описания`}</b>
      </td>
      <td  className={b.table_actions}>
         <Edit
            id={props.id}
            type={props.type}
            db_name={props.db_name}

            update_data_w={props.update_data_w}
            current_type={props.current_type}
            data_status={props.data_status}
            loading_status={props.loading_status}
            data_description={props.data_description}
            update_status_w={props.update_status_w}
            update_description_w={props.update_description_w}

            update_loading_status_w={props.update_loading_status_w}
            />
         <Delete
            id={props.id}
            name={props.name}
            db_name={props.db_name}
            update_data_w={props.update_data_w}
            current_type={props.current_type}
            />
         <Show
            id={props.id}
            name={props.name}
            type={props.type}
            description={props.description}
            db_name={props.db_name}
            />
      </td>
      </tr>
   </tbody>
</Table>
)}
else{
   return(<></>)
}
}


export default Section
