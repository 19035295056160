// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n/* Table\r\n**************************/\r\n\r\n.Head_table_order_id__G0VdA{\r\n  width: 12.5%;}\r\n.Head_table_name__bm\\+dC{\r\n  width: 15%;}\r\n.Head_table_price__wlofA{width: 15%;}\r\n.Head_table_status__-18aZ{width: 15%;}\r\n.Head_table_date__JztyQ{width: 17.5%;}\r\n.Head_table_actions__jsnxJ{width: 25%;}\r\n.Head_input__p\\+S7K{\r\n  width: 80%;\r\n  margin-top: 10px;\r\n}", "",{"version":3,"sources":["webpack://./src/Components/Phones/Head/Head.module.css"],"names":[],"mappings":";AACA;0BAC0B;;AAE1B;EACE,YAAY,CAAC;AACf;EACE,UAAU,CAAC;AACb,yBAAa,UAAU,CAAC;AACxB,0BAAc,UAAU,CAAC;AACzB,wBAAY,YAAY,CAAC;AACzB,2BAAe,UAAU,CAAC;AAC1B;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["\r\n/* Table\r\n**************************/\r\n\r\n.table_order_id{\r\n  width: 12.5%;}\r\n.table_name{\r\n  width: 15%;}\r\n.table_price{width: 15%;}\r\n.table_status{width: 15%;}\r\n.table_date{width: 17.5%;}\r\n.table_actions{width: 25%;}\r\n.input{\r\n  width: 80%;\r\n  margin-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_order_id": "Head_table_order_id__G0VdA",
	"table_name": "Head_table_name__bm+dC",
	"table_price": "Head_table_price__wlofA",
	"table_status": "Head_table_status__-18aZ",
	"table_date": "Head_table_date__JztyQ",
	"table_actions": "Head_table_actions__jsnxJ",
	"input": "Head_input__p+S7K"
};
export default ___CSS_LOADER_EXPORT___;
