
import {BarLoader,BeatLoader,BounceLoader,CircleLoader,FadeLoader ,ClipLoader, ClockLoader,DotLoader,
  GridLoader ,HashLoader ,MoonLoader ,PacmanLoader ,PropagateLoader ,PuffLoader ,PulseLoader,
  RingLoader,RiseLoader,RotateLoader,ScaleLoader,SkewLoader,SquareLoader,SyncLoader} from "react-spinners";

let Spinner = (props) => {
  let get_random_spinner=()=>{
    var color="#052457";
    var data=<ClipLoader color={color}/>;
    var chooser=Math.floor(Math.random() * 21);
    if(chooser==0){
      data=<BarLoader color={color}/>;
    }
    if(chooser==1){
      data=<BeatLoader color={color}/>;
    }
    if(chooser==2){
      data=<BounceLoader  color={color}/>;
    }
    if(chooser==3){
      data=<CircleLoader  color={color}/>;
    }
    if(chooser==4){
      data=<FadeLoader   color={color}/>;
    }
    if(chooser==5){
      data=<ClipLoader  color={color}/>;
    }
    if(chooser==6){
      data=<ClockLoader  color={color}/>;
    }
    if(chooser==7){
      data=<DotLoader color={color}/>;
    }
    if(chooser==8){
      data=<GridLoader  color={color}/>;
    }
    if(chooser==9){
      data=<HashLoader color={color}/>;
    }
    if(chooser==10){
      data=<MoonLoader color={color}/>;
    }
    if(chooser==11){
      data=<PacmanLoader color={color}/>;
    }
    if(chooser==12){
      data=<PropagateLoader color={color}/>;
    }
    if(chooser==13){
      data=<RingLoader color={color}/>;
    }
    if(chooser==14){
      data=<PuffLoader color={color}/>;
    }
    if(chooser==15){
      data=<PulseLoader color={color}/>;
    }
    if(chooser==16){
      data=<RotateLoader color={color}/>;
    }
    if(chooser==17){
      data=<RiseLoader color={color}/>;
    }
    if(chooser==18){
      data=<ScaleLoader color={color}/>;
    }
    if(chooser==19){
      data=<SkewLoader color={color}/>;
    }
    if(chooser==20){
      data=<SyncLoader color={color}/>;
    }
    if(chooser==21){
      data=<SquareLoader color={color}/>;
    }
    return data;        
  }
  return (<>
     {get_random_spinner()}
    </>
  );

}

export default Spinner;
