import React , { useState } from 'react';
import b from './Show.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { get_info_user } from '../../../common/common';
const Show = (props) =>{
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return(
    <b><Button className={b.serbut}   outline  onClick={toggle} >Show</Button>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>SMS ID {props.sms_id}:</ModalHeader>
      <ModalBody>
      Status: {props.status}<br/>
      Date: {props.date}<br/>
      Номер телефона: {props.phone_number}<br/>
      Отправитель: {props.sender}<br/>  
      Текст сообщения: <br/>{props.msg_text}<br/>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)
}


export default Show
