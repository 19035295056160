import { dbAPI } from "../api/api_db";
const SET_USER_DATA = 'SET_USER_DATA';
let initialState = {
  id: null,
  email: null,
  login: null,
  isAuth: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state;
  }
}
 

export const setAuthUserData = (id, email, login, isAuth) => (
  { type: SET_USER_DATA, 
    data: { id, email, login, isAuth } 
  });

export const getAuthUserData = (email, password) => async (dispatch) => {
    var id=1
    dispatch(setAuthUserData(id, email, password, true));
}

export const loginAccount = (email, password, rememberMe, setStatus) => {
  return async (dispatch) => {
    dbAPI.check_access(email,password).then(response => {
      if (response.data.answer === true) {
        dispatch(getAuthUserData(email, password));
      } else {
        setStatus('Доступ закрыт')
      }
    })
  }
}

export const logout = () => {
  return async (dispatch) => {
    dispatch(setAuthUserData(null, null, null, false));
  }
}
export default authReducer;