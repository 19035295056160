// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.PhonesButtons_postsBlock__caCTj{\n    padding: 10px;\n  }\n.PhonesButtons_error__mr5JS {\n  color:red;\n}\n.PhonesButtons_serbut__lOVR3{\n  margin-left: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/PhonesBASKET/PhonesButtons/PhonesButtons.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;EACf;AACF;EACE,SAAS;AACX;AACA;EACE,iBAAiB;AACnB","sourcesContent":["\n.postsBlock{\n    padding: 10px;\n  }\n.error {\n  color:red;\n}\n.serbut{\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postsBlock": "PhonesButtons_postsBlock__caCTj",
	"error": "PhonesButtons_error__mr5JS",
	"serbut": "PhonesButtons_serbut__lOVR3"
};
export default ___CSS_LOADER_EXPORT___;
