import React , { useState } from 'react';
import b from './post.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { dbAPI } from '../../../../api/api_db';
import { filter_data } from '../../../common/common';
const Delete = (props) =>{
  let update_data_w = (where) => {
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => { 
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      let answer=filter_data(props.current_type,data,props.current_type)
      props.update_data_w(answer[0],answer[1])   
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let del=() =>{
    dbAPI.db_delete_data(props.db_name,`order_id='${props.order_id}'`)        
    .then(result => { 
      if(result.data.status==200){
        update_data_w('')
      }
      else{
        alert(`ERROR: ${result.data.status}`)
      }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
  }
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return(
    <b><Button className={b.serbut}   outline color="danger" onClick={toggle} >Delete</Button>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Удаление заказа из базы данных</ModalHeader>
      <ModalBody>
      Заказ будет удален из базы насовсем. Его не получится восстановить<br></br>
      ID заказа: {props.order_id}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={del}>Удалить</Button>{' '}
        <Button color="secondary" onClick={toggle}>Выйти</Button>
      </ModalFooter>
    </Modal>
</b>
)
}


export default Delete
