
import React from 'react';
import s from './UsersButtons.module.css';
import { dbAPI } from '../../../api/api_db';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledDropdown,
  Container, Row, Col
} from 'reactstrap';
import { get_new_direction,filter_data } from '../../common/common';
const UsersButtons = React.memo((props) => {
  let get_status = () => {
    if(props.current_type.includes("admin")){
      return "Админы"
    }
    if(props.current_type.includes("customer")){
      return "Клиенты"
    }
    if(props.current_type.includes("freelancer")){
      return "Исполнители"
    }
    return"Все пользователи"
  }
  
  return (
    <div className={s.postsBlock}>
      <h3>Users: {Object.values(props.data).filter(v => v.show === true).length}/{props.data.length}</h3>
      {get_status()}
      <IncreaseForm 
      update_data_w={props.update_data_w} 
      db_name={props.db_name}
      order_by={props.order_by}
      current_type={props.current_type}
      data={props.data}
       />
    </div>
  );
});

const IncreaseForm = (props) => {
  let update_data_w_test = (where) => {
    where=get_new_direction(where,props.current_type)
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => {
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      props.update_data_w(data,where) 
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_data_w = (where) => {
    let answer=filter_data(where,props.data,props.current_type)
    props.update_data_w(answer[0],answer[1])
  }
  let s_UserName= React.createRef();
  let onUserNameChange=()=>{
    let data=s_UserName.current.value;
    data=data.replace(/^\s+/,'');
    var where=`username=${data}`
    update_data_w(where)
  }
  let get_status= () => {
    if(props.current_type.includes('status=freelancer')){
      return "Исполнители"
    }
    if(props.current_type.includes('status=customer')){
      return "Клиенты"
    }
    if(props.current_type.includes('status=admin')){
      return "Админы"
    }
    return""
  }
  let get_theme= () => {
    if(props.current_type.includes('work_theme=📝Отрисовка')){
      return "📝Отрисовка"
    }
    if(props.current_type.includes('work_theme=📞Пробив')){
      return "📞Пробив"
    }
    if(props.current_type.includes('work_theme=📟Продажа@сканов')){
      return "📟Продажа сканов"
    }
    if(props.current_type.includes('work_theme=📧SMS')){
      return "📧SMS"
    }
    return""
  }
  return (
    <div className={s.parent}>
<Container>
<Row>
<Col xs="auto">
  <Button color="primary" className={s.serbut} onClick={() => update_data_w_test('')}>Сброс</Button>
  <br/>
  <input type="name" className={s.serbut} onChange={onUserNameChange}
        ref={s_UserName}
        placeholder={"Enter Username"} list="datalist-name"/>
</Col>
<Col xs="auto">
  <UncontrolledDropdown >
  <DropdownToggle className={s.serbut} caret>Статус {get_status()}</DropdownToggle>
  <DropdownMenu >
  <DropdownItem onClick={() => update_data_w('status=freelancer')}>Исполнители</DropdownItem>
  <DropdownItem onClick={() => update_data_w('status=customer')}>Клиенты</DropdownItem>
  <DropdownItem onClick={() => update_data_w('status=admin')}>Админы</DropdownItem>
  </DropdownMenu>
  </UncontrolledDropdown>
  <UncontrolledDropdown >
  <DropdownToggle className={s.serbut} caret>Раздел работы {get_theme()}</DropdownToggle>
  <DropdownMenu >
  <DropdownItem onClick={() => update_data_w('work_theme=📝Отрисовка')}>📝Отрисовка</DropdownItem>
  <DropdownItem onClick={() => update_data_w('work_theme=📞Пробив')}>📞Пробив</DropdownItem>
  <DropdownItem onClick={() => update_data_w('work_theme=📟Продажа@сканов')}>📟Продажа сканов</DropdownItem>
  <DropdownItem onClick={() => update_data_w('work_theme=📧SMS')}>📧SMS</DropdownItem>
  </DropdownMenu>
  </UncontrolledDropdown>
</Col>
</Row>
</Container>
    </div>
  )
}


export default UsersButtons;
