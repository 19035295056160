import React , { useState } from 'react';
import b from './post.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import { dbAPI } from '../../../../api/api_db';
import { filter_data } from '../../../common/common';
import { 
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Label,
  Col,
  Input
} from 'reactstrap';
const Edit = (props) =>{
  let newLink= React.createRef();
  let newOwner= React.createRef();
  let newPrice= React.createRef();
  let newReceived_id= React.createRef();
  let newActive_period= React.createRef();
  let newWhitePrice= React.createRef();
  let newBlackPrice= React.createRef();
  let update_data_w = (where) => {
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => { 
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      let answer=filter_data(props.current_type,data,props.current_type)
      props.update_data_w(answer[0],answer[1])  
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_db_data = (where,new_data) => {
    dbAPI.update_db_data(props.db_name,where,new_data)        
    .then(result => { 
      if(result.data.status==200){
        update_data_w('')
      }
      else{
        alert(`ERROR: ${result.data.status}`)
      }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let onLinkChange=()=>{
    let data=newLink.current.value;
    props.update_link_w(data)
  }
  let onOwnerChange=()=>{
    let data=newOwner.current.value;
    data=data.replace(/^\s+/,'');
    props.update_owner_w(data)
  }
  let onPriceChange=()=>{
    let data=newPrice.current.value;
    data=data.replace(/^\s+/,'');
    props.update_price_w(data)
  }
  let onWhitePriceChange=()=>{
    let data=newWhitePrice.current.value;
    data=data.replace(/^\s+/,'');
    props.update_white_price_w(data)
  }
  let onBlackPriceChange=()=>{
    let data=newBlackPrice.current.value;
    data=data.replace(/^\s+/,'');
    props.update_black_price_w(data)
  }
  let onReceived_idChange=()=>{
    let data=newReceived_id.current.value;
    data=data.replace(/^\s+/,'');
    props.update_received_id_w(data)
  }
  let onActive_periodChange=()=>{
    let data=newActive_period.current.value;
    data=data.replace(/^\s+/,'');
    props.update_active_period_w(data)
  }
  let update_status=(new_status) =>{
    var where=`operator='${props.operator}'`
    var new_data=`status=${new_status}`
    update_db_data(where,new_data)
  }
  const {
    className
  } = props;
  const [modal, setModal] = useState(false);
  const toggle = () =>{
    setModal(!modal);
  }
  let edit_link=() =>{
    var where=`operator='${props.operator}'`
    var new_data=`day=${props.data_link}`
    update_db_data(where,new_data)
    props.update_link_w('')
  }
  let edit_owner=() =>{
    var where=`operator='${props.operator}'`
    var new_data=`day7=${props.data_owner}`
    update_db_data(where,new_data)
    props.update_owner_w('')
  }
  let edit_price=() =>{
    var where=`operator='${props.operator}'`
    var new_data=`expiry_date='${props.data_price.replaceAll('+', '@')}',alert_3_days='${false}',alert_1_hour='${false}'`
    update_db_data(where,new_data)
    props.update_price_w('')
  }
  let edit_received_id=() =>{
    var where=`operator='${props.operator}'`
    var new_data=`day15=${props.data_received_id}`
    update_db_data(where,new_data)
    props.update_received_id_w('')
  }
  let edit_active_period=() =>{
    var where=`operator='${props.operator}'`
    var new_data=`day30=${props.data_active_period}`
    update_db_data(where,new_data)
    props.update_active_period_w('')
  }
  let update_min=(new_min) =>{
    var where=`operator='${props.operator}'`
    var new_data=`min=${new_min}`
    update_db_data(where,new_data)
  }
  let edit_black_price=() =>{
    var where=`operator='${props.operator}'`
    var new_data=`day3=${props.data_black_price}`
    update_db_data(where,new_data)
    props.update_black_price_w('')
  }
  return(
    <b>
    <Button className={b.serbut} outline color="primary" onClick={toggle}>EDIT</Button>
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>Оператор: {props.operator}</ModalHeader>
      <ModalBody>
      🗓️Минимальная аренда:
      <UncontrolledDropdown >
        <DropdownToggle className={b.serbut} caret>⌛</DropdownToggle>
        <DropdownMenu >
        <DropdownItem onClick={() => update_min('0')}>1 день</DropdownItem>
        <DropdownItem onClick={() => update_min('2')}>3 дня</DropdownItem>
        <DropdownItem onClick={() => update_min('6')}>7 дней</DropdownItem>
        <DropdownItem onClick={() => update_min('13')}>14 дней</DropdownItem>
        <DropdownItem onClick={() => update_min('29')}>30 дней</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      1 день:
      <br/>
      <input className={b.upspace} onChange={onLinkChange}
              ref={newLink}  value={props.data_link} placeholder={"Enter"}/>
      <Button className={b.serbut} disabled={ !props.data_link} color="primary" onClick={edit_link}>🔄Update</Button>
      <br/>
      3 дня:
      <br/>
      <input className={b.upspace} onChange={onBlackPriceChange}
              ref={newBlackPrice}  value={props.data_black_price} placeholder={"Enter"}/>
      <Button className={b.serbut} disabled={ !props.data_black_price} color="primary" onClick={edit_black_price}>🔄Update</Button>
      <br/>
      7 дней:
      <br/>
      <input type="number" min="0" onChange={onOwnerChange} pattern={"^[0-9]*$"}
              ref={newOwner}  value={props.data_owner} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_owner} color="primary" onClick={edit_owner}>🔄Update</Button>
      <br/>
      15 дней:
      <br/>
      <input type="number" min="0" onChange={onReceived_idChange} 
              ref={newReceived_id}  value={props.data_received_id} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_received_id} color="primary" onClick={edit_received_id}>🔄Update</Button>
      <br/>
      30 дней:
      <br/>
      <input type="number" min="0" onChange={onActive_periodChange} 
              ref={newActive_period}  value={props.data_active_period} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_active_period} color="primary" onClick={edit_active_period}>🔄Update</Button>
      <br/>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)





}


export default Edit
