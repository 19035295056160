import React , { useState } from 'react';
import b from './Show.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Show = (props) =>{
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  let get_status=(status) =>{
    if(status=='admin'){
       return (<p>
        <b>Status:</b>Админ<br/>
        Баланс: {props.credits}$
        </p>)
     }
    if(status=='customer'){
    return (<p>
      <b>Status:</b>Клиент<br/>
      Потратил: {props.balance}$<br/>
      Баланс: {props.credits}$
      </p>)
    }
    if(status=='freelancer'){
      return (<p>
        <b>Status:</b>Исполнитель<br/>
        Заработок: {props.balance}$<br/>
        Не выплачено: {props.unpaid}$
        </p>)
    }
    return ""
  }
  return(
    <b > 
    <Button className={b.serbut}   outline  onClick={toggle} >Show</Button>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>ID: {props.id}</ModalHeader>
      <ModalBody >
      <b>ID:</b> {props.id}<br/>
      <b>Name:</b>{props.name}<br/>
      <b>Username:</b><a href={`https://t.me/${props.username}`} target="_blank">@{props.username}</a><br/>
      {get_status(props.status)}  
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)
}


export default Show
