
import React from 'react';
import Navbar from './Navbar';
import { connect } from 'react-redux';
import * as update_data_users from '../../redux/users-reducer';
import * as update_data_orders from '../../redux/orders-reducer';
import * as update_data_checks from '../../redux/checks-reducer';
import * as update_data_items from '../../redux/items-reducer';
import * as update_data_sms from '../../redux/sms-reducer';
import * as update_data_phone from '../../redux/phone-reducer';
import * as update_data_price from '../../redux/price-reducer';
import * as update_data_sections from '../../redux/section-reducer';
class NavbarContainer extends React.Component {

  render() {
    return (<Navbar {...this.props} />);
  }
}
let mapDispatchToProps=(dispatch)=>{
  return{
    update_users:(data)=>{
      dispatch(update_data_users.update_data(data,''));
    },
    update_orders:(data)=>{
      dispatch(update_data_orders.update_data(data,''));
    },
    update_checks :(data)=>{
      dispatch(update_data_checks.update_data(data,''));
    },
    update_items :(data)=>{
      dispatch(update_data_items.update_data(data,''));
    },
    update_sms :(data)=>{
      dispatch(update_data_sms.update_data(data,''));
    },
    update_phones :(data)=>{
      dispatch(update_data_phone.update_data(data,''));
    },
    update_prices :(data)=>{
      dispatch(update_data_price.update_data(data,''));
    }
    ,
    update_sections :(data)=>{
      dispatch(update_data_sections.update_data(data,''));
    }
    ,
    update_current_page :(data)=>{
      dispatch(update_data_phone.update_current_page(data));
    }
  }
}
let mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuth,
  }

}
export default connect(mapStateToProps,mapDispatchToProps)(NavbarContainer);
