import PhonesButtonsContainer from './PhonesButtons/PhonesButtonsContainer';
import PhoneContainer from './Phone/PhoneContainer.jsx';
import HeadContainer from './Head/HeadContainer.jsx';
import PaginatorContainer from '../common/Paginator/PaginatorContainer.jsx';
import  {Table } from 'reactstrap';
const Phones = (props) => {
  let items = props.data.map(
    p =>
    <PhoneContainer 
    id={p.id}
    owner={p.owner}
    phone_number={p.phone}
    order_id={p.order_id}
    status={p.status}
    key_word={p.key_word}
    expiry_date={p.expiry_date}
    price={p.price}
    black_price={p.black_price}
    operator={p.operator}
    received_id={p.received_id}
    db_name={props.db_name}
    show={p.show}
    rent_type={p.rent_type}
    active_period={p.active_period}
    image={p.image}
    section={p.section}
    page={p.page}
    port={p.port}
    server={p.server}
    select={p.select}
    />
    );
  return (
      <div>
        <PhonesButtonsContainer 
            db_name={props.db_name}
            />
        <PaginatorContainer/>        
        <Table striped bordered hover cellSpacing="1" >
            <thead>
              <HeadContainer
                  db_name={props.db_name}
                  />
            </thead>
            <tbody>
              {items}
            </tbody>
        </Table>
      </div>
  );
}

export default Phones;
