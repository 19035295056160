import React , { useState } from 'react';
import CalendarForm from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
const Calendar = (props) =>{
  const [value, setValue] = useState(new Date());
  function onChange(nextValue) {
    setValue(nextValue);
  }
  function onClickDay(value) {
    var choosen_date=new Date(value)
    var day=choosen_date.getDate().toString().padStart(2, "0")
    var month=(choosen_date.getMonth() + 1).toString().padStart(2, "0")
    var year=choosen_date.getFullYear()
    var new_date=`${year}-${month}-${day} 13:12:06.768591+03:00`
    setValue(value);
    props.update_expiry_date(new_date);
  }
  const locale = 'ru-RU'; 
  return (
    <div>
      <CalendarForm 
      onChange={onChange} 
      value={value} 
      onClickDay={onClickDay}
      formatLongDate ={
        (date) => new Intl.DateTimeFormat(
          locale, 
          {
            year: "numeric", 
            month: "2-digit", 
            day: "2-digit"
          }).format(date)
        }
      />
    </div>
  );
}


export default Calendar
