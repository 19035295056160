
import {update_data } from '../../../redux/checks-reducer.js';
import { connect } from 'react-redux';
import ChecksButtons from './ChecksButtons.jsx';

let mapStateToProps=(state)=>{
  return{
    data:state.checks.data,
    current_type:state.checks.current_type,
    order_by:state.checks.order_by
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    }
  }
}
const ChecksButtonsContainer = connect(mapStateToProps,mapDispatchToProps)(ChecksButtons);
export default ChecksButtonsContainer;
