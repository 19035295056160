import React  from 'react';
import b from './Phone.module.css';
import Edit from './edit/edit.jsx'
import Delete from './delete/delete.jsx'
import Show from './Show/Show.jsx'
import  {Table,Button} from 'reactstrap';
import { get_info_user } from '../../common/common';
import { dbAPI } from '../../../api/api_db';
import { filter_data,get_show_items_amount } from '../../common/common';
const Phone = (props) =>{
   let get_status=() =>{
      if(props.status==2){
         return `Ожидает продления\n`
         }
      if(props.status==1){
         return `В аренде`+`\n${props.order_id}\n`
      }
      if(props.status==0){
      return `Не использован\n`
      }
      if(props.status==3){
         return <span style={{color:'red'}}>Удалить</span>
         }
      return "-"
    }

if(props.show===true && (props.current_page==props.page || get_show_items_amount(props.data)<401)) { 
   let get_sernders=(phone_number,data) =>{
      let u_senders=[...new Set()]
      for (let i = 0; i < data.length; i++) {
         if((data[i].phone_number.includes(phone_number))&&(!u_senders.includes(data[i].sender))){
            let new_el=data[i].sender
            u_senders.push(new_el)
         }
      }
      let u_senders2=[...new Set()]
      for (let i = 0; i < u_senders.length; i++) {
            let new_el=u_senders[i]+' '
            if(u_senders2.length%4==0){
               new_el+='\n'
            }
            u_senders2.push(new_el)
      }
      let return_data='Нет SMS'
      if(u_senders.length>0){
         return_data=u_senders2
      }
      return return_data
   }
   let update_data_w = (where) => {
      dbAPI.get_db_data(props.db_name,where+props.condition)        
      .then(result => { 
        let data=[]
        for (let i = 0; i < result.data.length; i++) {
          let copy_data=result.data[i]
          copy_data.show=true
          data.push(copy_data)
        }
        let answer=filter_data(props.current_type,data,props.current_type)
        props.update_data_w(answer[0],answer[1])  
      })
      .catch(error => { console.error(error); return Promise.reject(error); })  
    }
   let update_db_data = (where,new_data) => {
      dbAPI.update_db_data(props.db_name,where,new_data)        
      .then(result => { 
         if(result.data.status==200){
            update_data_w('')
          }
          else{
            alert(`ERROR: ${result.data.status}`)
          }
      })
      .catch(error => { console.error(error); return Promise.reject(error); })  
    }
    let delete_phone=() =>{
      dbAPI.db_delete_data(props.db_name,`id=${props.id}`)        
      .then(result => { 
        if(result.data.status==200){
          update_data_w('')
        }
        else{
          alert(`ERROR: ${result.data.status}`)
        }
      })
      .catch(error => { console.error(error); return Promise.reject(error); })
    }
  return(
<Table bordered hover size="sm" cellSpacing="1" >
   <thead id={b.remover}>
      <tr>
      </tr>
   </thead>
   <tbody > <tr>
      <td  className={b.table_order_id}>
         {props.phone_number}<br/>
         {(props.image&&props.image.length)>4?'📥':''}{props.key_word=='fullRent'?'Полная аренда':props.key_word} {props.section}
      </td>
      <td  className={b.table_price}>
         <b>{(props.owner && props.owner.toString().length>1)?props.owner:``}</b>
         {(props.owner && props.owner.toString().length>1)?get_info_user(props.owner,props.users_data):`Нет владельца`}
         <br/>
         {(props.owner &&props.received_id &&props.owner.toString()==props.received_id )?'SMS идут владельцу':props.received_id?`SMS иду на ${props.received_id}`:''}
      </td>
      <td  className={b.table_status}>
         {get_status()}<br/>
         <small>[{get_sernders(props.phone_number,props.sms_data)}]</small>
      </td>
      <td  className={b.table_actions}>
         <Button className={b.serbut} color="danger" onClick={delete_phone}>🚨Удалить</Button>
      </td>
      </tr>
   </tbody>
</Table>
)}
else{
   return(<></>)
}
}


export default Phone
