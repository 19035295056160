import React , { useState } from 'react';
import b from './post.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import { dbAPI } from '../../../../api/api_db';
import { filter_data,set_new_data } from '../../../common/common';
import  Spinner from '../../../common/Spinner.jsx';
import Option from './Option/Option.jsx'
import { 
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Label,
  Col,
  Input
} from 'reactstrap';
import Calendar from './Calendar/calendar.jsx';
const Edit = (props) =>{
  let newLink= React.createRef();
  let newOwner= React.createRef();
  let newPrice= React.createRef();
  let newReceived_id= React.createRef();
  let newActive_period= React.createRef();
  let newWhitePrice= React.createRef();
  let newBlackPrice= React.createRef();
  let newImage= React.createRef();
  let newKey_Word= React.createRef();
  let unique_sections= props.u_sections.map( (p => <Option option={p}/>) );
  let get_unique_sections = (where) => {
    dbAPI.get_unique_db_data('admin_data|sections',where,'name')        
    .then(result => { 
        var array=[]
        for (let i = 0; i < result.data.length; i++) {
          array.push(result.data[i].name)
        }
        props.update_u_sections_w(array)
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
    return 'TEST_DATA'
  }
  let update_data_w = (new_data) => {
    let data=[]
    for (let i = 0; i < new_data.length; i++) {
      let copy_data=new_data[i]
      copy_data.show=true
      data.push(copy_data)
    }
    let answer=filter_data(props.current_type,data,props.current_type)
    props.update_data_w(answer[0],answer[1])  
}
  let update_db_data = (where,new_data) => {
    dbAPI.update_db_data(props.db_name,where,new_data)        
    .then(result => { 
      if(result.data.status==200){
        //update_data_w('')
      }
      else{
        alert(`ERROR: ${result.data.status}`)
      }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_image = (where,image) => {
    props.update_loading_status_w(1)
    dbAPI.update_image(props.db_name,where,image)        
    .then(result => { 
      if(result.data.status==200){
        props.update_image_w('')
      }
      else{
        alert(`ERROR: ${result.data.status}`)
      }
      props.update_loading_status_w(0)
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let onLinkChange=()=>{
    let data=newLink.current.value;
    props.update_link_w(data)
  }
  let onOwnerChange=()=>{
    let data=newOwner.current.value;
    data=data.replace(/^\s+/,'');
    props.update_owner_w(data)
  }
  let onPriceChange=()=>{
    let data=newPrice.current.value;
    data=data.replace(/^\s+/,'');
    props.update_price_w(data)
  }
  let onKey_WordChange=()=>{
    let data=newKey_Word.current.value;
    data=data.replace(/^\s+/,'');
    props.update_service_w(data)
  }
  let onReceived_idChange=()=>{
    let data=newReceived_id.current.value;
    data=data.replace(/^\s+/,'');
    props.update_received_id_w(data)
  }
  let onBoth_IDChange=()=>{
    let data=newBlackPrice.current.value;
    data=data.replace(/^\s+/,'');
    props.update_black_price_w(data)
  }
  let onActive_periodChange=()=>{
    let data=newActive_period.current.value;
    data=data.replace(/^\s+/,'');
    props.update_active_period_w(data)
  }
  let onActive_section=()=>{
    let data=newWhitePrice.current.value;
    data=data.replace(/^\s+/,'');
    props.update_white_price_w(data)
  }
  let onUpdate_Image=(event)=>{
    var where=`id='${props.id}'`
    const formData = new FormData();
    var image=event.target.files[0]
    formData.append('pic',image,image.name);
    update_image(where,formData)
    let data=newImage.current.value;
    props.update_image_w(data)
  }
  let update_status=(new_status) =>{
    var where=`id='${props.id}'`
    var new_data=`status=${new_status}`
    update_db_data(where,new_data)

    var new_phone_obj=props.phone_obj
    new_phone_obj.status=new_status
    var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj) 
    update_data_w(edited_data) 
    update_data_w(edited_data)    
  }
  const {
    className
  } = props;
  const [modal, setModal] = useState(false);
  const toggle = () =>{
    setModal(!modal);
  }
  let edit_link=() =>{
    var where=`id='${props.id}'`
    var new_data=`phone='${props.data_link}'`
    update_db_data(where,new_data)
    props.update_link_w('')
    var new_phone_obj=props.phone_obj
    new_phone_obj.phone=props.data_link
    var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj) 
    update_data_w(edited_data) 
  }
  let edit_owner=() =>{
    var where=`id='${props.id}'`
    var new_data=`owner=${props.data_owner},received_id='${props.data_owner}'`
    update_db_data(where,new_data)
    props.update_owner_w('')
    var new_phone_obj=props.phone_obj
    new_phone_obj.owner=props.data_owner
    new_phone_obj.received_id=props.data_owner
    var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj) 
    update_data_w(edited_data) 
  }
  let edit_price=() =>{
    var where=`id='${props.id}'`
    var new_data=`expiry_date='${props.data_price.replaceAll('+', '@')}',alert_3_days='${false}',alert_1_hour='${false}'`
    update_db_data(where,new_data)
    props.update_price_w('')
    var new_phone_obj=props.phone_obj
    new_phone_obj.expiry_date=props.data_price
    var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj) 
    update_data_w(edited_data) 
  }
  let edit_key_word=() =>{
    console.log(props.data_service)
    var where=`id='${props.id}'`
    var new_data=`key_word='${props.data_service.replaceAll('+', '@')}'`
    update_db_data(where,new_data)
    props.update_service_w('')
    var new_phone_obj=props.phone_obj
    new_phone_obj.key_word=props.data_service
    var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj) 
    update_data_w(edited_data) 
  }
  let edit_received_id=() =>{
    var where=`id='${props.id}'`
    var new_data=`received_id='${props.data_received_id}'`
    update_db_data(where,new_data)
    props.update_received_id_w('')
    var new_phone_obj=props.phone_obj
    new_phone_obj.received_id=props.data_received_id
    var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj) 
    update_data_w(edited_data) 
  }
  let edit_both=() =>{
    var where=`id='${props.id}'`
    var both_id=props.data_black_price.split(';')
    if(both_id.length==1){
      var new_data=`owner=${props.data_black_price},received_id='${props.data_black_price}'`
      var new_phone_obj=props.phone_obj
      new_phone_obj.owner=props.data_black_price
      new_phone_obj.received_id=props.data_black_price
      var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj) 
    update_data_w(edited_data) 
    }
    if(both_id.length==2){
      var new_data=`owner=${both_id[0]},received_id='${both_id[1]}'`
      var new_phone_obj=props.phone_obj
      new_phone_obj.owner=both_id[0]
      new_phone_obj.received_id=both_id[1]
      var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj) 
    update_data_w(edited_data) 
    }
    update_db_data(where,new_data)
    props.update_owner_w('')
  }
  let edit_active_period=() =>{
    var where=`id='${props.id}'`
    var new_data=`active_period=${props.data_active_period}`
    update_db_data(where,new_data)
    props.update_active_period_w('')

    var new_phone_obj=props.phone_obj
    new_phone_obj.active_period=props.data_active_period
    var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj) 
    update_data_w(edited_data) 
  }
  let edit_section=() =>{
    var where=`operator='${props.operator}'`
    var new_data=`section='${props.data_white_price}'`
    update_db_data(where,new_data)
    props.update_active_period_w('')

    var new_phone_obj=props.phone_obj
    new_phone_obj.section=props.data_white_price
    var edited_data=set_new_data('phone',props.id,props.data,new_phone_obj) 
    update_data_w(edited_data) 
  }
  let edit_image=() =>{
    var where=`id='${props.id}'`
    var new_data=`image=${props.data_image}`
    //update_db_data(where,new_data)
    props.update_image_w('')
  }
  let set_default_description=() =>{
    props.update_description_w(props.description)
  }
  let get_type=(status) =>{
    if(status=='done'){
       return "Закрыт"
     }
    if(status=='in_progress'){
    return "Без исполнителя"
    }
    if(status=='active'){
      return "Активный"
    }
   if(status=='deleted'){
   return "Удален"
   }
    return ""
  }
  return(
    <b>
    <Button className={b.serbut} outline color="primary" onClick={() =>
      {
        get_unique_sections("type='phone'");
        toggle()
      }
    }
    >E</Button>
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>{props.loading_status==0?`Item ID: ${props.id}`:<Spinner/>}</ModalHeader>
      <ModalBody>
      Номер: {props.phone_number}
      <br/>
      <input className={b.upspace} onChange={onLinkChange} 
              ref={newLink}  value={props.data_link} placeholder={"Enter New Phone"}/>
      <Button className={b.serbut} disabled={ !props.data_link} color="primary" onClick={edit_link}>Update Phone</Button>
      <br/>
      {(props.owner==props.received_id)?
      <div>
        Owner ID {props.owner}:
        <br/>
        <input type="number" min="0" onChange={onOwnerChange} pattern={"^[0-9]*$"}
                ref={newOwner}  value={props.data_owner} placeholder={"Enter "}/>
        <Button className={b.serbut} disabled={ !props.data_owner} color="primary" onClick={edit_owner}>Update Owner ID</Button>
        <br/>
        Получатель ID {props.received_id}:
        <br/>
        <input type="text" onChange={onReceived_idChange} 
                ref={newReceived_id}  value={props.data_received_id} placeholder={"Enter "}/>
        <Button className={b.serbut} disabled={ !props.data_received_id} color="primary" onClick={edit_received_id}>Update Receiver ID</Button>
        <br/>
      </div>:
          <div>
            ID владельца и получателя:
            <br/>
            <input type="text" onChange={onBoth_IDChange}
                    ref={newBlackPrice}  value={props.data_black_price} placeholder={"Enter "}/>
            <Button className={b.serbut} disabled={ !props.data_black_price} color="primary" onClick={edit_both}>Update ID</Button>
            <br/>
          </div>
      }
      Срок Аренды в днях:
      <br/>
      <input type="number" min="0" onChange={onActive_periodChange} 
              ref={newActive_period}  value={props.data_active_period} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_active_period} color="primary" onClick={edit_active_period}>Update</Button>
      <br/>
      Раздел:
      <br/>
      <input onChange={onActive_section} 
            list="datalist_section"  ref={newWhitePrice}  value={props.data_white_price} placeholder={"Enter "}/>
      <datalist id="datalist_section">
            <select>
            {unique_sections}
            </select>
      </datalist>               
      <Button className={b.serbut} disabled={ !props.data_white_price} color="primary" onClick={edit_section}>Update</Button>
      <br/>
      🛠️Сервис:
      <br/>
      <input class={b.textarea} role="textbox" contenteditable type="text" onChange={onKey_WordChange} 
              ref={newKey_Word}  value={props.data_service} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_service} color="primary" onClick={edit_key_word}>🛠️Update</Button>
      <br/>
      Фото:
      <br/>
      <FormGroup row>
          <Col sm={10}>
            <input
              id="fileUpload"
              name="file"
              type="file"
              onChange={onUpdate_Image}
              ref={newImage}
              value={props.data_image}

            />
            {//<Button className={b.serbut} disabled={!props.data_image} color="primary" onClick={edit_image}>Update</Button>
            }
          </Col>
      </FormGroup>
      Дата начала аренды:
      <br/>
      Пример: 2023-09-22 13:12:06.768591+03:00
      <br/>
      <input class={b.textarea} role="textbox" contenteditable type="text" onChange={onPriceChange} 
              ref={newPrice}  value={props.data_price} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_price} color="primary" onClick={edit_price}>Update</Button>
      <br/>
      Status {props.status}
      <UncontrolledDropdown >
        <DropdownToggle className={b.serbut} caret>Статус {get_type(props.data_status)}</DropdownToggle>
        <DropdownMenu >
        <DropdownItem onClick={() => update_status('1')}>В аренде</DropdownItem>
        <DropdownItem onClick={() => update_status('0')}>Не использован</DropdownItem>
        <DropdownItem onClick={() => update_status('2')}>Ожидает продления</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <br/>
      <Calendar
        update_expiry_date={props.update_price_w}
      />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)





}


export default Edit
