// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n/* Table\r\n**************************/\r\n\r\n.Head_table_name__cSH9l{\r\n  width: 15%;}\r\n.Head_table_price__V7Lvz{width: 15%;}\r\n.Head_table_status__GGTMH{width: 15%;}\r\n.Head_table_date__s6nfn{width: 20%;}\r\n.Head_table_actions__HStJA{width: 35%;}\r\n.Head_input__3oEK7{\r\n  width: 80%;\r\n  margin-top: 10px;\r\n}", "",{"version":3,"sources":["webpack://./src/Components/Checks/Head/Head.module.css"],"names":[],"mappings":";AACA;0BAC0B;;AAE1B;EACE,UAAU,CAAC;AACb,yBAAa,UAAU,CAAC;AACxB,0BAAc,UAAU,CAAC;AACzB,wBAAY,UAAU,CAAC;AACvB,2BAAe,UAAU,CAAC;AAC1B;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["\r\n/* Table\r\n**************************/\r\n\r\n.table_name{\r\n  width: 15%;}\r\n.table_price{width: 15%;}\r\n.table_status{width: 15%;}\r\n.table_date{width: 20%;}\r\n.table_actions{width: 35%;}\r\n.input{\r\n  width: 80%;\r\n  margin-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_name": "Head_table_name__cSH9l",
	"table_price": "Head_table_price__V7Lvz",
	"table_status": "Head_table_status__GGTMH",
	"table_date": "Head_table_date__s6nfn",
	"table_actions": "Head_table_actions__HStJA",
	"input": "Head_input__3oEK7"
};
export default ___CSS_LOADER_EXPORT___;
