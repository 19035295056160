
import React from 'react';
import s from './OrdersButtons.module.css';
import { dbAPI } from '../../../api/api_db';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledDropdown,
  Container, Row, Col
} from 'reactstrap';
import DDItem from './DDItem.jsx'
import { get_new_direction,filter_data } from '../../common/common';
const OrdersButtons = React.memo((props) => {
  let get_status = () => {
    if(props.current_type.includes("done")){
      return "Оплаченные заказы"
    }
    if(props.current_type.includes("active")){
      return "Неоплаченные заказы"
    }
    if(props.current_type.includes("deleted")){
      return "Просроченные заказы"
    }
    return"ВСЕ заказы"
  }
  return (
    <div className={s.postsBlock}>
      <h3>Orders: {Object.values(props.data).filter(v => v.show === true).length}/{props.data.length}</h3>
      {get_status()}
      <IncreaseForm 
      update_data_w={props.update_data_w} 
      update_data_w_={props.update_data_w_} 
      db_name={props.db_name}
      order_by={props.order_by}

      current_type={props.current_type}
      update_u_sections_w={props.update_u_sections_w}
      u_sections={props.u_sections}
      data={props.data}
       />
    </div>
  );
});

const IncreaseForm = (props) => {
  let update_data_w_test = (where) => {
    where=get_new_direction(where,props.current_type)
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => {
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      props.update_data_w(data,where) 
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_data_w = (where) => {
    let answer=filter_data(where,props.data,props.current_type)
    props.update_data_w(answer[0],answer[1])
  }
 
  let get_status = () => {
    if(props.current_type.includes("done")){
      return "Оплаченные"
    }
    if(props.current_type.includes("active")){
      return "Неоплаченные"
    }
    if(props.current_type.includes("deleted")){
      return "Удаленные"
    }
    return""
  }
  let get_type = () => {
    if(props.current_type.includes("type=service")){
      return "Услуги"
    }
    if(props.current_type.includes("type=item")){
      return "Товары"
    }
    return""
  }
  let get_theme = () => {
    for (let i = 0; i < props.u_sections.length; i++) {
      if(props.current_type.includes(props.u_sections[i].replaceAll('@', ' '))){
        return props.u_sections[i].replaceAll('@', ' ')
      }
    }
    return""
  }
  return (
    <div className={s.parent}>
<Container>
<Row>
<Col xs="auto">
  <Button color="primary" className={s.serbut} onClick={() => update_data_w_test('')}>🔄</Button>
</Col>
<Col xs="auto">
  <UncontrolledDropdown >
  <DropdownToggle className={s.serbut} caret>Статус {get_status()}</DropdownToggle>
  <DropdownMenu >
  <DropdownItem onClick={() => update_data_w("status=done")}>Оплаченные</DropdownItem>
  <DropdownItem onClick={() => update_data_w("status=active")}>Неоплаченные</DropdownItem>
  <DropdownItem onClick={() => update_data_w("status=deleted")}>Удаленные</DropdownItem>
  </DropdownMenu>
  </UncontrolledDropdown>
</Col>
<Col xs="auto">
  <UncontrolledDropdown >
  <DropdownToggle className={s.serbut} caret>Тип {get_type()}</DropdownToggle>
  <DropdownMenu >
  <DropdownItem onClick={() => update_data_w("type=service")}>Услуги</DropdownItem>
  <DropdownItem onClick={() => update_data_w("type=item")}>Товары</DropdownItem>
  </DropdownMenu>
  </UncontrolledDropdown>
</Col>
</Row>
</Container>
    </div>
  )
}


export default OrdersButtons;
