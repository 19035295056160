import ChecksButtonsContainer from './ChecksButtons/ChecksButtonsContainer';
import CheckContainer from './Check/CheckContainer.jsx';
import HeadContainer from './Head/HeadContainer.jsx';
const Checks = (props) => {
  let items = props.data.map(
    p => 
    <CheckContainer 
    author_id={p.author_id}
    address={p.address}
    amount={p.amount}
    check_id={p.check_id}
    currency={p.currency}
    date={p.date}
    order_id={p.order_id}
    payment_id={p.payment_id}
    price={p.price}
    status={p.status}
    type={p.type}
    db_name={props.db_name}
    show={p.show}
    />
    );
  return (
    <div>
      <ChecksButtonsContainer 
      db_name={props.db_name}
      />
      <HeadContainer
      db_name={props.db_name}
      />
      {items}
    </div>
  );
}

export default Checks;
