
import {
  update_data,
  update_link,
  update_status,
  update_owner,
  update_price,
  update_description,
  update_received_id,
  update_u_senders,
  update_active_period,
  update_white_price,
  update_black_price,
  update_image,
  update_loading_status,
  update_u_sections } from '../../../redux/phone-reducer.js';
import { connect } from 'react-redux';
import Order from './Phone.jsx';

let mapStateToProps=(state)=>{
  return{
    data:state.phone.data,
    sms_data:state.sms.data,
    current_type:state.phone.current_type,

    data_received_id:state.phone.data_received_id,
    data_link:state.phone.data_link,
    data_status:state.phone.data_status,
    data_owner:state.phone.data_owner,
    data_price:state.phone.data_price,
    data_description:state.phone.data_description,
    data_active_period:state.phone.data_active_period,
    data_white_price:state.phone.data_white_price,
    data_black_price:state.phone.data_black_price,
    users_data:state.users.data,
    data_image:state.phone.data_image,
    loading_status:state.phone.loading_status,
    current_page:state.phone.current_page,
    unique_senders:state.phone.unique_senders,
    u_sections:state.phone.u_sections,
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    },
    update_link_w:(data)=>{
      dispatch(update_link(data));
    },
    update_status_w:(data)=>{
      dispatch(update_status(data));
    },
    update_owner_w:(data)=>{
      dispatch(update_owner(data));
    },
    update_price_w:(data)=>{
      dispatch(update_price(data));
    },
    update_received_id_w:(data)=>{
      dispatch(update_received_id(data));
    },
    update_description_w:(data)=>{
      dispatch(update_description(data));
    },
    update_u_senders_w:(data)=>{
      dispatch(update_u_senders(data));
    },
    update_active_period_w:(data)=>{
      dispatch(update_active_period(data));
    },
    update_white_price_w:(data)=>{
      dispatch(update_white_price(data));
    },
    update_black_price_w:(data)=>{
      dispatch(update_black_price(data));
    },
    update_image_w:(data)=>{
      dispatch(update_image(data));
    },
    update_loading_status_w:(data)=>{
      dispatch(update_loading_status(data));
    },
    update_u_sections_w:(data)=>{
      dispatch(update_u_sections(data));
    }
  }
}
const ItemContainer = connect(mapStateToProps,mapDispatchToProps)(Order);
export default ItemContainer;
