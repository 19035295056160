import React from 'react';
import { Formik, Form, Field, ErrorMessage, connect } from "formik";
import * as Yup from "yup";
import { Navigate } from "react-router-dom";
import style from './Login.module.css';
import { useCookies } from 'react-cookie';
const validateLoginForm = values => {
   const errors = {};
   if (!values.email) {
      errors.email = 'Required 1';
   } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
   ) {
      errors.email = 'Invalid email address';
   }
   return errors;
};

const validationSchemaLoginForm = Yup.object().shape({

   password: Yup.string()
      .min(2, "Must be longer than 2 characters")
      .max(10, "Must be shorter than 10 characters")
      .required("Required 2")
});

const LoginForm = (props) => {
   return <Formik
      initialValues={
         {
            email: "",
            password: "",
            rememberMe: false,
         }}
      validate={validateLoginForm}
      validationSchema={validationSchemaLoginForm}
      onSubmit={(values, { resetForm,setSubmitting, setStatus }) => {
         props.loginAccount(values.email,values.password,values.rememberMe,setStatus);
         resetForm({ values: '' });
         setSubmitting(false);
      }}
   >
      {({status}) => (
         <Form>
         <div>{status}</div>
            <div className={style.form_input_material}>
               <Field
                  name={'email'}
                  type={'text'}
                  className={style.form_control_material}
                   />
               <label htmlFor="username">Почта</label>
            </div>
            <ErrorMessage name="email" component="div" />
            <div  className={style.form_input_material}>
               <Field
                  name={'password'}
                  type={'password'}
                  className={style.form_control_material} />
                  <label htmlFor="password">Пароль</label>
            </div>
            <ErrorMessage name="password" component="div" />

            <div>
               <Field
                  type={'checkbox'}
                  name={'rememberMe'}
                  id='rememberMe' />
               <label htmlFor={'rememberMe'}>&nbsp;&nbsp;Запомнить </label>
            </div>

            <button className={style.btn_ghost}  type={'submit'}>Войти</button>
         </Form>
      )}
   </Formik>
}

const Login = (props) => {
   const [cookies, setCookie] = useCookies(['auth']);
   if(cookies.auth=='4H3DIREZQ6QO'){
      props.setAuthUserData(1,'pass@mail.com','password',true)
   }
   if(props.isAuth){
      if(cookies.auth!=='4H3DIREZQ6QO'){
         // Expires Example
         var today = new Date();
         var week2 = new Date();
         week2.setDate(today.getDate()+14);
         setCookie('auth', '4H3DIREZQ6QO', {
            expires: week2
         });
      }
   return(
      <h1>Добро пожаловать</h1>
   )
   }
   else{
   return (
      <div className={style.login_form_wrapper}>
         <div className={style.login_form}>
            <h2 >Вход </h2>

            <LoginForm 
               loginAccount={props.loginAccount}
               getFormData={props.getFormData}
            />
         </div>
      </div>
   )
}
}

export default Login;