
import {update_data,
  update_status,
  update_f_id,
  update_ex_id

} from '../../../redux/orders-reducer.js';
import { connect } from 'react-redux';
import Order from './Order';

let mapStateToProps=(state)=>{
  return{
    data:state.orders.data,
    current_type:state.orders.current_type,
    status_data:state.orders.status_data,
    f_id_data:state.orders.f_id_data,
    ex_id_data:state.orders.ex_id_data,

    users_data:state.users.data
  }
}

let mapDispatchToProps=(dispatch)=>{
  return{
    update_data_w:(data,current_type)=>{
      dispatch(update_data(data,current_type));
    },
    update_status_w:(status_data)=>{
      dispatch(update_status(status_data));
    },
    update_f_id_w:(data)=>{
      dispatch(update_f_id(data));
    },
    update_ex_id_w:(data)=>{
      dispatch(update_ex_id(data));
    }
  }
}
const OrderContainer = connect(mapStateToProps,mapDispatchToProps)(Order);
export default OrderContainer;
