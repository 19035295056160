import React  from 'react';
import b from './Order.module.css';
import Edit from './edit/edit.jsx'
import Delete from './delete/delete.jsx'
import Show from './Show/Show.jsx'
import  {Table} from 'reactstrap';
import { dbAPI } from '../../../api/api_db';
const Order = (props) =>{
   let get_type=(status) =>{
      if(status=='done'){
         return "Закрыт"
       }
      if(status=='in_progress'){
      return "Без исполнителя"
      }
      if(status=='active'){
        return "Активный"
      }
     if(status=='deleted'){
     return "Удален"
     }
      return ""
    }
    if(props.show===true) {     
      return(
      <Table bordered hover size="sm" cellSpacing="1" >
         <thead id={b.remover}>
            <tr>
            </tr>
         </thead>
         <tbody > <tr>
            <td  className={b.table_order_id}>
               {props.order_id}
            </td>
            <td  className={b.table_name}>
               {props.name}
            </td>
            <td  className={b.table_price}>
               {props.price}$
            </td>
            <td  className={b.table_status}>
               {get_type(props.status)}
            </td>
            <td  className={b.table_date}>
               {props.date}
            </td>
            <td  className={b.table_actions}>
               <Edit
                  update_author_info_w={props.update_author_info_w}
                  users_data={props.users_data}

                  author_id={props.author_id}
                  freelancer_id={props.freelancer_id}
                  executor_id={props.executor_id}
                  address={props.address}
                  amount={props.amount}
                  check_id={props.check_id}
                  currency={props.currency}
                  date={props.date}
                  order_id={props.order_id}
                  payment_id={props.payment_id}
                  price={props.price}
                  status={props.status}
                  type={props.type}
                  update_data_w={props.update_data_w}
                  update_status_w={props.update_status_w}
                  update_f_id_w={props.update_f_id_w}
                  update_ex_id_w={props.update_ex_id_w}
                  current_type={props.current_type}
                  db_name={props.db_name}
                  status_data={props.status_data}
                  f_id_data={props.f_id_data}
                  ex_id_data={props.ex_id_data}
                  />
               <Delete
                  author_id={props.author_id}
                  address={props.address}
                  amount={props.amount}
                  check_id={props.check_id}
                  currency={props.currency}
                  date={props.date}
                  order_id={props.order_id}
                  payment_id={props.payment_id}
                  price={props.price}
                  status={props.status}
                  type={props.type}
                  update_data_w={props.update_data_w}
                  current_type={props.current_type}
                  db_name={props.db_name}
                  />
               <Show
                  users_data={props.users_data}
                  author_id={props.author_id}
                  freelancer_id={props.freelancer_id}
                  name={props.name}
                  text={props.text}
                  theme={props.theme}
                  date={props.date}
                  order_id={props.order_id}
                  price={props.price}
                  status={props.status}
                  type={props.type}
                  db_name={props.db_name}
                  message_history={props.message_history}
                  executor_id={props.executor_id}
                  />
            </td>
            </tr>
         </tbody>
      </Table>
      )}
   else{
      return(<></>)
   }
}


export default Order
