import React  from 'react';
import b from './Head.module.css';
import SortButton from './SortButton/SortButton.jsx';
import  {Table } from 'reactstrap';
import { filter_data } from '../../common/common';

const Head=(props) =>{
    let s_phone_number= React.createRef();
    let s_name= React.createRef();
    let s_user_id= React.createRef();
    let s_date= React.createRef();
    let update_data_w = (where) => {
        let answer=filter_data(where,props.data,props.current_type)
        props.update_data_w(answer[0],answer[1])
      }
    let onChange=(value,target)=>{
        let data=value.current.value;
        data=data.replace(/^\s+/,'');
        var where=`${target}=${data}`
        update_data_w(where)
    }
  return(
    <Table striped bordered hover cellSpacing="1" >
        <thead>
            <tr>
            <th scope="col" className={b.table_order_id}>
                    <SortButton
                        name="operator"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Оператор
                    <br></br>
                    <input type="name" className={b.input} onChange={() => onChange(s_phone_number,'operator')}
                        ref={s_phone_number}
                        placeholder={""}  />
                </th>
                <th scope="col" className={b.table_price}>
                    <SortButton
                        name="day30"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Прайслист
                    <br></br>
                    <input type="name" className={b.input} onChange={() => onChange(s_user_id,'day30')}
                        ref={s_user_id}
                        placeholder={""}  />
                </th>
                <th scope="col" className={b.table_actions}>Actions</th>
            </tr>
        </thead>
    <tbody id={b.remover}></tbody>
    </Table>)}



export default Head
