// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.SectionsButtons_postsBlock__\\+ZOoa{\n    padding: 10px;\n  }\n.SectionsButtons_error__fGFxU {\n  color:red;\n}\n.SectionsButtons_serbut__wTOzg{\n  margin-left: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Sections/SectionsButtons/SectionsButtons.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;EACf;AACF;EACE,SAAS;AACX;AACA;EACE,iBAAiB;AACnB","sourcesContent":["\n.postsBlock{\n    padding: 10px;\n  }\n.error {\n  color:red;\n}\n.serbut{\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postsBlock": "SectionsButtons_postsBlock__+ZOoa",
	"error": "SectionsButtons_error__fGFxU",
	"serbut": "SectionsButtons_serbut__wTOzg"
};
export default ___CSS_LOADER_EXPORT___;
