// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.ChecksButtons_postsBlock__KkJcI{\n    padding: 10px;\n  }\n.ChecksButtons_error__0P7pe {\n  color:red;\n}", "",{"version":3,"sources":["webpack://./src/Components/Checks/ChecksButtons/ChecksButtons.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;EACf;AACF;EACE,SAAS;AACX","sourcesContent":["\n.postsBlock{\n    padding: 10px;\n  }\n.error {\n  color:red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postsBlock": "ChecksButtons_postsBlock__KkJcI",
	"error": "ChecksButtons_error__0P7pe"
};
export default ___CSS_LOADER_EXPORT___;
