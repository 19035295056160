// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.PricesButtons_postsBlock__pGcHC{\n    padding: 10px;\n  }\n.PricesButtons_error__OKaPa {\n  color:red;\n}\n.PricesButtons_serbut__OZsho{\n  margin-left: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Prices/PricesButtons/PricesButtons.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;EACf;AACF;EACE,SAAS;AACX;AACA;EACE,iBAAiB;AACnB","sourcesContent":["\n.postsBlock{\n    padding: 10px;\n  }\n.error {\n  color:red;\n}\n.serbut{\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postsBlock": "PricesButtons_postsBlock__pGcHC",
	"error": "PricesButtons_error__OKaPa",
	"serbut": "PricesButtons_serbut__OZsho"
};
export default ___CSS_LOADER_EXPORT___;
