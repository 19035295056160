const UPDATE_DATA = 'UPDATE_DATA_USER';
const UPDATE_DATA_ = 'UPDATE_DATA_USER_';
const UPDATE_ORDER = 'UPDATE_USER_ORDER';

const UPDATE_BALANCE = 'UPDATE_BALANCE_USER';
const UPDATE_CREDITS = 'UPDATE_CREDITS_USER';
const UPDATE_UNPAID = 'UPDATE_UNPAID_USER';

let initialState = {
    counts:2,
    data:[],
    current_type:'',
    current_status:'',
    order_name:'balance',
    order_by:' ORDER BY balance DESC',
    order_direction:'DESC',

    data_balance:'',
    data_credits:'',
    data_unpaid:''

};
 
const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DATA_:
            return {
                ...state,
                data:action.data
            }
        case UPDATE_DATA:
            return {
                ...state,
                data:action.data,
                current_type:action.current_type
            }
        case UPDATE_ORDER:
            return {
                ...state,
                order_name:action.order_name,
                order_by:action.order_by,
                order_direction:action.order_direction
            }
        case UPDATE_BALANCE:
            return {
                ...state,
                data_balance:action.data_balance
            }
        case UPDATE_CREDITS:
            return {
                ...state,
                data_credits:action.data_credits
            }
        case UPDATE_UNPAID:
            return {
                ...state,
                data_unpaid:action.data_unpaid
            }
        default:
            return state;
    }
}
export const update_unpaid = (data) => (
    { 
        type: UPDATE_UNPAID, 
        data_unpaid:data
    }
    )
export const update_credits = (data) => (
    { 
        type: UPDATE_CREDITS, 
        data_credits:data
    }
    )
export const update_balance = (data) => (
    { 
        type: UPDATE_BALANCE, 
        data_balance:data
    }
    )
export const update_data_ = (data) => (
    { 
        type: UPDATE_DATA_, 
        data:data
    }
    )
export const update_data = (data,current_type) => (
    { 
        type: UPDATE_DATA, 
        data:data,
        current_type:current_type
    }
    )
export const update_order = (order_name,order_by,order_direction) => (
    { 
        type: UPDATE_ORDER,order_name,order_by,order_direction
    }
    )
export default ordersReducer;