
import React from 'react';
import { connect } from 'react-redux';
import Prices from './Prices';
import {useParams} from 'react-router-dom';
import { compose } from 'redux';
import { withAuthRedirect } from '../../hoc/withAuthRedirect';
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
      let params = useParams();
      return (
          <Component
              {...props}
              router={{ params }}
          />
      );
  }
  return ComponentWithRouterProp;
}

class PricesContainer extends React.Component {
  componentDidMount() {
    let userId = this.props.router.params.userId ;
    if(!userId){
      userId=this.props.authorizedUserId;
    }
  }
  render() {
    return (
      <Prices {...this.props} 
      data={this.props.data}
      current_type={this.props.current_type}
      db_name='admin_data|rent_prices'
      />
    );
  }
}

let mapStateToProps = (state) => ({
  data:state.price.data,
  current_type:state.price.current_type,

  
})

export default compose(
  connect(mapStateToProps),
  withRouter,
  withAuthRedirect
)
(PricesContainer);
