import React  from 'react';
import {
    DropdownItem
  } from 'reactstrap';
  import {filter_data } from '../../common/common';

const DDItem=(props) =>{
  let update_data_w = (where) => {
    let answer=filter_data(where,props.data,props.current_type)
    props.update_data_w(answer[0],answer[1])
  }
return(
<DropdownItem onClick={() => update_data_w(`name=${props.option.replaceAll(' ', '@')}`)}>{props.option}</DropdownItem>
)
}


export default DDItem
