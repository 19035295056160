import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import s from './Navbar.module.css';
import { dbAPI } from '../../api/api_db';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
const NavbarHead = (props) => {
  let get_info=(db,where='') =>{
    if(props.isAuth){
    dbAPI.get_db_data(db,where)        
    .then(result => {
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        copy_data.select=false
        data.push(copy_data)
      }
      if(db==='accounts'){
      props.update_users(data)
        }
      if(db==='checks'){
        props.update_checks(data)
        }
      if(db==='orders'){
        props.update_orders(data)
        }
      if(db==='sms'){
        props.update_sms(data)
        }
      if(db==='phone'){
        props.update_current_page(0)
        data.sort(function(a, b) {
          var keyA = a.id,
            keyB = b.id;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        var page=0;
        var counter=0;
        for (let i = 0; i < data.length; i++) {
          counter+=1
          if(counter==400){
            counter=0
            page+=1
          }
          let copy_data=data[i]
          copy_data.page=page
        }        
        props.update_phones(data)
        }
      if(db==='admin_data|rent_prices'){
        props.update_prices(data)
        }
      if(db==='admin_data|sections'){
        props.update_sections(data)
        }
    })
    .catch(error => { console.log(error) })
  }
  else{
    alert('ERROR')
  }
    return 'TesT'
 }
 if(props.isAuth){
  return (
    <>
        <div className={s.menu}>
      <nav className={s.menu__list}>
      <Navbar key={false} expand={false} className="bg-body-secondary">
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${false}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
              placement="top"
              scroll={true} 
              backdrop={true}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`}>
                  Меню
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                    <ButtonToolbar aria-label="Toolbar with button groups">
                        <ButtonGroup className="me-2" aria-label="First group">
                            <NavLink  onClick={() => {
                          get_info("accounts");
                          get_info('checks');
                      }} to='/checks' className = {  s.menu__link2  } >Чеки</NavLink>
                              <NavLink  onClick={() => {
                          get_info("accounts");
                          get_info('orders');
                      }} to='/orders' className = { s.menu__link2 } >Заказы</NavLink>
                            <NavLink  onClick={() => {
                      get_info("accounts");
                  }} to='/users' className = { s.menu__link2 } >Пользователи</NavLink>
                        </ButtonGroup>
                    </ButtonToolbar>
                    <ButtonToolbar aria-label="Toolbar with button groups">
                      <ButtonGroup className="me-2" aria-label="Second group">
                              <NavLink  onClick={() => {
                                  get_info("accounts");
                                  get_info('orders');
                                  //get_info('sms');
                                  get_info('phone');
                              }} to='/statistics' className = { s.menu__link2 } >Статистика</NavLink>
                                        <NavLink  onClick={() => {
                                  get_info("accounts");
                                  get_info('sms');
                              }} to='/sms' className = { s.menu__link2 } >SMS</NavLink>
                                        <NavLink  onClick={() => {
                                  get_info("accounts");
                                  get_info('phone');
                                  //get_info('sms');
                              }} to='/phone' className = { s.menu__link2 } >Номера</NavLink>
                                        <NavLink  onClick={() => {
                                  get_info('admin_data|rent_prices');
                              }} to='/prices' className = { s.menu__link2 } >Цены</NavLink>
                                        <NavLink  onClick={() => {
                                  get_info('admin_data|sections');
                              }} to='/sections' className = { s.menu__link2 } >Разделы</NavLink>
                          </ButtonGroup>
                      </ButtonToolbar>
                      <ButtonToolbar aria-label="Toolbar with button groups">
                        <ButtonGroup className="me-2" aria-label="Third group">
                          <NavLink  onClick={() => {
                              get_info('admin_data|sections');
                              get_info("accounts");
                              get_info('phone','status=2');
                              //get_info('sms');
                          }} to='/basket' className = { s.menu__link2 } >🗑️</NavLink>
                            </ButtonGroup>
                      </ButtonToolbar>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        <span className={s.menu__item}>
          <NavLink  onClick={() => {
            get_info("accounts");
            get_info('checks');

        }} to='/checks' className = {  s.menu__link  } >Чеки</NavLink>
        </span>
        <span className={s.menu__item}>
          <NavLink  onClick={() => {
            get_info("accounts");
            get_info('orders');

        }} to='/orders' className = { s.menu__link } >Заказы</NavLink>
        </span>
        <span className={s.menu__item}>
          <NavLink  onClick={() => {
            get_info("accounts");

        }} to='/users' className = { s.menu__link } >Пользователи</NavLink>
        </span>
        <span className={s.menu__item}>
          <NavLink  onClick={() => {
            get_info("accounts");
            get_info('orders');
            //get_info('sms');
            get_info('phone');
        }} to='/statistics' className = { s.menu__link } >Статистика</NavLink>
        </span>
        <span className={s.menu__item}>
          <NavLink  onClick={() => {
            get_info("accounts");
            get_info('sms','order by id desc limit 10');
        }} to='/sms' className = { s.menu__link } >SMS</NavLink>
        </span>
        <span className={s.menu__item}>
          <NavLink  onClick={() => {
            get_info('admin_data|sections');
            get_info("accounts");
            get_info('phone');
            get_info('sms','status="old"');
            //get_info('sms');
        }} to='/phone' className = { s.menu__link } >Номера</NavLink>
        </span>
        <span className={s.menu__item}>
          <NavLink  onClick={() => {
            get_info('admin_data|sections');
            get_info("accounts");
            get_info('phone','status=2');
            //get_info('sms');
        }} to='/basket' className = { s.menu__link } >🗑️</NavLink>
        </span>
        <span className={s.menu__item}>
          <NavLink  onClick={() => {
            get_info('admin_data|rent_prices');
        }} to='/prices' className = { s.menu__link } >Цены</NavLink>
        </span>
        <span className={s.menu__item}>
          <NavLink  onClick={() => {
            get_info('admin_data|sections');
        }} to='/sections' className = { s.menu__link } >Разделы</NavLink>
        </span>
      </nav>
    </div>
      </>
  );
  }
  else{
return(
  <>
      <div className={s.menu}>
    <nav className={s.menu__list}>
    <Navbar key={false} expand={false} className="bg-body-secondary">
        <Container fluid>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} />
        </Container>
      </Navbar>
    </nav>
    </div>
    </>
    );
  }
}
export default NavbarHead;