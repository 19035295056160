import React  from 'react';
import b from './Head.module.css';
import SortButton from './SortButton/SortButton.jsx';
import  {Table } from 'reactstrap';
import { filter_data } from '../../common/common';
const Head=(props) =>{ 
    let update_data_w = (where) => {
        let answer=filter_data(where,props.data,props.current_type)
        props.update_data_w(answer[0],answer[1])
      }
    let onChange=(value,target)=>{
        let data=value.current.value;
        data=data.replace(/^\s+/,'');
        var where=`${target}=${data}`
        update_data_w(where)
    }
    let s_Order_ID= React.createRef();
    let s_Name= React.createRef();
    let s_price= React.createRef();
    let s_date= React.createRef();
  return(
    <Table striped bordered hover cellSpacing="1" >
        <thead>
            <tr>
            <th scope="col" className={b.table_order_id}>
                    <SortButton
                        name="order_id"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Order ID
                    <br/>
                    <input type="name" className={b.input} onChange={() => onChange(s_Order_ID,'order_id')}
                        ref={s_Order_ID}
                        placeholder={"Enter Order ID"}  />
                </th>
                <th scope="col" className={b.table_name}>
                    <SortButton
                        name="name"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Name
                    <br/>
                    <input type="name" className={b.input} onChange={() => onChange(s_Name,'name')}
                        ref={s_Name}
                        placeholder={"Enter name"}  />
                </th>
                <th scope="col" className={b.table_price}>
                    <SortButton
                        name="price"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Price $
                    <br/>
                    <input type="name" className={b.input} onChange={() => onChange(s_price,'price')}
                        ref={s_price}
                        placeholder={"Enter price"}  />
                </th>
                <th scope="col" className={b.table_status}>
                    <SortButton
                        name="status"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Status
                    <br/>
                    <input type="name" className={b.input}
                        placeholder={"disabled"} disabled="disabled"/>
                </th>
                <th scope="col" className={b.table_date}>
                    <SortButton
                        name="date"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Date
                    <br/>
                    <input type="name" className={b.input} onChange={() => onChange(s_date,'date')}
                        ref={s_date}
                        placeholder={"Enter date"}  />
                </th>
                <th scope="col" className={b.table_actions}>Actions</th>
            </tr>
        </thead>
    <tbody id={b.remover}></tbody>
    </Table>)}



export default Head
