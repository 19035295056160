import React , { useState } from 'react';
import b from './Show.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { get_info_user } from '../../../common/common';

const Show = (props) =>{
  let get_status=() =>{
    if(props.status==1){
       return "Продан"
     }
    if(props.status==0){
    return "Запас"
    }
    return "ERROR"
  }
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  return(
    <b > 
    <Button className={b.serbut}   outline  onClick={toggle} >S</Button>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>ID: {props.id}</ModalHeader>
      <ModalBody >
      <b>Order ID:</b> {props.order_id}<br/>
      <b>Сервис:</b> {props.key_word}<br/>
      <b>Owner:</b> {props.owner}{get_info_user(props.owner,props.users_data)}<br/>  
      <b>Phone number:</b> {props.phone_number}<br/>
      <b>STATUS:</b> {props.status}<br/>
      <b>Активен до:</b> {props.expiry_date}<br/>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)
}


export default Show
