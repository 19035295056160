import React , { useState } from 'react';
import b from './post.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';
import { dbAPI } from '../../../../api/api_db';
import { filter_data } from '../../../common/common';
const Edit = (props) =>{
  let newBalance= React.createRef();
  let newCredits= React.createRef();
  let newUnpaid= React.createRef();
  let update_data_w = (where) => {
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => { 
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      let answer=filter_data(props.current_type,data,props.current_type)
      props.update_data_w(answer[0],answer[1])  
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_db_data = (where,new_data) => {
    dbAPI.update_db_data(props.db_name,where,new_data)        
    .then(result => { 
      if(result.data.status==200){
        update_data_w('')
      }
      else{
        alert(`ERROR: ${result.data.status}`)
      }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let onUnpaidChange=()=>{
    let data=newUnpaid.current.value;
    data=data.replace(/^\s+/,'');
    props.update_unpaid_w(data)
  }
  let edit_unpaid=() =>{
    var where=`user_id='${props.user_id}'`
    var new_data=`unpaid=${props.data_unpaid}`
    update_db_data(where,new_data)
    props.update_unpaid_w('')
  }
  let onCreditsChange=()=>{
    let data=newCredits.current.value;
    data=data.replace(/^\s+/,'');
    props.update_credits_w(data)
  }
  let edit_credits=() =>{
    var where=`user_id='${props.user_id}'`
    var new_data=`credits=${props.data_credits}`
    update_db_data(where,new_data)
    props.update_credits_w('')
  }
  let onBalanceChange=()=>{
    let data=newBalance.current.value;
    data=data.replace(/^\s+/,'');
    props.update_balance_w(data)
  }
  let edit_balance=() =>{
    var where=`user_id='${props.user_id}'`
    var new_data=`balance=${props.data_balance}`
    update_db_data(where,new_data)
    props.update_balance_w('')
  }
  let update_status=(new_status) =>{
    var where=`user_id='${props.user_id}'`
    var new_data=`status='${new_status}'`
    update_db_data(where,new_data)
  }
  let update_work_theme=(new_work_theme) =>{
    var where=`user_id='${props.user_id}'`
    var new_data=`work_theme='${new_work_theme}'`
    update_db_data(where,new_data)
  }
  const {
    className
  } = props;
  const [modal, setModal] = useState(false);
  const toggle = () =>{
    setModal(!modal);
  }
  let get_type=(status) =>{
    if(status=='admin'){
       return "Админ"
     }
    if(status=='customer'){
    return "Клиент"
    }
    if(status=='freelancer'){
      return <>Исполнитель</>
    }
    return ""
  }
  let get_work_themes=() =>{
    return (
      <>
      Раздел: 
      <br/>
    <UncontrolledDropdown >
      <DropdownToggle className={b.serbut} caret>Раздел работы {props.work_theme}</DropdownToggle>
      <DropdownMenu >
      <DropdownItem onClick={() => update_work_theme('📝Отрисовка')}>📝Отрисовка</DropdownItem>
      <DropdownItem onClick={() => update_work_theme('📟Продажа сканов')}>📟Продажа сканов</DropdownItem>
      <DropdownItem onClick={() => update_work_theme('📞Пробив')}>📞Пробив</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    </>
    )
  }
  let get_editer=(status) =>{
    if(status=='admin'){
       return (<>
             <br/>
      Потраченно {props.balance}$:
      <br/>
      <input type="number" min="0" onChange={onBalanceChange} pattern={"^[0-9]*$"}
              ref={newBalance}  value={props.data_balance} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_balance} color="primary" onClick={edit_balance}>Update</Button>
      <br/>
      Баланс {props.credits}$:
      <br/>
      <input type="number" min="0" onChange={onCreditsChange} pattern={"^[0-9]*$"}
              ref={newCredits}  value={props.data_credits} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_credits} color="primary" onClick={edit_credits}>Update</Button>
      <br/>
      Не выплаченно {props.unpaid}$:
      <br/>
      <input type="number" min="0" onChange={onUnpaidChange} pattern={"^[0-9]*$"}
              ref={newUnpaid}  value={props.data_unpaid} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_unpaid} color="primary" onClick={edit_unpaid}>Update</Button>
      <br/>
       </>)
     }
    if(status=='customer'){
      return (<>
      <br/>
      Потраченно {props.balance}$:
      <br/>
      <input type="number" min="0" onChange={onBalanceChange} pattern={"^[0-9]*$"}
              ref={newBalance}  value={props.data_balance} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_balance} color="primary" onClick={edit_balance}>Update</Button>
      <br/>
      Баланс {props.credits}$:
      <br/>
      <input type="number" min="0" onChange={onCreditsChange} pattern={"^[0-9]*$"}
              ref={newCredits}  value={props.data_credits} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_credits} color="primary" onClick={edit_credits}>Update</Button>
        </>)
    }
    if(status=='freelancer'){
      return (<>
      <br/>
      Заработок {props.balance}$:
      <br/>
      <input type="number" min="0" onChange={onBalanceChange} pattern={"^[0-9]*$"}
              ref={newBalance}  value={props.data_balance} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_balance} color="primary" onClick={edit_balance}>Update</Button>
      <br/>
      Баланс {props.credits}$:
      <br/>
      <input type="number" min="0" onChange={onCreditsChange} pattern={"^[0-9]*$"}
              ref={newCredits}  value={props.data_credits} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_credits} color="primary" onClick={edit_credits}>Update</Button>
      <br/>
      Не выплаченно {props.unpaid}$:
      <br/>
      <input type="number" min="0" onChange={onUnpaidChange} pattern={"^[0-9]*$"}
              ref={newUnpaid}  value={props.data_unpaid} placeholder={"Enter "}/>
      <Button className={b.serbut} disabled={ !props.data_unpaid} color="primary" onClick={edit_unpaid}>Update</Button>
      <br/>
        </>)
    }
    return (<>
       
      </>)
  }
  return(
    <b>
    <Button className={b.serbut} outline color="primary" onClick={toggle}>Edit</Button>
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>User ID: {props.id}</ModalHeader>
      <ModalBody>
      Name: {props.name}
      <br/>
      Username: <a href={`https://t.me/${props.username}`} target="_blank">@{props.username}</a>
      {get_editer(props.status)}
      Статус пользователя {get_type(props.status)} (нажатие сразу меняет статус)
      <br/>
      <UncontrolledDropdown >
        <DropdownToggle className={b.serbut} caret>Статус {get_type(props.status_data)}</DropdownToggle>
        <DropdownMenu >
        <DropdownItem onClick={() => update_status('freelancer')}>Исполнитель</DropdownItem>
        <DropdownItem onClick={() => update_status('customer')}>Клиент</DropdownItem>
        <DropdownItem onClick={() => update_status('admin')}>Админ</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {props.status=='freelancer' ? get_work_themes():''}
      </ModalBody>
      <ModalFooter>
        <Button className={b.serbut} color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)





}


export default Edit
