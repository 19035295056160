import React , { useState } from 'react';
import b from './Show.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { get_info_user } from '../../../common/common';
const Show = (props) =>{
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  return(
    <b > 
    <Button className={b.serbut}   outline  onClick={toggle} >Show</Button>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Order ID: {props.order_id}</ModalHeader>
      <ModalBody >
      <b>Order ID:</b> {props.order_id}<br/>
      <b>Author ID:</b>{props.author_id}{get_info_user(props.author_id,props.users_data)}<br/>
      <b>Manager ID:</b>{props.freelancer_id}{get_info_user(props.freelancer_id,props.users_data)}<br/>
      <b>Executor ID:</b>{props.executor_id}{get_info_user(props.executor_id,props.users_data)}<br/>
      <b>Name:</b>{props.name}<br/>
      <b>Type:</b>{props.type}<br/>
      <b>Section:</b>{props.theme}<br/>
      <b>Status:</b>{props.status}<br/>
      <b>Date:</b>{props.date}<br/>
      <b>Price:</b>{props.price}$<br/>
      <b>ТЗ:</b><br/><span className={b.space}>{props.text}</span><br/>
      <b>Переписка:</b><br/><span className={b.space}>{props.message_history}</span><br/>     
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)
}


export default Show
