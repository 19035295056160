const UPDATE_DATA = 'UPDATE_DATA_ORDER';
const UPDATE_DATA_ = 'UPDATE_DATA_ORDER_';
const UPDATE_ORDER = 'UPDATE_ORDER_ORDER';
const UPDATE_STATUS = 'UPDATE_STATUS';
const UPDATE_EX_ID = 'UPDATE_EX_ID';
const UPDATE_F_ID = 'UPDATE_F_ID';
const UPDATE_U_SECTIONS = 'UPDATE_U_SECTIONS_ORDER';
let initialState = {
    counts:2, 
    data:[],
    current_type:'',
    current_status:'',
    order_name:'date',
    order_by:' ORDER BY date DESC',
    order_direction:'DESC',
    status_data:'',
    f_id_data:'',
    ex_id_data:'',
    u_sections:[]
};
 
const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DATA_:
            return {
                ...state,
                data:action.data
            }
        case UPDATE_DATA:
            return {
                ...state,
                data:action.data,
                current_type:action.current_type
            }
        case UPDATE_ORDER:
            return {
                ...state,
                order_name:action.order_name,
                order_by:action.order_by,
                order_direction:action.order_direction
            }
        case UPDATE_STATUS:
            return {
                ...state,
                status_data:action.status_data
            }
        case UPDATE_F_ID:
            return {
                ...state,
                f_id_data:action.f_id_data
            }
        case UPDATE_EX_ID:
            return {
                ...state,
                ex_id_data:action.ex_id_data
            }
        case UPDATE_U_SECTIONS:
            return {
                ...state,
                u_sections:action.u_sections
            }
        default:
            return state;
    }
}
export const update_u_sections = (data) => (
    { 
        type: UPDATE_U_SECTIONS, 
        u_sections:data
    }
)
export const update_f_id = (f_id_data) => (
    { 
        type: UPDATE_F_ID, 
        f_id_data:f_id_data
    }
)
export const update_ex_id = (ex_id_data) => (
    { 
        type: UPDATE_EX_ID, 
        ex_id_data:ex_id_data
    }
)
export const update_status = (status_data) => (
    { 
        type: UPDATE_STATUS, 
        status_data:status_data
    }
)
export const update_data_ = (data) => (
    { 
        type: UPDATE_DATA_, 
        data:data
    }
    )
export const update_data = (data,current_type) => (
    { 
        type: UPDATE_DATA, 
        data:data,
        current_type:current_type
    }
    )
export const update_order = (order_name,order_by,order_direction) => (
    { 
        type: UPDATE_ORDER,order_name,order_by,order_direction
    }
    )
export default ordersReducer;