// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".post_serbut__rYbjv{\r\n  margin-left: 10px;\r\n}\r\n.post_upspace__CLZHT{\r\n  margin-top: 10px;\r\n}\r\n.post_textarea__oYAXa {\r\n  border: 1px solid #ccc;\r\n  font-family: inherit;\r\n  font-size: inherit;\r\n  padding: 1px 6px;\r\n\r\n}\r\n.post_width-machine__zA45J {\r\n  /*   Sort of a magic number to add extra space for number spinner */\r\n  padding: 0 1rem;\r\n}\r\n\r\n.post_textarea__oYAXa {\r\n  display: block;\r\n  width: 95%;\r\n  height: -moz-fit-content;\r\n  height: fit-content;\r\n  overflow: hidden;\r\n  resize: both;\r\n  min-height: 50px;\r\n  line-height: 20px;\r\n  \r\n}\r\n\r\n.post_textarea__oYAXa[contenteditable]:empty::before {\r\n  content: \"Placeholder still possible\";\r\n  color: gray;\r\n}", "",{"version":3,"sources":["webpack://./src/Components/PhonesBASKET/PhonesButtons/Search/post.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;;AAElB;AACA;EACE,mEAAmE;EACnE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,UAAU;EACV,wBAAmB;EAAnB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;;AAEnB;;AAEA;EACE,qCAAqC;EACrC,WAAW;AACb","sourcesContent":[".serbut{\r\n  margin-left: 10px;\r\n}\r\n.upspace{\r\n  margin-top: 10px;\r\n}\r\n.textarea {\r\n  border: 1px solid #ccc;\r\n  font-family: inherit;\r\n  font-size: inherit;\r\n  padding: 1px 6px;\r\n\r\n}\r\n.width-machine {\r\n  /*   Sort of a magic number to add extra space for number spinner */\r\n  padding: 0 1rem;\r\n}\r\n\r\n.textarea {\r\n  display: block;\r\n  width: 95%;\r\n  height: fit-content;\r\n  overflow: hidden;\r\n  resize: both;\r\n  min-height: 50px;\r\n  line-height: 20px;\r\n  \r\n}\r\n\r\n.textarea[contenteditable]:empty::before {\r\n  content: \"Placeholder still possible\";\r\n  color: gray;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serbut": "post_serbut__rYbjv",
	"upspace": "post_upspace__CLZHT",
	"textarea": "post_textarea__oYAXa",
	"width-machine": "post_width-machine__zA45J"
};
export default ___CSS_LOADER_EXPORT___;
