import React  from 'react';
import b from './Head.module.css';
import SortButton from './SortButton/SortButton.jsx';
import  {Table } from 'reactstrap';
import { filter_data } from '../../common/common';
const Head=(props) =>{ 
    let s_phone= React.createRef();
    let s_sender= React.createRef();
    let s_date= React.createRef();
    let s_user_id= React.createRef();
    let update_data_w = (where) => {
        let answer=filter_data(where,props.data,props.current_type)
        props.update_data_w(answer[0],answer[1])
      }
    let onChange=(value,target)=>{
        let data=value.current.value;
        data=data.replace(/^\s+/,'');
        var where=`${target}=${data}`
        update_data_w(where)
    }
  return(
    <Table striped bordered hover cellSpacing="1" >
        <thead>
            <tr>
                <th scope="col" className={b.table_name}>
                    <SortButton
                        name="phone_number"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w_={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Номер
                    <br/>
                    <input type="name" className={b.input} onChange={() => onChange(s_phone,'phone_number')}
                        ref={s_phone}
                        placeholder={"Enter phone"}  />
                </th>
                <th scope="col" className={b.table_price}>
                    <SortButton
                        name="sender"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w_={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Sender
                    <br/>
                    <input type="name" className={b.input} onChange={() => onChange(s_sender,'sender')}
                        ref={s_sender}
                        placeholder={"Enter sender"}  />
                </th>
                <th scope="col" className={b.table_status}>
                    <SortButton
                        name="status"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w_={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Status
                    <br/>
                    <input type="name" className={b.input}
                        placeholder={"disabled"} disabled="disabled"/>
                </th>
                <th scope="col" className={b.table_price}>
                    <SortButton
                        name="owner"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Owner
                    <input type="name" className={b.input} onChange={() => onChange(s_user_id,'user_id')}
                        ref={s_user_id}
                        placeholder={"Enter ID"}  />
                </th>
                <th scope="col" className={b.table_date}>
                    <SortButton
                        name="date"
                        current_type={props.current_type}
                        order_name={props.order_name}
                        order_by={props.order_by}
                        order_direction={props.order_direction}
                        update_data_w_={props.update_data_w_}
                        update_order_w={props.update_order_w}
                        db_name={props.db_name}
                        data={props.data}
                    />
                    Date
                    <br/>
                    <input type="name" className={b.input} onChange={() => onChange(s_date,'date')}
                        ref={s_date}
                        placeholder={"Enter date"}  />
                </th>
                <th scope="col" className={b.table_actions}>Actions</th>
            </tr>
        </thead>
    <tbody id={b.remover}></tbody>
    </Table>)}



export default Head
